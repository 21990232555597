// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { generateClassPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { SpecificService } from '@core/specific/specific.service';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { NavService } from '@shared/services/nav/nav.service';

@Component({
  selector: 'nav-one',
  templateUrl: './nav-one.component.html',
  styleUrls: ['./nav-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavOneComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public specificService:SpecificService,
    public accountsService:AccountsService,
    public navService: NavService
  ) { }

  classPrefix = generateClassPrefix('NavOne')
  idPrefix = generateClassPrefix(ENV.idPrefix.navOne)

  @Input('props') props: NavOneProps = new NavOneProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix()


  ngUnsub = new Subject<void>()

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export class NavOneProps {
  constructor(props: Partial<NavOneProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key, val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
}
