import { ENV } from "@env/environment";
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base";


@WMLConstructorDecorator
export class AuthBase {
  constructor(props: Partial<AuthBase> = {}) { }

  accessToken:string =""
}

@WMLConstructorDecorator
export class GoogleDriveAPIBase {
  constructor(props: Partial<GoogleDriveAPIBase> = {}) { }

  accessToken:string =""
  googleDriveApiAccessToken:string = ""
  googleDriveApiRefreshToken:string = ""
  appPlatformTypeEnumValue = ENV.platformType
}

@WMLConstructorDecorator
export class YoutubeDataAPIBase {
  constructor(props: Partial<YoutubeDataAPIBase> = {}) { }

  accessToken:string =""
  youtubeDataApiAccessToken:string = ""
  youtubeDataApiRefreshToken:string = ""
  appPlatformTypeEnumValue = ENV.platformType
}

@WMLConstructorDecorator
export class GoogleDriveYoutubeDataAPIBase {
  constructor(props: Partial<YoutubeDataAPIBase> = {}) { }

  accessToken:string =""
  googleDriveApiAccessToken:string = ""
  googleDriveApiRefreshToken:string = ""
  youtubeDataApiAccessToken:string = ""
  youtubeDataApiRefreshToken:string = ""
  appPlatformTypeEnumValue = ENV.platformType
}
