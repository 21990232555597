import { AbstractControl, AsyncValidatorFn, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { WMLFileUploadZeroItem } from '@windmillcode/angular-wml-file-manager';
import { convertHtmlToPreFormattedText } from './rich-text-utils';
import { parseFileSize } from './file-utils';

export let WMLValidatorFileExtensions = (props:{ext:string[]}) => {
  return (c: FormArray) => {
    let validatorObj = null
    // until rxweb wants to work properly
    // let fn = RxwebValidators.extension({extensions:['docx','pdf'],isExcludeExtensions:true})

    // c.value
    // .map((file:WMLFileUploadZeroItem )=>{
    //   let fileControl = new FormControl([file.file])
    //   let result = fn(fileControl)
    //   console.log(result)

    // })
    let allValidFiles = c.value.every((item:WMLFileUploadZeroItem )=>{

      let splitText = item.file.name.split(".");
      let extension = splitText[splitText.length - 1];

      return props.ext.includes(extension)
    })
    if(!allValidFiles){
      validatorObj = {
        extension:true
      }
    }
    return validatorObj
  };
};

export let WMLValidatorFileSize = (props:{fileSizeRange:{value:number,unit:string}[]}) => {
  return (c: FormArray) => {
    let validatorObj = null

    let allValidFiles = c.value.every((item:WMLFileUploadZeroItem )=>{
      let file:File = item.file
      file.size
      let [minVal,maxVal] =props.fileSizeRange
      let min = parseFileSize(minVal.value??0,minVal.unit??"B" as any)
      let max = parseFileSize(maxVal.value??999,maxVal.unit??"YB" as any)

      return file.size < max && file.size > min
    })
    if(!allValidFiles){
      validatorObj = {
        fileSize:true
      }
    }
    return validatorObj
  };
};


export let WMLValidatorConditionalRequired =(predicate:Function,fields:string[] =[])=>{

  return (parent:FormGroup)=>{
    predicate(fields,parent)


    return null
  }
}



export let WMLValidatorConditionalRequiredPredicate = (fields:Array<string>,c:FormGroup)=>{

  let groupRequired = !fields.some((field)=>{
    let targetControl = c.controls[field]
    let myField = targetControl.value
    let required = ["",undefined,null].includes(myField )
    return !required
  })
  fields.forEach((field)=>{
    let targetControl = c.controls[field]
    let myField = targetControl.value
    let required = ["",undefined,null].includes(myField )

    if(groupRequired){
      if(required){
        targetControl.setErrors({required:true})
      }
    }
    else{
      let errors = targetControl.errors ??{}
      delete errors.required
      if(Object.keys(errors).length===0){
        targetControl.setErrors(null)
      }
      else{
        targetControl.setErrors(errors)
      }
    }
  })



}


export let WMLValidatorRichTextSanitizer = (validator: ValidatorFn | AsyncValidatorFn) => {
  return (c: AbstractControl) => {
    if (c instanceof FormControl) {
      // Sanitize and validate FormControl
      let content = convertHtmlToPreFormattedText(c.value);
      let f = new FormControl(content);
      return validator(f);
    } else if (c instanceof FormGroup) {
      // Recursively sanitize and validate FormGroup
      const group = new FormGroup({});
      Object.keys(c.controls).forEach(key => {
        // @ts-ignore
        group.addControl(key, WMLValidatorRichTextSanitizer(validator)(c.get(key)!));
      });
      return group;
    } else if (c instanceof FormArray) {
      // Recursively sanitize and validate FormArray
      const array = new FormArray([]);
      c.controls.forEach(control => {
        array.push(WMLValidatorRichTextSanitizer(validator)(control));
      });
      return array;
    } else {
      throw new Error('Unsupported control type');
    }
  }
}
