import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { SocketioClientResponseModel } from "../socketio/models/socketio-client-resp-model"

export let requestFileTransferLoad = (uiBody:RequestFileTransferUIRequestBody): Observable<RequestFileTransferAPIRequestBody>=>{



  let apiBody = new RequestFileTransferAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let requestFileTransferSuccess = (apiBody:RequestFileTransferAPIResponseBody):RequestFileTransferUIResponseBody=>{
  let uiBody = new RequestFileTransferUIResponseBody(transformObjectKeys(
    apiBody,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class RequestFileTransferUIRequestBody {
  constructor(props:Partial<RequestFileTransferUIRequestBody>={}){}
  accessToken:string
  recipients:Array<string>
  jobId:string
}

@WMLConstructorDecorator
export class RequestFileTransferAPIRequestBody {
  constructor(props:Partial<RequestFileTransferAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<RequestFileTransferUIRequestBody>
}

@WMLConstructorDecorator
export class RequestFileTransferUIResponseBody extends SocketioClientResponseModel<{
  senderEmail:string
  ,senderUid:string,
  senderSid:string,
  jobId:string,
  filesAmount:number
}> {
  constructor(props:Partial<RequestFileTransferUIResponseBody>={}){ super()}


}

@WMLConstructorDecorator
export class RequestFileTransferAPIResponseBody {
  constructor(props:Partial<RequestFileTransferAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<RequestFileTransferUIResponseBody>
}

