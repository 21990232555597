import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { concatMap, iif, map, Observable, of } from 'rxjs';

import { ListSubscriptionsAPIResponseBody, ListSubscriptionsUIRequestBody,listSubscriptionsLoad, listSubscriptionsSuccess } from './list';
import { WMLDataSource } from '@core/utility/data-source-utils';
import { PurchaseSubscriptionUIRequestBody,PurchaseSubscriptionUIResponseBody,purchaseSubscriptionLoad, purchaseSubscriptionSuccess } from './purchase';
import { transformObjectKeys } from '@core/utility/common-utils';
import { transformFromCamelCaseToSnakeCase } from '@core/utility/string-utils';
import { WMLAPIPaginationResponseModel } from '@windmillcode/wml-components-base';
import { CancelSubscriptionUIRequestBody, CancelSubscriptionUIResponseBody,cancelSubscriptionLoad, cancelSubscriptionSuccess } from './cancelSubscription';
import { AccountsService } from '../accounts/accounts.service';
import { PurchaseCreditsUIRequestBody, PurchaseCreditsUIResponseBody,purchaseCreditsLoad, purchaseCreditsSuccess } from './purchaseCredits';
import { ListCreditsUIRequestBody, ListCreditsUIResponseBody,listCreditsLoad, listCreditsSuccess } from './listCredits';


import { UseCreditsUIRequestBody, UseCreditsUIResponseBody,useCreditsLoad, useCreditsSuccess } from './useCredits';
import { BaseService } from '@app/core/base/base.service';



@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public accountsService:AccountsService,
    public baseService:BaseService
  ) { }

  _listSubscriptions = (uiBody = new ListSubscriptionsUIRequestBody())=>{
    return iif(
      ()=>ENV.storeService.listSubscriptions.automate,
      of(new ListSubscriptionsAPIResponseBody({
        data:transformObjectKeys(
          new WMLAPIPaginationResponseModel({
            data:[]
          }),transformFromCamelCaseToSnakeCase
        )
      })),
      listSubscriptionsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.storeService.listSubscriptions.url(),apiBody)
          })
        ) as Observable<ListSubscriptionsAPIResponseBody>
      )
    }

  listSubscriptionsDataSource = new WMLDataSource({
    getFromSink:this._listSubscriptions,
    transformationPredicate:listSubscriptionsSuccess
    // webStorageObj:{
    //   key:"ListSubscriptionsDataSource",
    //   expiry:new DateTimeZero({
    //     minutes:2
    //   })
    // }
  })

  purchaseSubscription = (uiBody = new PurchaseSubscriptionUIRequestBody())=>{

    if(!ENV.app.includeSubscriptions){
      this.baseService.openFeatureIsComingSoon()
      return of()
    }
    return iif(
      ()=>ENV.storeService.purchaseSubscription.automate,
      of(new PurchaseSubscriptionUIResponseBody()),
      purchaseSubscriptionLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.storeService.purchaseSubscription.url(),apiBody)
              .pipe(map(purchaseSubscriptionSuccess))
          })
        )
      )
    }

  cancelSubscription = (uiBody = new CancelSubscriptionUIRequestBody())=>{
    let {currentUser} = this.accountsService
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken
    })
    return iif(
      ()=>ENV.storeService.cancelSubscription.automate,
      of(new CancelSubscriptionUIResponseBody()),
      cancelSubscriptionLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .delete(ENV.storeService.cancelSubscription.url(),{body:apiBody})
              .pipe(map(cancelSubscriptionSuccess))
          })
        )
      )
    }


  purchaseCredits = (uiBody = new PurchaseCreditsUIRequestBody())=>{

    let {currentUser} = this.accountsService
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken
    })
    return iif(
      ()=>ENV.storeService.purchaseCredits.automate,
      of(new PurchaseCreditsUIResponseBody()),
      purchaseCreditsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.storeService.purchaseCredits.url(),apiBody)
              .pipe(map(purchaseCreditsSuccess))
          })
        )
      )
    }

  listCredits = (uiBody = new ListCreditsUIRequestBody())=>{
    return iif(
      ()=>ENV.storeService.listCredits.automate,
      of(new ListCreditsUIResponseBody()),
      listCreditsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.storeService.listCredits.url(),apiBody)
              .pipe(map(listCreditsSuccess))
          })
        )
      )
    }

  useCredits = (uiBody = new UseCreditsUIRequestBody())=>{

    let {currentUser} = this.accountsService
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken,
    })
    return iif(
      ()=>ENV.storeService.useCredits.automate,
      of(new UseCreditsUIResponseBody()),
      useCreditsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.storeService.useCredits.url(),apiBody)
              .pipe(map(useCreditsSuccess))
          })
        )
      )
    }

}
