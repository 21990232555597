
export enum EnvPlatformType{
  WEB, ANDROID, IOS, LINUX, MACOS,WINDOWS
}
export let silenceAllConsoleLogs = ()=>{
  Object.entries(console)
  .forEach((x, i) => {
    let [key, val] = x
    if (typeof val === "function") {
      ((console as any)[key] ) = () => { }
    }
  })
}


// TODO why do we have this
// export let restoreOriginalConsoleObject = ( )=>{
//   ENV.app.originalConsoleObj
//   .forEach((x, i) => {
//     let [key, val] = x
//     ;((console as any)[key] ) = val

//   })
// }

export let traverseClassAndRemoveUpdateAutomation = (obj, automate = false) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (value instanceof Object) {
      traverseClassAndRemoveUpdateAutomation(value, automate);
    } else if (key === "automate") {
      obj.automate = automate;
    }
  });
};

