<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <h4 [class]="classPrefix('SharedText0')">{{"JobStatusZero.status" | translate}}</h4>
      <h4>{{"ListJobs.status."+props.status | translate}}</h4>
    </div>
    <div  *ngIf="props.resultURLIsPresent" [class]="classPrefix('Pod0Item0')">
      <h4 [class]="classPrefix('SharedText0')">{{"JobStatusZero.downloadURL" | translate}}</h4>
      <h4 >
        <span *ngIf="['ISSUES','SUCCESS'].includes(props.status) && props.resultURL === 'N/A'">{{"JobStatusZero.downloadURLValueMissing" | translate}}</span>
        <a *ngIf="['ISSUES','SUCCESS'].includes(props.status) && !(props.resultURL === 'N/A')" [href]="props.resultURL" target="_blank">{{ "JobStatusZero.downloadURLValue" | translate}}</a>
        <span *ngIf="(!['ISSUES','SUCCESS'].includes(props.status) && !(props.resultURL === 'N/A')) || ['FAILED'].includes(props.status)">{{ "global.na" | translate}}</span>
      </h4>
    </div>
  </section>
</div>

