import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { FormsService } from "../forms/forms.service"
import { ENV } from "@env/environment"
import { LoadUserVideoDataEditorSettingsUIResponseBody } from "./loadUserVideoDataEditorSettings"
import { WMLFileUploadZeroItem } from "@windmillcode/angular-wml-file-manager"

export let saveUserVideoDataEditorSettingsLoad = (uiBody:SaveUserVideoDataEditorSettingsUIRequestBody & FormsService["videoDataEditorZero"]["mainForm"]["value"]): Observable<SaveUserVideoDataEditorSettingsAPIRequestBody>=>{

  let apiBody =saveUserVideoDataEditorSettingsTransformViaFormGroupValue(uiBody)


  return of(apiBody)
  .pipe(
    take(1),
  )
}

export function saveUserVideoDataEditorSettingsTransformViaFormGroupValue(
  uiBody:SaveUserVideoDataEditorSettingsUIRequestBody & FormsService["videoDataEditorZero"]["mainForm"]["value"],deleteThumbnail = true
) {
  // @ts-ignore
  uiBody.videos = uiBody.videos.map((video) => video.appId)


  // @ts-ignore
  uiBody = new SaveUserVideoDataEditorSettingsUIRequestBody(uiBody)
  uiBody.actionTypeEnumValue = uiBody.action[0]?.value ?? uiBody.actionTypeEnumValue
  uiBody.sectionTypeEnumValue = uiBody.type[0]?.value ?? uiBody.sectionTypeEnumValue
  delete uiBody.action
  delete uiBody.type
  if(deleteThumbnail){
    delete uiBody.content.thumbnail
  }

  let apiBody = new SaveUserVideoDataEditorSettingsAPIRequestBody({
    data: transformObjectKeys(
      uiBody,
      transformFromCamelCaseToSnakeCase
    )
  })
  return apiBody
}

export let saveUserVideoDataEditorSettingsSuccess = (apiBody:SaveUserVideoDataEditorSettingsAPIResponseBody):SaveUserVideoDataEditorSettingsUIResponseBody=>{
  let uiBody = apiBody.data
  return uiBody
}

@WMLConstructorDecorator
export class SaveUserVideoDataEditorSettingsUIRequestBody extends LoadUserVideoDataEditorSettingsUIResponseBody<WMLFileUploadZeroItem>  {
  constructor(props: Partial<SaveUserVideoDataEditorSettingsUIRequestBody> = {}) {super()}
  accessToken:string =""
  googleDriveApiAccessToken:string = ""
  googleDriveApiRefreshToken:string = ""
  appPlatformTypeEnumValue = ENV.platformType

}

@WMLConstructorDecorator
export class SaveUserVideoDataEditorSettingsAPIRequestBody  {
  constructor(props: Partial<SaveUserVideoDataEditorSettingsAPIRequestBody> = {}) {}

  data:RecursiveSnakeCaseType<SaveUserVideoDataEditorSettingsUIRequestBody>
}


export type SaveUserVideoDataEditorSettingsUIResponseBody ="NEED_NEW_TOKEN" | ""

@WMLConstructorDecorator
export class SaveUserVideoDataEditorSettingsAPIResponseBody   {
  constructor(props: Partial<SaveUserVideoDataEditorSettingsAPIResponseBody> = {}) {
    // s
  }

  data:RecursiveSnakeCaseType<SaveUserVideoDataEditorSettingsUIResponseBody>
}





