import { Injectable } from '@angular/core';
import { ENV } from '@env/environment';
import { BaseService } from '@core/base/base.service';
import { WMLNotifyOneBarType, WMLNotifyOneService } from '@windmillcode/angular-wml-notify';
import { WMLCustomComponent, WMLImage } from '@windmillcode/wml-components-base';
import { UtilityService } from '@core/utility/utility.service';
import { GenerateFieldProps, createWMLFormZeroPropsField } from '@core/utility/form-utils';

import { VerifiedZeroProps, VerifiedZeroComponent } from '@shared/components/verified-zero/verified-zero.component';
import { MultiInputOneComponent, MultiInputOneProps } from '@shared/components/multi-input-one/multi-input-one.component';
import { ContentEditorControlZeroComponent, ContentEditorControlZeroProps } from '@shared/components/content-editor-control-zero/content-editor-control-zero.component';
import { RichTextEditorZeroComponent, RichTextEditorZeroProps } from '@shared/components/rich-text-editor-zero/rich-text-editor-zero.component';
import { WMLFileUploadZeroProps, WMLFileUploadZeroComponent } from '@windmillcode/angular-wml-file-manager';
import { WMLChipsZeroProps, WMLChipsZeroComponent } from '@windmillcode/angular-wml-chips';
import { InsertAtControlZeroProps, InsertAtControlZeroComponent } from '@shared/components/insert-at-control-zero/insert-at-control-zero.component';
import { FriendControlZeroComponent, FriendControlZeroProps } from '@shared/components/friend-control-zero/friend-control-zero.component';
import { IntervalInputZeroComponent, IntervalInputZeroProps } from '@shared/components/interval-input-zero/interval-input-zero.component';
import { defer, of } from 'rxjs';
import { loadScript } from '../utility/common-utils';

@Injectable({
  providedIn: 'root'
})
export class SpecificService  {
  constructor(
    public utilService:UtilityService,
    public WMLNotifyOneService:WMLNotifyOneService,
    public baseService:BaseService
  ){}

  getLogoImg = ()=>new WMLImage({
    src:"assets/media/app/logo-no-bg.png",
    alt:"global.logoImgAlt",
    routerLink:ENV.nav.urls.home
  })
  logoImg = this.getLogoImg()
  getDrawerLogoBackgroundImg = ()=>new WMLImage({
            src:"assets/media/app/drawer-logo-bg.png",
            alt:"NavZero.logoBackgroundAlt",
    routerLink:ENV.nav.urls.home
  })
  drawerLogoBackgroundImg = this.getDrawerLogoBackgroundImg()



  _manageWebStorage(webStorage:Storage,thisWebStorage:any,predicate:Function) {
    let myWebStorage = webStorage.getItem(ENV.classPrefix.app);
    myWebStorage = JSON.parse(myWebStorage);
    Object.assign(thisWebStorage, myWebStorage);
    predicate()
    webStorage.setItem(ENV.classPrefix.app, JSON.stringify(thisWebStorage))
  }

  reconnectToAPlatform =(props ={navigate:true})=>{
    if(props.navigate){
      this.utilService.router.navigateByUrl(ENV.nav.urls.connectionHub)
    }
    this.baseService.createWMLNote(
      "global.WMLNotifyOne.reconnectToPlatform",
      WMLNotifyOneBarType.Error
    )
  }

  purchaseMoreCredits  =(props ={navigate:true})=>{
    if(props.navigate){
      this.utilService.router.navigateByUrl(ENV.nav.urls.billingZeroPage)
    }
    this.baseService.createWMLNote(
      "global.WMLNotifyOne.purchaseMoreCredits",
      WMLNotifyOneBarType.Error
    )
  }

  getWakeLock = ()=>{
    return defer(async ()=>{
      try{
        return await navigator.wakeLock.request('screen');
      } catch (err){
        return null
      }
    })
  }


  getOneSignalDeferredGlobalVar = ()=>{
    let OneSignalDeferred ;
    OneSignalDeferred =this.utilService.getWindow().OneSignalDeferred = this.utilService.getWindow().OneSignalDeferred || [];
    return OneSignalDeferred
  }




  createField=(props =new GenerateFieldProps<any>(),cpnt:WMLCustomComponent["cpnt"])=>{
    let {
      fieldCustomProps
    } = props
    fieldCustomProps = fieldCustomProps
    let wmlField = createWMLFormZeroPropsField(props)
    wmlField.field.custom.cpnt = cpnt
    wmlField.field.custom.props  =fieldCustomProps

    return wmlField
  }


  createVerifiedField=(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new VerifiedZeroProps()
    return this.createField(props,VerifiedZeroComponent)
  }

  createMultiInputField=(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new MultiInputOneProps()
    return this.createField(props,MultiInputOneComponent)
  }

  createWMLFileUploadField = (props= new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new WMLFileUploadZeroProps()
    return this.createField(props,WMLFileUploadZeroComponent)
  }

  createRichTextEditorZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new RichTextEditorZeroProps()
    return this.createField(props,RichTextEditorZeroComponent)
  }

  createWMLChipsZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new WMLChipsZeroProps()
    return this.createField(props,WMLChipsZeroComponent)
  }

  // dev aditions
  createContentEditorControlZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new ContentEditorControlZeroProps()
    return this.createField(props,ContentEditorControlZeroComponent)
  }

  createInsertAtControlZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new InsertAtControlZeroProps()
    return this.createField(props,InsertAtControlZeroComponent)
  }

  createFriendControlZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new FriendControlZeroProps()
    return this.createField(props,FriendControlZeroComponent)
  }

  createIntervalInputZeroField =(props =new GenerateFieldProps())=>{
    props.fieldCustomProps ??= new IntervalInputZeroProps()
    return this.createField(props,IntervalInputZeroComponent)
  }




}
