import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { Observable, of, take } from "rxjs"

export let deleteVideosLoad = (uiBody:DeleteVideosUIRequestBody): Observable<DeleteVideosAPIRequestBody>=>{

  let apiBody = new DeleteVideosAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let deleteVideosSuccess = (apiBody:DeleteVideosAPIResponseBody):DeleteVideosUIResponseBody=>{
  let uiBody = new DeleteVideosUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class DeleteVideosUIRequestBody {
  constructor(props:Partial<DeleteVideosUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  ids:string[]
  accessToken:string
  googleDriveApiAccessToken:string
  googleDriveApiRefreshToken:string
  appPlatformTypeEnumValue =ENV.platformType

}

export class DeleteVideosAPIRequestBody {
  constructor(props:Partial<DeleteVideosAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<DeleteVideosUIRequestBody>
}

export class DeleteVideosUIResponseBody {
  constructor(props:Partial<DeleteVideosUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class DeleteVideosAPIResponseBody {
  constructor(props:Partial<DeleteVideosAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<DeleteVideosUIResponseBody>
}

