<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
  </section>
  <section [class]="classPrefix('Pod1')">
      <video
      *ngIf="props.type === 'Other'"
      [src]="videoSrc"></video>
      <iframe
      *ngIf="props.type === 'Youtube'"
      [title]="'VideoTileZero.iframeTitle' | translate"
      [class]="classPrefix('Pod0Item0')"
      [src]="videoSrc"
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      ></iframe>
  </section>
</div>
