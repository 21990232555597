export class WMLError extends Error {
  constructor(props:Partial<WMLError>={}){
    super(props.message)
    Object.assign(
      this,
      {
        ...props
      }
    )
  }

  openSystemErrorBanner: "false"|"true"= "true"
}


export class CantReachBackendError extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthEmailAlreadyInUse extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}


export class AuthUserNotFound extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthWrongPassword extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthAccountDisabled extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthProviderExists extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class AuthWeakPassword extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}


export class AuthInvalidPasswordResetCode extends Error {
  constructor(message?) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class UserCanceledAuthError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}


export class APIServerError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}

export class ServiceUnavailableError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}




