<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <h2 [class]="classPrefix('Pod0Text0')">{{props.title.text | translate}}</h2>
    <wml-button-one
    [class]="classPrefix('Pod0Btn0')"
    [props]="props.actionBtn"></wml-button-one>
  </section>
  <section [class]="classPrefix('Pod1')">
    <p>{{props.desc.text | translate}}</p>
  </section>

</div>
