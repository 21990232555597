
export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";
export const ISSUES = "ISSUES";

// dev additions
export const NEED_MORE_CREDITS ="NEED_MORE_CREDITS"
export const NEED_NEW_TOKEN = "NEED_NEW_TOKEN"
export const PROCESSED ="PROCESSED"
export const EMAIL_REQUIRED = "EMAIL_REQUIRED"

export const RECIPIENT_DELAY_TIME_TO_REQUEST_CHUNK_FOR_FILE_TRANSFER_IN_MS = 0

export enum SITE_OFFLINE_ENUM {
  TRUE,FALSE
}

