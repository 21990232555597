import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLConstructorDecorator, WMLDeepPartial } from '@windmillcode/wml-components-base';
import { Observable, Subject, concatMap, defer, iif, map, of, tap } from 'rxjs';
import { RecursiveSnakeCaseType } from './string-utils';
import localforage from 'localforage';
import { DateTimeZero, calculateTimeInFuture } from './date-utils';


@WMLConstructorDecorator
export  class WMLDataSourceSinkResponseBody {
  constructor(props: Partial<WMLDataSourceSinkResponseBody> = {}) {

  }
  data:RecursiveSnakeCaseType<WMLAPIPaginationResponseModel>
}

export type WMLDataSourceRequestModifierPredicate<R extends WMLAPIPaginationRequestModel= WMLAPIPaginationRequestModel> = (req: R, self: WMLDataSource<R>) => R;

export let afterCursorRequestModifierPredicate:WMLDataSourceRequestModifierPredicate = (req,self )=>{

  req.cursor ={}
  if (self.currentSource.data.length !== 0) {
    req.cursor = {
      value: self.currentSource.data.at(-1).cursor,
      order: self.currentSource.data.length - 1
    };
  }
  return req
}

@WMLConstructorDecorator
export class WMLDataSourceCurrentSource {
  constructor(props: Partial<WMLDataSourceCurrentSource> = {}) {}
  totalItems = null
  totalPages = null
  data = []
}

@WMLConstructorDecorator
export class WMLDataSourceWebStorageEntity {
  constructor(props: Partial<WMLDataSourceWebStorageEntity> = {}) {}
  expiryDate:string
  isSyncedWithExternal:any = true
}

@WMLConstructorDecorator
// TODO better name
export class WMLDataSourceWebStorageEntityWithData extends WMLDataSourceWebStorageEntity {
  constructor(props: Partial<WMLDataSourceWebStorageEntityWithData> = {}) {
    super()
  }
  data:WMLDataSource["sources"]
}

export function WMLDataSourceUpdateSourcesObjUpdatePredicate(a,b){
  return {
    ...a,
    ...b
  }
}

@WMLConstructorDecorator
export  class WMLDataSourceUpdateSourcesObj {
  constructor(props: WMLDeepPartial<WMLDataSourceUpdateSourcesObj> = {}) {}

  wmlInit(){
    this.configurations?.forEach((config)=>{
      config.updatePredicate ??=WMLDataSourceUpdateSourcesObjUpdatePredicate
    })
  }
  row:any
  id:string
  configurations:Array<{
    key:string
    position:number
    action:"update" | "delete" | "insert"
    updatePredicate:Function
  }>
}


@WMLConstructorDecorator
export class WMLDataSource<R extends WMLAPIPaginationRequestModel= WMLAPIPaginationRequestModel> {
  constructor(props: Partial<WMLDataSource<R>> = {}) {}


  currentSource :WMLDataSourceCurrentSource;
  sources:{[k:string]:WMLDataSourceCurrentSource} ={}
  webStorageObj:{
    key?:string,
    expiry?:DateTimeZero
    isSyncedWithExternal?:any
  }

  transformationPredicate= (val:any) => val;
  get sucessPredicate(){
    return this.transformationPredicate
  }
  getFromSink: (req:R)=>Observable<WMLDataSourceSinkResponseBody> = (val) => new Subject<any>();

  updateSources=(data:WMLDataSourceUpdateSourcesObj[],syncWithBrowserStorage = false)=>{

    data.forEach((updateObj)=>{

      updateObj.configurations.forEach((config)=>{
        if(config.action ==="insert"){
          let targetSource = this.sources?.[config.key]
          if (!targetSource) return;
          if ([null,undefined].includes(config.position)){
            targetSource.data.push(updateObj.row)
          }
          else{
            targetSource.data.splice(config.position,0,updateObj.row)
          }
          targetSource.totalItems += 1
          targetSource.totalPages +=1

        }
        if(config.action ==="update"){
          Object.values(this.sources).forEach((targetSource:WMLDataSourceCurrentSource)=>{
            let index = targetSource.data.findIndex((val)=>val.id === updateObj.id)
            if(index === -1) return
            targetSource.data[index] = config.updatePredicate(targetSource.data[index],updateObj.row)
          })
        }

        if(config.action ==="delete"){
          Object.values(this.sources).forEach((targetSource:WMLDataSourceCurrentSource)=>{
            let index = targetSource.data.findIndex((val)=>val.id === updateObj.id)
            targetSource.data.splice(index,1)
            targetSource.totalItems -= 1
            targetSource.totalPages -=1
          })

        }
      })
    })

    return this.setSyncWithExternal(syncWithBrowserStorage)
    .pipe(
      tap(()=>this.updateSourcesEvent.next(data))
    )

  }
  updateSourcesEvent = new Subject<WMLDataSourceUpdateSourcesObj[]>()

  updateCurrentSource = (req)=>{

    let key = JSON.stringify([req.sort,req.filter])
    this.sources[key] ??=new WMLDataSourceCurrentSource()
    this.currentSource = this.sources[key]
  }

  invalidateCache = ()=>{
    this.sources = {}
    this.currentSource =null
    return defer(async()=>{
      await localforage.removeItem(this.webStorageObj?.key)
    })
  }

  checkForPersistedData() {
    return defer(async ()=>{

      if(this.webStorageObj){
        let {key} = this.webStorageObj
        if(!key){
          return
        }
        let data:WMLDataSourceWebStorageEntityWithData = await localforage.getItem(key);
        if(!data){
          return
        }
        if(new Date(data.expiryDate).getTime() > new Date().getTime()){
          this.sources= Object.fromEntries(Object.entries(data.data).map(([key,val])=>{
            return [key,new WMLDataSourceCurrentSource(val)]
          }))
        }
        else{
          await localforage.removeItem(key)
        }

      }
    })
  }

  syncWithBrowserStorage(webStorageObj = new WMLDataSourceWebStorageEntityWithData()) {
    return defer(async ()=>{
      if(this.webStorageObj){
        let {key,expiry} = this.webStorageObj

        if(expiry){
          let expiryDate = calculateTimeInFuture(expiry)
          await localforage.setItem(key,new WMLDataSourceWebStorageEntityWithData({
            expiryDate:expiryDate.toString(),
            data:this.sources,
            isSyncedWithExternal: webStorageObj.isSyncedWithExternal
          }))
        }

      }
    })

  }

  setSyncWithExternal = (isSyncedWithExternal:any,obs$ =(res)=> of(res))=>{
    return this.getSyncWithExternal()
    .pipe(
      concatMap(obs$),
      concatMap(()=>{
        return  this.syncWithBrowserStorage(new WMLDataSourceWebStorageEntityWithData({isSyncedWithExternal}))
      })
    )
  }

  getSyncWithExternal = ()=>{
    return defer(async()=>{
      if(this.webStorageObj?.expiry){
        let {key} = this.webStorageObj
        let data:WMLDataSourceWebStorageEntityWithData = await localforage.getItem(key);
        return data?.isSyncedWithExternal
      }
      else{
        return this.webStorageObj?.isSyncedWithExternal
      }
    })
  }


  queryDataFromSource = (req:R ) => {

    return this.checkForPersistedData()
    .pipe(
      map(()=>{
        this.updateCurrentSource(req)
        let {startIndex,endIndex } = req.getIndexInfo()
        if(req.pageSize === Infinity){
          startIndex = 0
          endIndex = Infinity
        }
        if(this.currentSource.totalItems !==null){
          endIndex = endIndex > this.currentSource.totalItems ? this.currentSource.totalItems :endIndex
        }
        let expectedRange = endIndex-startIndex

        let haveAllItemsInRange= Array(expectedRange)
        .fill(null)
        .every((nullVal,index0)=>{
          let targetIndex = index0+startIndex

          return this.currentSource.data[targetIndex]
        })
        return {
          haveAllItemsInRange,startIndex,endIndex
        }
      }),
      concatMap(({haveAllItemsInRange,startIndex,endIndex})=>{
        return iif(
          ()=>haveAllItemsInRange,
          (()=>{
            let newResp = new WMLAPIPaginationResponseModel({
              pageNum:req.pageNum,
              data:this.currentSource.data.slice(startIndex,endIndex),
              // done for filter and sort logic and other instance where exact page values are unknwon
            }).calculateCurrentState(this.currentSource.totalPages,this.currentSource.totalItems,req.pageSize)

            return of(new WMLDataSourceSinkResponseBody({
              // @ts-ignore
              data:{
                page_num:newResp.pageNum,
                page_size:newResp.pageSize,
                data:newResp.data,
                total_items:newResp.totalItems,
                total_pages:newResp.totalPages
              }
            }))
          })(),
          (()=>{
            // @ts-ignore
            req= this.requestModifierPredicate(req,this);
            return this.getFromSink(req)
            .pipe(
              tap((res)=>{
                let startIndex = res.data.metadata.start_order_value
                if(this.currentSource.data.length < startIndex){
                  this.currentSource.data = this.currentSource.data.concat(
                    Array(startIndex-this.currentSource.data.length).fill(null)
                  )
                }
                this.currentSource.data.splice(
                  startIndex,
                  0,...res.data.data
                )


                res.data.data = this.currentSource.data.slice(startIndex,endIndex)

                this.currentSource.totalItems = res.data.total_items
                this.currentSource.totalPages = res.data.total_pages
              }),
              concatMap((res)=>{
                return this.syncWithBrowserStorage()
                .pipe(
                  map(()=>res)
                )

              })
            )
          })()
        )
      }),
      map(this.transformationPredicate)
    )




  };

  requestModifierPredicate:WMLDataSourceRequestModifierPredicate  =(req,self)=> {
    return req
  }

}



