import { VideoDataEditorPageProps } from "@app/pages/video-data-editor-page/video-data-editor-page.component"
import { ENV } from "@env/environment"
import { ListUsersVideoDataUIResponseBody } from "@shared/services/platforms/listUsersVideoData"
import localforage from "localforage"
import { defer } from "rxjs"
import { VideoTileZeroProps } from "./video-tile-zero.component"


export let getVideoDataEditorData =()=>{
  return defer(async()=>{
    let data:VideoDataEditorPageProps  =await localforage.getItem(ENV.localForage.VideoDataEditor) as any
    let props = new VideoDataEditorPageProps(data ??{})
    props.items = props.items.map((item)=>{

      return new VideoTileZeroProps(item)
    })
    return props
  })

}

export let getUpdatedUserVideoData =(returnNullIfEmpty = false)=>{
  return defer(async()=>{
    let data:ListUsersVideoDataUIResponseBody  =await localforage.getItem(ENV.localForage.UserVideoData) as any
    if(returnNullIfEmpty){
      return data ? new ListUsersVideoDataUIResponseBody(data) : null
    }
    return  new ListUsersVideoDataUIResponseBody(data ??{})
  })

}
