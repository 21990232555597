<div [class]="classPrefix('MainPod')">
  <section
  #videoContainer
  (pointermove)="listenForPointerMoveOnContainer()"
  (pointerleave)="listenForPointerLeaveOnContainer()"
  [class]="classPrefix('Pod0')">
    <img
    (error)=props.videoThumbnail.onError($event)
    [class]="classPrefix('Pod0Img0')"
    [ngStyle]="props.videoThumbnail.style"
    [src]=props.videoThumbnail.src
    [alt]="props.videoThumbnail.alt | translate" />

    <div
    *ngIf="!ENV.app.toPassChromeOauthVerficationTest"
    [class]="classPrefix('Pod0Item1')"
    >
      <wml-button-one

      [props]=downloadBtn></wml-button-one>
    </div>
  </section>
  <section [class]="classPrefix('Pod1')">
    <div [class]="classPrefix('Pod1Item0')">
      <ul [class]="classPrefix('Pod1List0')">
        <li [class]="classPrefix('Pod1Item1')">
          <p>{{"VideoTileZero.platform" | translate}}</p>
          <img
          [class]="classPrefix('SharedImg0')"
          [src]=props.platformIcon.src [alt]="props.platformIcon.alt | translate" />
        </li>
        <li [class]="classPrefix('Pod1Item1')">
          <p>{{"VideoTileZero.channel" | translate}}</p>
          <img
          [class]="classPrefix('SharedImg0')"
          [src]=props.channelIcon.src [alt]="props.channelIcon.alt | translate" />
        </li>
        <li [class]="classPrefix('Pod1Item1')">
          <p>{{"VideoTileZero.playlist" | translate}}</p>
          <p [class]="classPrefix('Pod1Text0')" >{{props.playlistTitle | translate }}</p>
        </li>
      </ul>
    </div>
  </section>
  <section [class]="classPrefix('Pod2')">
    <input
    [class]="classPrefix('Pod2Input0')"
    type="checkbox"
    [checked]="props.isChosenForVideoDataEdit"
    (change)="toggleIsChosenForVideoDataEditor($any($event).target.checked).subscribe()"
    >
    <wml-button-one [props]="deleteBtn"></wml-button-one>
  </section>
</div>
