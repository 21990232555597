import { WMLAPIPaginationRequestModel } from "@windmillcode/wml-components-base"
import { Subject, takeUntil, merge, fromEvent, debounceTime, tap, of, concatMap, catchError, switchMap, EMPTY, Observable, retry, take } from "rxjs"
import { WMLDataSource } from "./data-source-utils"
import { documentQuerySelector } from "./common-utils"

export let listEntitiesFail = (uiBody:WMLAPIPaginationRequestModel )=>{
  if(!uiBody.errorOccuredIsPresent){
    uiBody.errorOccuredIsPresent = true
    uiBody.pageNum = uiBody.pageNum === 0 ? 0 : uiBody.pageNum -1
  }
}


export class ScrollPageProps<T extends WMLAPIPaginationRequestModel=WMLAPIPaginationRequestModel>  {
  constructor(props: Partial<ScrollPageProps > = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, {
      ...Object.fromEntries(origProps),
      direction:props.direction ?? "vertical"
    });
    if(!this.manualInit){
      this.init()
    }
  }

  _keepMakingAPICalls = true


  targetElement:HTMLElement |string  = document.documentElement
  _targetElement:HTMLElement
  targetElementPollingInterval = 500
  dataSource:WMLDataSource<T> = new WMLDataSource<T>()
  pageReqBody = new WMLAPIPaginationRequestModel()
  listenForScollDebounceTime = 500
  retryAmount = 3
  amntOfPixelsFromEndBeforeRetrievingData = 5
  _direction:{
    direction:string
    scrollBegin:string,
    scrollDim:string,
    clientDim:string
  }
  get direction(){
    // @ts-ignore
    return this._direction
  }
  set direction(val:"horizontal" | "vertical"){
    this._direction = {
      "vertical":{
        "direction":"vertical",
        "scrollBegin":"scrollTop",
        "scrollDim":"scrollHeight",
        "clientDim":"clientHeight"
      },
      "horizontal":{
        "direction":"horizontal",
        "scrollBegin":"scrollLeft",
        "scrollDim":"scrollWidth",
        "clientDim":"clientWidth"
      }
    }[val] ?? this._direction
  }
  manualInit = false
  ngUnsub:Subject<void>

  init = (misc?:any)=>{
    this.getTargetElement()
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this._targetElement = res
        this.populateItemsAtStart(misc).subscribe()
        this.listenForScroll().subscribe()
      })
    )
    .subscribe()


  }
  beforeItemsRetrievedListener = ()=>{}
  onItemsRetrievedListener = (res,misc?:any)=>{}
  onItemsErrorListener = (err,misc?:any)=>{}
  /**
   * @readonly
  */
  getTargetElement = ()=>{
    return of(null)
    .pipe(
      takeUntil(this.ngUnsub),
      concatMap(()=>{
        if(this.targetElement instanceof HTMLElement){
          return of(this.targetElement)
        }
        else {
          return this.listenForWhenElementBecomesPresentViaMutationObserverSubj(this.targetElement)
        }

      }),
      catchError(()=>{
        if(this.targetElement instanceof HTMLElement){
          return of(this.targetElement)
        }
        return this.listenForWhenElementBecomesPresentViaPolling(this.targetElement as string)
      })
    )

  }
  /**
   * @readonly
  */
  listenForWhenElementBecomesPresentViaMutationObserverSubj =(targetSelector:string)=> {

    let subj = new Subject<HTMLElement>()
    let mutationObserver = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList') {
          const targetElement = documentQuerySelector(targetSelector) ;

          if (targetElement) {
            subj.next(targetElement);
            mutationObserver.disconnect();
            break;
          }
        }
      }
    });

    mutationObserver.observe(document.body, { childList: true, subtree: true });
    return subj
  }

  // TODO check and handle accordingly if these methods block
  quitAfterXAttemptsAtPolling =Infinity
  /**
   * @readonly
  */
  listenForWhenElementBecomesPresentViaPolling=(targetSelector:string) =>{
    let subj = new Subject<HTMLElement>()
    let counter = 0
    let pollingInterval = window.setInterval(() => {
      let targetElement = documentQuerySelector(targetSelector) ;

      if (targetElement) {
        clearInterval(pollingInterval);
        subj.next(targetElement);
      }
      else if(counter > this.quitAfterXAttemptsAtPolling){
        clearInterval(pollingInterval);
        subj.error("Element not found")
      }
      counter++

    }, this.targetElementPollingInterval);

    return subj
  }
  determineXPixelsFromEnd=()=> {
    let element = this._targetElement;

    let xPixelsFromEnd = Math.abs(
      ((element[this._direction.scrollDim] - element[this._direction.scrollBegin]) - element[this._direction.clientDim])
    );
    return xPixelsFromEnd;
  }
  getMoreItems =(misc?:any)=>{
    this.pageReqBody.pageNum +=1
    this.beforeItemsRetrievedListener()
    // @ts-ignore
    return this.dataSource.queryDataFromSource(this.pageReqBody)
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>this.onItemsRetrievedListener(res,misc)),
      retry(this.retryAmount),
      catchError((err)=>{
        this.pageReqBody.pageNum -=1
        this.onItemsErrorListener(err)
        return of(null)
      })
    )
  }
  populateWhenEndIsReached=(res) => {
    let xPixelsFromEnd = this.determineXPixelsFromEnd();
    this._keepMakingAPICalls =this.shouldKeepMakingAPICalls()

    if(xPixelsFromEnd < this.amntOfPixelsFromEndBeforeRetrievingData && this._keepMakingAPICalls  ){

      return this.getMoreItems()
    }
    return of(null)
  }
  hasScroll = (element?)=> {
    element ??=this._targetElement
    return {
        vertical: element.scrollHeight > element.clientHeight,
        horizontal: element.scrollWidth > element.clientWidth
    };
  }
  populateItemsAtStart = (misc?: any) => {
    this.pageReqBody.pageNum = -1;
    return this.populateUntilContainerStartsToScroll(misc).pipe(
      takeUntil(this.ngUnsub),
      take(1)
    );
  }
  populateUntilContainerStartsToScroll = (misc): Observable<any> => {
    return this.getMoreItems(misc).pipe(
      concatMap(() => {
        let scrollStatus = this.hasScroll();
        if (!scrollStatus[this._direction.direction] && this.shouldKeepMakingAPICalls()) {
          return this.populateUntilContainerStartsToScroll(misc);
        } else {
          return EMPTY;
        }
      })
    );
  };

  listenForScroll=()=>{
    return of(null)
    .pipe(
      takeUntil(this.ngUnsub),
      switchMap((res)=>{

        return  merge(
          fromEvent(this._targetElement, 'scroll'),
          fromEvent(window, 'resize')
        )
        .pipe(
          debounceTime(this.listenForScollDebounceTime),
          takeUntil(this.ngUnsub),
          concatMap(this.populateWhenEndIsReached)
        )
      })
    )



  }


  private shouldKeepMakingAPICalls() {
     return (this.dataSource.currentSource.data.length < this.dataSource.currentSource.totalItems) || ((this.pageReqBody.pageNum + 1) * this.pageReqBody.pageSize) < this.dataSource.currentSource.totalItems
  }
}
