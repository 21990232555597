import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"
import { ListJobsEntity } from "./listJobs"
import {  WMLConstructorDecorator } from '@windmillcode/wml-components-base';

export let updateJobsLoad = (uiBody:UpdateJobsUIRequestBody): Observable<UpdateJobsAPIRequestBody>=>{


  uiBody.jobs =uiBody.jobs
  .filter((job)=>{
    let letGo = ['BACKUP_PREVIOUS_STATE','UPDATE_VIDEOS'].includes(job.jobType) && !job.id.startsWith("jst")
    return !letGo
  })
  .map((job)=>{
    if(job.jobType === "TRANSFER_LOCAL_FILES"){
      job.details.transferLocalFilesDetails.files.map((myFile)=>{
        delete  myFile.streamSaver
        return myFile
      })
    }
    return job
  })


  let apiBody = new UpdateJobsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})

  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let updateJobsSuccess = (apiBody:UpdateJobsAPIResponseBody):UpdateJobsUIResponseBody=>{
  let uiBody = new UpdateJobsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class UpdateJobsUIRequestBody {
  constructor(props:Partial<UpdateJobsUIRequestBody>={}){}
  jobs:Array<ListJobsEntity> = []
  accessToken:string = ""
}

@WMLConstructorDecorator
export class UpdateJobsAPIRequestBody {
  constructor(props:Partial<UpdateJobsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<UpdateJobsUIRequestBody>
}

@WMLConstructorDecorator
export class UpdateJobsUIResponseBody {
  constructor(props:Partial<UpdateJobsUIResponseBody>={}){}
  updatedJobIds:Array<{
    client:string,
    server:string
  }> = []
}

@WMLConstructorDecorator
export class UpdateJobsAPIResponseBody {
  constructor(props:Partial<UpdateJobsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<UpdateJobsUIResponseBody>
}

