
import { EMPTY, Observable, catchError, concatMap, fromEvent, map, merge, of } from "rxjs";

export let BROWSER_MAX_ARRAY_BUFFER_SIZE = 300 * 1024 * 1024 //unit in mb
export function findMaxArrayBuffer() {
  let size = 1024 * 1024 * 1000; // Start with 1GB
  let increment = 1024 * 1024 * 1000; // Initial increment of 1GB
  let maxSize = 0;
  const MIN_INCREMENT = 1024 * 1024 * 1000 * (1/Math.pow(2,3)); // Minimum increment of 125MB

  let intervalId = setInterval(() => {
    try {
      let buffer = new ArrayBuffer(size);
      maxSize = size;
      size += increment;
    } catch (e) {
      if (increment > MIN_INCREMENT) {
        size -= increment; // Walk back to previous size
        increment /= 2; // Reduce increment by half
      } else {
        clearInterval(intervalId);
        BROWSER_MAX_ARRAY_BUFFER_SIZE = maxSize; // Assign maximum size in MB
        // console.log(`Maximum memory for a single ArrayBuffer: ${BROWSER_MAX_ARRAY_BUFFER_SIZE / (1024 * 1024)} MB`);
      }
    }
  }, 0);
}

export let makeAFileAndDowlnload = (base64String,filename='export_data')=>{
  let binaryString = atob(base64String);
  let uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([uint8Array]);

  let downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = filename ;
  downloadLink.click();
}
export function getFileType(fileName: string): string | null {
  const dotIndex = fileName.lastIndexOf('.');

  if (dotIndex === -1) {
      // No file extension found
      return null;
  }

  const fileType = fileName.substring(dotIndex + 1);
  return fileType.toLowerCase();
}

function joinArrayBuffers(buffers: any[]): ArrayBuffer {
  let totalLength = buffers.reduce((sum, buffer) => sum + buffer.byteLength, 0);
  let joined = new Uint8Array(totalLength);
  let offset = 0;
  for (let buffer of buffers) {
    joined.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  }
  return joined.buffer;
}

export let readFileContent = (
  file: File,
  readPredicate:
    | 'readAsBinaryString'
    | 'readAsArrayBuffer'
    | 'readAsDataURL'
    | 'readAsText' = 'readAsBinaryString',
    progress = false,action:"fullContent"|"chunk"|"stream"|"emitChunks"|"seek"="fullContent",
    chunkSize?:number,
    seek?:{
      offset:number,
      length:number
    }
):Observable<{
  content: any;
  chunkProgress: number;
  progress: number;
}> =>  {
  if(!chunkSize){
    chunkSize = BROWSER_MAX_ARRAY_BUFFER_SIZE * .4
  }

  if(action === "seek"){
    let currentPosition = seek.offset + seek.length;
    const blob = file.slice(seek.offset, currentPosition);
    const reader = new FileReader();

    reader[readPredicate](blob);
    return fromEvent(reader, 'load').pipe(
      concatMap(()=>{
        return of({ content: reader.result, progress: Math.min((currentPosition/file.size) * 100,100) ,chunkProgress:100 });
      })
    )

  }
  const chunks: any[] = [];
  let chunkContent;
  let offset = 0;
  let lastChunkProgress = 0
  const readChunk = (offset: number) => {
    const blob = file.slice(offset, offset + chunkSize);
    let reader = new FileReader();
    let progress$:Observable<{content:any,progress:number,chunkProgress:number}>  = EMPTY
    if (progress || action ==="emitChunks") {
      const totalSize = file.size;
      let chunkProgress
      progress$ = fromEvent(reader, 'progress').pipe(
        map((event: ProgressEvent<FileReader>) => {

          chunkProgress =event.loaded / event.total
          let progress = ((offset + event.loaded) / totalSize) * 100;
          progress = progress > 100 ? 100 : progress;
          let content =null
          // isNaN is for the last chunnk this fn adds the last chunk with a length of zero this helps us here
          if((lastChunkProgress > chunkProgress || isNaN(chunkProgress)) &&action ==="emitChunks" ){
            content = chunks.pop()
          }
          lastChunkProgress = chunkProgress;
          return {
            content,
            progress,
            chunkProgress
          }
        }),
      );
    }
    reader.onerror = (err)=>{
      console.error(err)
    }
    const chunkLoad$ = fromEvent(reader, 'load').pipe(
      concatMap(() => {

        if (readPredicate === 'readAsBinaryString') {
          // @ts-ignore
          chunkContent = btoa(reader.result);
        } else if (readPredicate === 'readAsArrayBuffer') {
          chunkContent = reader.result;
        } else {
          chunkContent = reader.result.toString();
        }
        chunks.push(chunkContent);

        if (offset < file.size) {
          offset += chunkSize;
          return readChunk(offset);
        } else {
          let completeContent
          if(action ==="fullContent"){
            if (readPredicate === 'readAsArrayBuffer') {
              completeContent = joinArrayBuffers(chunks);
            } else {
              completeContent = chunks.join('');
            }
          }
          else if(action === "chunk"){
            completeContent = chunks
          }
          else if(action ==="stream"){
            const readableStream = new ReadableStream({
              start(controller) {
                chunks.forEach(chunk => controller.enqueue(chunk));
                controller.close();
              }
            });
            completeContent = readableStream;
          }
          return of({ content: completeContent, progress: 100,chunkProgress:100 });
        }
      }),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    reader[readPredicate](blob);
    return merge(progress$, chunkLoad$);
  };




  return readChunk(offset) ;


};

// Serialize ArrayBuffer to Base64
export let arrayBufferToBase64 = (buffer: ArrayBuffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

//
export let base64ToUint8Array = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

// Deserialize Base64 to ArrayBuffer
export let base64ToArrayBuffer = (base64: string) => {
  return base64ToUint8Array(base64).buffer;
};

export const chunkFileContent = (content: ArrayBuffer | string, chunkSize = 16384,orderStart=0) => {
  const chunks = [];
  let totalChunks;

  if (content instanceof ArrayBuffer) {
    // Handle ArrayBuffer content
    const buffer = new Uint8Array(content);
    totalChunks = Math.ceil(buffer.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, buffer.length);
      const chunk = buffer.slice(start, end).buffer;  // Slice the Uint8Array and convert it back to ArrayBuffer
      const serializedChunk = arrayBufferToBase64(chunk);
      chunks.push({
        chunk: serializedChunk,
        order: i+orderStart
      });
    }
  } else if (typeof content === 'string') {
    // Handle string content
    totalChunks = Math.ceil(content.length / chunkSize);

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, content.length);
      const chunk = content.slice(start, end);
      chunks.push({
        chunk,
        order: i+orderStart
      });
    }
  } else {
    throw new Error(`Unsupported content type: ${typeof content}`);
  }

  return chunks;
};




export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function parseFileSize(value: number, unit: 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB'): number {
  let k = 1024;
  let unitOrder = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  // Determine the exponent based on the unit
  const exponent = unitOrder.indexOf(unit);

  // Calculate the number of bytes
  return value * Math.pow(k, exponent);
}
