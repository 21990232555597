import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let EmailPasswordRecoveryLinkLoad = (uiBody:EmailPasswordRecoveryLinkUIRequestBody): Observable<EmailPasswordRecoveryLinkAPIRequestBody>=>{



  let apiBody = new EmailPasswordRecoveryLinkAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let EmailPasswordRecoveryLinkSuccess = (apiBody:EmailPasswordRecoveryLinkAPIResponseBody):EmailPasswordRecoveryLinkUIResponseBody=>{
  let uiBody = new EmailPasswordRecoveryLinkUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class EmailPasswordRecoveryLinkUIRequestBody {
  constructor(props:Partial<EmailPasswordRecoveryLinkUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  email:string
}

export class EmailPasswordRecoveryLinkAPIRequestBody {
  constructor(props:Partial<EmailPasswordRecoveryLinkAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<EmailPasswordRecoveryLinkUIRequestBody>
}

export class EmailPasswordRecoveryLinkUIResponseBody {
  constructor(props:Partial<EmailPasswordRecoveryLinkUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class EmailPasswordRecoveryLinkAPIResponseBody {
  constructor(props:Partial<EmailPasswordRecoveryLinkAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<EmailPasswordRecoveryLinkUIResponseBody>
}

