export class DateTimeZero  {
  constructor(props: Partial<DateTimeZero > = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  years = 0;
  months = 0;
  days = 0;
  hours = 0;
  minutes =0;
  seconds = 0;
  milliseconds = 0;

  normalize(value: number, unit: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds') {
    const MILLISECONDS_IN_A_SECOND = 1000;
    const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * 60;
    const MILLISECONDS_IN_AN_HOUR = MILLISECONDS_IN_A_MINUTE * 60;
    const MILLISECONDS_IN_A_DAY = MILLISECONDS_IN_AN_HOUR * 24;
    const MILLISECONDS_IN_A_MONTH = MILLISECONDS_IN_A_DAY * 30; // Approximation
    const MILLISECONDS_IN_A_YEAR = MILLISECONDS_IN_A_DAY * 365; // Approximation

    let totalMilliseconds = 0;

    switch (unit) {
      case 'years':
        totalMilliseconds = value * MILLISECONDS_IN_A_YEAR;
        break;
      case 'months':
        totalMilliseconds = value * MILLISECONDS_IN_A_MONTH;
        break;
      case 'days':
        totalMilliseconds = value * MILLISECONDS_IN_A_DAY;
        break;
      case 'hours':
        totalMilliseconds = value * MILLISECONDS_IN_AN_HOUR;
        break;
      case 'minutes':
        totalMilliseconds = value * MILLISECONDS_IN_A_MINUTE;
        break;
      case 'seconds':
        totalMilliseconds = value * MILLISECONDS_IN_A_SECOND;
        break;
      case 'milliseconds':
        totalMilliseconds = value;
        break;
    }

    this.years = Math.floor(totalMilliseconds / MILLISECONDS_IN_A_YEAR);
    totalMilliseconds %= MILLISECONDS_IN_A_YEAR;

    this.months = Math.floor(totalMilliseconds / MILLISECONDS_IN_A_MONTH);
    totalMilliseconds %= MILLISECONDS_IN_A_MONTH;

    this.days = Math.floor(totalMilliseconds / MILLISECONDS_IN_A_DAY);
    totalMilliseconds %= MILLISECONDS_IN_A_DAY;

    this.hours = Math.floor(totalMilliseconds / MILLISECONDS_IN_AN_HOUR);
    totalMilliseconds %= MILLISECONDS_IN_AN_HOUR;

    this.minutes = Math.floor(totalMilliseconds / MILLISECONDS_IN_A_MINUTE);
    totalMilliseconds %= MILLISECONDS_IN_A_MINUTE;

    this.seconds = Math.floor(totalMilliseconds / MILLISECONDS_IN_A_SECOND);
    totalMilliseconds %= MILLISECONDS_IN_A_SECOND;

    this.milliseconds = totalMilliseconds;
  }
}

export function calculateTimeInFuture(duration: DateTimeZero): Date {
  const now = new Date();
  now.setFullYear(now.getFullYear() + duration.years);
  now.setMonth(now.getMonth() + duration.months);
  now.setDate(now.getDate() + duration.days);
  now.setHours(now.getHours() + duration.hours);
  now.setMinutes(now.getMinutes() + duration.minutes);
  now.setSeconds(now.getSeconds() + duration.seconds);
  now.setMilliseconds(now.getMilliseconds() + duration.milliseconds);

  return now;
}
