<form [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <ol [class]="classPrefix('Pod0Item0')" *ngFor="let item of multiFields">
      <h3
      *ngIf="item.index.isPresent"
      [class]="classPrefix('Pod0Text0')" >{{(item.index.text) | translate}}.</h3>
      <li [class]="classPrefix('Pod0Item1')">
        <wml-field-zero [class]="classPrefix('Pod0Field0')" [props]="item.field" ></wml-field-zero>
        <wml-button-one
        *ngIf="item.btn.isPresent"
        [class]="classPrefix('Pod0Btn0')"
        [props]="item.btn"
        ></wml-button-one>
    </li>

    </ol>
  </section>
  <section [class]="classPrefix('Pod1')">
    <wml-button-one
    [class]="classPrefix('Pod1Btn0')"
    *ngIf="props.addFieldBtn.isPresent"
    [props]="addFieldBtn"
    ></wml-button-one>
    <wml-button-one
    [class]="classPrefix('Pod1Btn1')"
    *ngIf="mainFormSubmitBtn.isPresent"
    [props]="mainFormSubmitBtn"></wml-button-one>
  </section>
</form>
