import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import {
  BaseServiceSubmitFormProps,
  BaseService,
} from '@core/base/base.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  generateClassPrefix,
  generateIdPrefix,
} from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import {
  resetFormControls,
} from '@core/utility/form-utils';

import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';

import {
  WMLButtonOneProps,
  WMLButtonPropsTypeEnum,
} from '@windmillcode/angular-wml-button';

import { FormsService } from '@shared/services/forms/forms.service';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { WMLInputZeroAreaProps, WMLInputZeroProps } from '@windmillcode/angular-wml-input';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { WMLUIProperty } from '@windmillcode/wml-components-base';
import { deepCopyInclude } from '@core/utility/object-utils';

@Component({
  selector: 'multi-input-one',
  templateUrl: './multi-input-one.component.html',
  styleUrls: ['./multi-input-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiInputOneComponent {
  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
  ) {}

  classPrefix = generateClassPrefix('MultiInputOne');
  idPrefix = generateIdPrefix(ENV.idPrefix.multiInputOne);
  @Input('props') props: MultiInputOneProps = new MultiInputOneProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  multiFields= [];
  getFormArray = () => {
    return this.mainFormGroup.controls.value as FormArray
  }
  clickRemoveField = (index) => {

    let urlsFormArray =this.getFormArray()
    urlsFormArray.removeAt(index)
    this.multiFields.splice(index,1)
    this.multiFields.forEach((item,index0)=>{
      item.index.text = (index0+1)
      item.btn.click = ()=>this.clickRemoveField(index0)
    })

    if(this.multiFields.length < this.props.minEntries){
      this.addFields()
    }
    this.cdref.detectChanges()
  }
  addFields = (amount=1)=>{

    if(this.props.limit < this.multiFields.length + amount){
      this.baseService.createWMLNote(this.props.limitText)

    }
    else{
      Array(amount)
      .fill(null)
      .map((nullVal,index0)=>{

        let formArray =this.getFormArray()
        let currentFieldIndex=  formArray.controls.length
        let metadata = this.props.getFieldCreatorPredicate(currentFieldIndex)
        formArray.push(metadata.reactiveFormControl ?? new FormGroup({
          value:new FormControl('',[Validators.required])
        }))
        metadata.entryNumber.text = (currentFieldIndex+1).toString()
        this.multiFields.push({
          index:metadata.entryNumber,
          field:this.createValueField(currentFieldIndex,metadata),
          btn: new WMLButtonOneProps({
            text:metadata.removeBtn.text ?? "global.remove",
            click:metadata.removeBtn.click ?? (()=>this.clickRemoveField(currentFieldIndex)),
            isPresent:metadata.removeBtn.isPresent??true

          })
        })
      })
      this.cdref.detectChanges()
    }

  }
  addFieldBtn = new WMLButtonOneProps({
    text:"global.add",
    style:{
      width:"calc(100/10.6 * 1em)"
    },
    click: ()=>{
      this.addFields()
    }
  })


  listenForUpdate = () => {
    return this.props.updateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new MultiInputOneProps({
          ...this.props,
          ...(res ?? this.props),
        });
        this.cdref.detectChanges();
      }),
    );
  };

  mainForm = new WMLFormZeroProps({});
  mainFormGroup = new FormGroup({
    value:new FormArray([],Validators.required)
  });

  createMainFormField = (props:Partial<{fieldParentForm:AbstractControl,labelValue:any,createWMLFieldFn:any,errorMsgKeyArray:any,fieldCustomProps:any,formControlName: keyof  MultiInputOneComponent["mainFormGroup"]["value"]}>) => {
    let {
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
      fieldParentForm,
      labelValue
    } = props;

    let field = this.baseService.createWMLField({
      i18nPrefix: 'MultiInputOne.mainForm',
      idPrefixFn: this.idPrefix,
      fieldParentForm: fieldParentForm ?? this.mainFormGroup,
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
      labelValue
    });

    return field;
  };
  mainFormSubmitBtn = new WMLButtonOneProps({
    type: WMLButtonPropsTypeEnum.PRIMARY,
    text: 'global.submitBtn',
    style:{
      width:"calc(100/10.6 * 1em)"
    },
    click: () =>this.mainFormClickSubmitBtn(),
  });

  mainFormClickSubmitBtn = () => {
    this.baseService.submitForm(
      new BaseServiceSubmitFormProps({
        rootFormGroup: this.mainFormGroup,
        openOverlayLoading:false,
        invalidFormPredicate:()=>{
          this.baseService.tellUserToFillOutRequiredFields(
            // @ts-ignore
            this.getFormArray().controls,this.cdref
          )
        },
        cdref: this.cdref,
        validFormPredicateType:"custom",
        validFormPredicateTypeCustom:()=>{

          this.props.submitEvent.next(this.mainFormGroup)
          if(this.props.clearForm){
            resetFormControls(this.mainFormGroup)
            this.multiFields = []
            this.cdref.detectChanges()
            this.addFields()
          }
        },

      }),
    );
  };

  createValueField = (index=0,metadata:MultiInputOneFieldCreatorProps) => {
    let fieldParentForm = this.getFormArray().controls[index]
    let field = this.createMainFormField({
      formControlName: "value",
      errorMsgKeyArray: [],
      createWMLFieldFn:metadata.createWMLFieldFn ??this.baseService.createInputFormField,
      fieldCustomProps:metadata.fieldCustomProps ?? new WMLInputZeroProps({
        input: new WMLInputZeroAreaProps({
          id: this.idPrefix('valueField'),
        })
      }),
      fieldParentForm,
      labelValue: "MultiInputOne.mainForm.valueField.label"
    });
    return metadata.modifyFieldPredicate(field) ?? field

  };

  initMainForm = () => {
    this.addFields(this.props.startingAmount);
  };



  ngOnInit(): void {
    this.myId = this.props.id
    this.props.internalMainFormGroup = this.mainFormGroup
    this.mainFormSubmitBtn.text = this.props.submitBtn.text
    this.mainFormSubmitBtn = new WMLButtonOneProps({
      ...this.mainFormSubmitBtn,
      ...deepCopyInclude(["isPresent","text"],this.props.submitBtn)
    })
    this.initMainForm();
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class MultiInputOneProps {
  constructor(props: Partial<MultiInputOneProps &{
    propAddFieldBtnIsPresent:boolean
    propSubmitBtnIsPresent:boolean
    propAddFieldBtnText:string
    propSubmitBtnText:string
  }> = {}) {
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
    if(![null,undefined].includes(props.propAddFieldBtnIsPresent)){
      this.addFieldBtn.isPresent = props.propAddFieldBtnIsPresent
    }
    if(![null,undefined].includes(props.propSubmitBtnIsPresent)){
      this.submitBtn.isPresent = props.propSubmitBtnIsPresent
    }
    if(![null,undefined].includes(props.propAddFieldBtnText)){
      this.addFieldBtn.text = props.propAddFieldBtnText
    }
    if(![null,undefined].includes(props.propSubmitBtnText)){
      this.submitBtn.text = props.propSubmitBtnText
    }
  }
  id:string
  startingAmount=1
  minEntries = 1
  updateSubj = new Subject<MultiInputOneProps>();
  // do not provide a value the component will provide one
  // only grab the value from the formGroup to use in your own dont use interchanegly because with angular
  // FormControl are not to be shared across ParentForms
  internalMainFormGroup
  submitEvent = new Subject<FormGroup<{value:FormArray}>>()
  clearForm= true
  limit = 51
  limitText="MultiInputOne.WMLNotifyOne.limit"
  addFieldBtn = new WMLButtonOneProps()
  submitBtn  = new WMLButtonOneProps({
    text: 'global.submitBtn'
  })
  wmlField:WMLFieldZeroProps
  getFieldCreatorPredicate = (index0)=>{
    return new MultiInputOneFieldCreatorProps()
  }
}


export  class MultiInputOneFieldCreatorProps {
  constructor(props: Partial<MultiInputOneFieldCreatorProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
    if(![null,undefined].includes(props.propRemoveBtnIsPresent)){
      this.removeBtn.isPresent = props.propRemoveBtnIsPresent
    }
    if(![null,undefined].includes(props.propEntryNumberIsPresent)){
      this.entryNumber.isPresent = props.propEntryNumberIsPresent
    }
  }

  createWMLFieldFn=null
  fieldCustomProps=null
  removeBtn=new WMLButtonOneProps({
    text:null,click:null
  })
  propRemoveBtnIsPresent?:boolean
  entryNumber = new WMLUIProperty({

  })
  reactiveFormControl:AbstractControl
  propEntryNumberIsPresent?:boolean
  modifyFieldPredicate:(field:WMLFieldZeroProps) => WMLFieldZeroProps | void = (field)=>{
    field.deleteRequiredLabelPart();
    return field
  }
}
