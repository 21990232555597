<div [class]="classPrefix('MainPod')">
  <form [formGroup]="props.formGroup" [class]="classPrefix('Pod0')">
    <p [class]="classPrefix('Pod0Text0')">
      <span [class]="classPrefix('Pod0Text1')">{{props.insertText | translate}}</span>
      <input formControlName="val" type="number" [max]="props.formGroup.value.max" [class]="classPrefix('SharedInput0')"/>
      <span>{{props.ofText | translate}}</span>
      <input formControlName="max" type="number"  [class]="classPrefix('SharedInput0')"/>
    </p>
  </form>
</div>
