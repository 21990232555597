<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <card-one [class]="classPrefix('Pod0Item0')" [props]="titleCard"></card-one>
    <i
    (click)="props.close()"
    [class]="classPrefix('Pod0Xmark0')">X</i>
  </section>
  <section [class]="classPrefix('Pod1')">
    <h3 [class]="classPrefix('Pod1Text0')" *ngIf="listJobs.length ===0 && !ENV.app.toPassChromeOauthVerficationTest">
      {{"JobsZero.noJobs" | translate}}
    </h3>
      <div
      *ngFor="let job of listJobs"
      [class]="classPrefix('Pod1Item0')"
      >
        <div [class]="classPrefix('Pod1Item2')"></div>
        <wml-accordion-zero [props]="job.card"></wml-accordion-zero>
        <div [class]="classPrefix('Pod1Item1')">
          <wml-button-one *ngIf="job.startJobBtn.isPresent" [props]="job.startJobBtn"></wml-button-one>
          <wml-button-one [props]="job.deleteJobBtn"></wml-button-one>
        </div>
        <overlay-loading *ngIf="job.props.props.jobInfo.status === 'IN_PROGRESS' && job.jobType !='TRANSFER_LOCAL_FILES'"  [class]="classPrefix('Pod1Item3')">
        </overlay-loading>
        <div *ngIf="job.props.props.jobInfo.status === 'IN_PROGRESS' && job.jobType=='TRANSFER_LOCAL_FILES'"   [class]="classPrefix('Pod1Item3') + classPrefix('Pod1Item4')">
        </div>
      </div>
  </section>
  <section [class]="classPrefix('Pod2')">
    <wml-button-one
    *ngIf="!ENV.app.toPassChromeOauthVerficationTest"
    [props]="addjobBtn"></wml-button-one>
    <wml-button-one [props]="clearFinishedAndFailedJobsBtn"></wml-button-one>
  </section>
</div>
