<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">

    <ul [class]="classPrefix('Pod0Item0')">
      <li
      [class]="classPrefix('Pod0Text0')"
      *ngFor="let item of props.items">
        <wml-button-one
        [props]="item"></wml-button-one>
      </li>
    </ul>
  </section>
</div>
