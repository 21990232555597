import { transformObjectKeys } from '@core/utility/common-utils';
import {
  RecursiveSnakeCaseType,
  transformFromCamelCaseToSnakeCase,
  transformFromSnakeCaseToCamelCase,
} from '@core/utility/string-utils';
import {
  WMLAPIPaginationRequestModel,
  WMLAPIPaginationResponseModel,
  WMLConstructorDecorator,
  WMLDeepPartial,
} from '@windmillcode/wml-components-base';
import { Observable, of, take } from 'rxjs';
import { PlatformsVideoDataEditorZeroActionEnum, PlatformsVideoDataEditorZeroSectionEnum } from '../platforms/platforms.service';

export let listJobsLoad = (
  uiBody: ListJobsUIRequestBody,
  accessToken: string,
): Observable<ListJobsAPIRequestBody> => {
  let apiBody = new ListJobsAPIRequestBody({
    data: transformObjectKeys(uiBody, transformFromCamelCaseToSnakeCase),
  });
  apiBody.data.access_token = accessToken;
  return of(apiBody).pipe(take(1));
};

export let listJobsSuccess = (
  apiBody: ListJobsAPIResponseBody,
): ListJobsUIResponseBody => {

  let uiBody = new ListJobsUIResponseBody(
    transformObjectKeys(apiBody.data, transformFromSnakeCaseToCamelCase),
  );
  return uiBody;
};

@WMLConstructorDecorator
export class ListJobsUIRequestBody extends WMLAPIPaginationRequestModel {
  constructor(props: Partial<ListJobsUIRequestBody> = {}) {super()}
  accessToken: string = '';
}

@WMLConstructorDecorator
export class ListJobsAPIRequestBody {
  constructor(props: Partial<ListJobsAPIRequestBody> = {}) {

  }
  data: RecursiveSnakeCaseType<ListJobsUIRequestBody>;
}


@WMLConstructorDecorator
export class ListJobsUIResponseBody<
  T = ListJobsEntity,
>  extends WMLAPIPaginationResponseModel<T> {

}

@WMLConstructorDecorator
export class ListJobsAPIResponseBody  {
  constructor(props: Partial<ListJobsAPIResponseBody> = {}) {}

  data: RecursiveSnakeCaseType<ListJobsUIResponseBody>;
}

@WMLConstructorDecorator
export class ListJobsEntity  {
  constructor(props: Partial<ListJobsEntity> = {}) {}
  // properties from the UI
  // TODO in future never replace Id just have client and server id
  id: string; // ui will create this id then API updates it
  serverId:string;
  jobType:
    | 'BULK_DOWNLOAD'
    | 'SHORTS_GENENERATION'
    | 'USER_VIDEO_DATA_RETRIEVAL'
    | 'BACKUP_PREVIOUS_STATE'
    | 'UPDATE_VIDEOS'
    | 'TRANSFER_LOCAL_FILES'
    ;
  isChosen: boolean;
  isDeleted: boolean;
  details: WMLDeepPartial<{
    bulkDownloadDetails: {
      totalSize: string;
      platforms: Array<{
        id: string;
        channels: Array<number>;
        playlists: Array<number>;
        videos: Array<number>;
      }>;
      channels: Array<{
        id: string;
        thumbnailUrl: string;
        includeThumbnail: boolean;
        includeDescription: boolean;
        playlists: Array<number>;
        videos: Array<number>;
      }>;
      playlists: Array<{
        id: string;
        videos: Array<number>;
      }>;
      videos: Array<{
        id: string;
        title: string;
        fileSize: number;
        downloadUrl: string;
        thumbnailUrl: string;
        description: string;
        tags: Array<string>;
        includeThumbnail: boolean;
        includeVideo: boolean;
        includeAudio: boolean;
        includeDescription: boolean;
        includeTags: boolean;
        results:{
          status: 'SUCCESS' | 'FAILED' | 'CANCELED';
        }
      }>;

    };
    backupPreviousStateDetails:{

    },
    updateVideosDetails:{
      videos:Array<{
        title:string,
        actionTypeEnumValue:PlatformsVideoDataEditorZeroActionEnum
        sectionTypeEnumValue :PlatformsVideoDataEditorZeroSectionEnum;
        // images are links anything too long gets ellipsis maybe with a tooltip
        newValue:string
      }>
    },
    transferLocalFilesDetails:{
      clientType:string
      recipients:Array<string>
      senders:Array<string>
      estimatedTimeInMs:number
      files:Array<{
        // the application never sends the job to the server webrtc and clients will update this job type
        name:string
        content:Array<{
          // @deprecated not used
          // chunk:any,
          // order:number,
          progress:number,
          chunkSize:number
          // TODO append InMS
          recipientDelayTime:number
        }>
        streamSaver:any
        type:string
        url:string
        file:File
        size:number
        progressPrecision:number
        status:ListJobsEntity["status"]
      }>
    }

  }>;

  // properties from the API
  firebaseUserId: string;
  status:
    | 'NOT_STARTED'
    | 'PENDING'
    | 'IN_PROGRESS'
    | 'SUCCESS'
    | 'FAILED'
    | 'CANCELED'
    | 'RETRY'
    | 'ISSUES';
  retryCount: number;
  priority: 'LOW' | 'MEDIUM' | 'HIGH' | 'URGENT';
  createdAt: string;
  startedAt: string;
  completedAt: string;
  resultUrls: Array<string>;
}

