import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"
import { ListUsersVideoDataUIResponseBody } from "./listUsersVideoData"
import { ENV } from "@env/environment"

export let listUsersCurrentVideoDataLoad = (uiBody:ListUsersCurrentVideoDataUIRequestBody): Observable<ListUsersCurrentVideoDataAPIRequestBody>=>{



  let apiBody = new ListUsersCurrentVideoDataAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listUsersCurrentVideoDataSuccess = (apiBody:ListUsersCurrentVideoDataAPIResponseBody):ListUsersCurrentVideoDataUIResponseBody=>{

  if(typeof apiBody.data === "string"){
    return apiBody.data
  }
  let uiBody = new ListUsersCurrentVideoDataUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class ListUsersCurrentVideoDataUIRequestBody {
  constructor(props:Partial<ListUsersCurrentVideoDataUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  accessToken:string
  googleDriveApiAccessToken:string
  googleDriveApiRefreshToken:string
  appPlatformTypeEnumValue =ENV.platformType
}

export class ListUsersCurrentVideoDataAPIRequestBody {
  constructor(props:Partial<ListUsersCurrentVideoDataAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ListUsersCurrentVideoDataUIRequestBody>
}

export class ListUsersCurrentVideoDataUIResponseBody extends ListUsersVideoDataUIResponseBody {
  constructor(props:Partial<ListUsersCurrentVideoDataUIResponseBody>={}){
    super(props);
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class ListUsersCurrentVideoDataAPIResponseBody extends ListUsersVideoDataUIResponseBody {
  constructor(props:Partial<ListUsersCurrentVideoDataAPIResponseBody>={}){
    super(props)
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ListUsersCurrentVideoDataUIResponseBody>
}

