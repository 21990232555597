import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { PlatformsService } from "../platforms/platforms.service"

export let updateFriendsLoad = (uiBody:UpdateFriendsUIRequestBody): Observable<UpdateFriendsAPIRequestBody>=>{



  let apiBody = new UpdateFriendsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let updateFriendsSuccess = (apiBody:UpdateFriendsAPIResponseBody):UpdateFriendsUIResponseBody=>{
  console.log(apiBody.data)
  if(apiBody.data ===PlatformsService.NEED_NEW_TOKEN){
    return apiBody.data
  }
  let uiBody = new UpdateFriendsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class UpdateFriendsUIRequestBody  {
  constructor(props:Partial<UpdateFriendsUIRequestBody>={}){ }

  accessToken:string =""
  friends:Array<{
    email:string,
    receiveFiles:boolean
  }> = []
}

@WMLConstructorDecorator
export class UpdateFriendsAPIRequestBody {
  constructor(props:Partial<UpdateFriendsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<UpdateFriendsUIRequestBody>
}

@WMLConstructorDecorator
export class UpdateFriendsUIResponseBody {
  constructor(props:Partial<UpdateFriendsUIResponseBody>={}){}

}

@WMLConstructorDecorator
export class UpdateFriendsAPIResponseBody {
  constructor(props:Partial<UpdateFriendsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<UpdateFriendsUIResponseBody>
}

