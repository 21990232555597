import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"
import { ListJobsEntity } from "./listJobs"
import { UpdateJobsUIResponseBody } from "./updateJobs"
import {WMLConstructorDecorator, WMLDeepPartial,} from '@windmillcode/wml-components-base';
import { GoogleDriveYoutubeDataAPIBase } from "@core/utility/specific-utils/classes-utils"
import { LoadUserVideoDataEditorSettingsUIResponseBody } from "../platforms/loadUserVideoDataEditorSettings"
import { PlatformsVideoDataEditorZeroActionEnum, PlatformsVideoDataEditorZeroSectionEnum } from "../platforms/platforms.service"

export let runJobsLoad = (uiBody:RunJobsUIRequestBody): Observable<RunJobsAPIRequestBody>=>{

  let apiBody = new RunJobsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let runJobsSuccess = (apiBody:RunJobsAPIResponseBody):RunJobsUIResponseBody=>{
  let uiBody = new RunJobsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}


@WMLConstructorDecorator
export class RunJobsUIRequestBody extends GoogleDriveYoutubeDataAPIBase {
  constructor(props:Partial<RunJobsUIRequestBody>={}){ super()}
  jobs:Array<ListJobsEntity> = []

  localeTimestamp = new Date().toLocaleString()
}

@WMLConstructorDecorator
export class RunJobsAPIRequestBody {
  constructor(props:Partial<RunJobsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<RunJobsUIRequestBody>
}

@WMLConstructorDecorator
export class RunJobsUIResponseBody {
  constructor(props:Partial<RunJobsUIResponseBody>={}){}
  taskId:string
  updatedJobIds:UpdateJobsUIResponseBody["updatedJobIds"] = []
  jobInfo:Partial<Pick<ListJobsEntity,"id"|"status">>[] =[]
}

@WMLConstructorDecorator
export class RunJobsAPIResponseBody {
  constructor(props:Partial<RunJobsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<RunJobsUIResponseBody>[]
}

@WMLConstructorDecorator
export class RunJobsEntity extends ListJobsEntity {
  constructor(props: Partial<RunJobsEntity> = {}) {super() }

  override details:WMLDeepPartial<Omit<ListJobsEntity["details"], "backupPreviousStateDetails" | "updateVideosDetails" | "transferLocalFilesDetails"> &{
    backupPreviousStateDetails: ListJobsEntity["details"]["backupPreviousStateDetails"] & {
      videoIds:Array<{
        platformTitle:string,
        id:string,
      }>
    },
    transferLocalFilesDetails : ListJobsEntity["details"]["transferLocalFilesDetails"] & {
      files:Array<{
        name:string
        content:string
        type:string
      }>
    }
    updateVideosDetails:{
      videos:Array<
      Omit<LoadUserVideoDataEditorSettingsUIResponseBody["content"],"thumbnail"> & {
        platform:string,
        id:string,
        actionTypeEnumValue:PlatformsVideoDataEditorZeroActionEnum
        sectionTypeEnumValue :PlatformsVideoDataEditorZeroSectionEnum;
        thumbnail:{
          name:string
          content:string
          type:string
        }
      }>
    }
  }>
}
