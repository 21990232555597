<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <p>{{ "CurrentPageZero.text" | translate }}</p>
        <form
        [class]="classPrefix('Pod0Input0')"
        [formGroup]="formGroup">
          <input
          formControlName="currentPage" />
        </form>
      <p
      [class]="classPrefix('Pod0Text0')"
      > / {{ props.totalPages }}</p>
    </div>

  </section>

</div>
