import { WMLConstructorDecorator } from "@windmillcode/wml-components-base";

@WMLConstructorDecorator
export class SocketioClientResponseModel<R=any> {
  constructor(props: Partial<SocketioClientResponseModel<R>> = {}) { }

  code:number
  data:{
    taskId:string
    result:R
  }
}
