import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// Services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// RxJS
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// WML components
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// Miscellaneous
import { ENV } from '@env/environment';
import enTranslations from "src/assets/i18n/en.json";
import { WMLOptionZeroItemProps, WMLOptionsZeroProps } from '@windmillcode/angular-wml-options';

@Component({
  selector: 'sort-zero',
  templateUrl: './sort-zero.component.html',
  styleUrls: ['./sort-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortZeroComponent {

  classPrefix = generateClassPrefix('SortZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.sortZero);
  @Input('props') props: SortZeroProps = new SortZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) { }
  enTranslations = enTranslations
  sortFields = enTranslations.SortZero.fields.map((text,index0)=>{
    let props = new WMLOptionsZeroProps({
      limit:1,
      options:enTranslations.SortZero.direction.map((text,index1)=>{

        let key = enTranslations.SortZero.fields[index0]
        return new WMLOptionZeroItemProps({
          text:"SortZero.direction."+index1,
          value:[
            [key,"ASC"],
            [key,"DESC"]
          ][index1],
          click:()=>{
            this.props.sortEvent.next(props.formArray.value[0]?.value ?? [key,""])
          }
        })
      })
    })
    return {
      text:"SortZero.fields."+index0,
      props
    }
  })

  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new SortZeroProps({
          ...this.props,
          ...(res ?? this.props)
        });
        this.cdref.detectChanges();
      })
    );
  }

  ngOnInit(): void {
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class SortZeroProps {
  constructor(props: Partial<SortZeroProps> = {}) { }

  setStateSubj = new Subject<SortZeroProps>();
  id:string
  setState = (value) => {
    this.setStateSubj.next(value);
  }
  sortEvent = new Subject<[string,string]>()
}
