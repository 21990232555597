import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let changePasswordLoad = (uiBody:ChangePasswordUIRequestBody): Observable<ChangePasswordAPIRequestBody>=>{



  let apiBody = new ChangePasswordAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let changePasswordSuccess = (apiBody:ChangePasswordAPIResponseBody):ChangePasswordUIResponseBody=>{
  let uiBody = new ChangePasswordUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class ChangePasswordUIRequestBody {
  constructor(props:Partial<ChangePasswordUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  currentPass:string
  pass:string
  confirmPass:string
}

export class ChangePasswordAPIRequestBody {
  constructor(props:Partial<ChangePasswordAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ChangePasswordUIRequestBody>
}

export class ChangePasswordUIResponseBody {
  constructor(props:Partial<ChangePasswordUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class ChangePasswordAPIResponseBody {
  constructor(props:Partial<ChangePasswordAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ChangePasswordUIResponseBody>
}

