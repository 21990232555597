import { transformObjectKeys } from "@core/utility/common-utils"
import { AuthBase } from "@core/utility/specific-utils/classes-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let cancelSubscriptionLoad = (uiBody:CancelSubscriptionUIRequestBody): Observable<CancelSubscriptionAPIRequestBody>=>{



  let apiBody = new CancelSubscriptionAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let cancelSubscriptionSuccess = (apiBody:CancelSubscriptionAPIResponseBody):CancelSubscriptionUIResponseBody=>{
  let uiBody = new CancelSubscriptionUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class CancelSubscriptionUIRequestBody extends AuthBase{
  constructor(props:Partial<CancelSubscriptionUIRequestBody>={}){super()}
  subscriptionContractInfo:Array<{
    id:string,
    confirmationNumber:string
  }> = []
}

@WMLConstructorDecorator
export class CancelSubscriptionAPIRequestBody {
  constructor(props:Partial<CancelSubscriptionAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<CancelSubscriptionUIRequestBody>
}

@WMLConstructorDecorator
export class CancelSubscriptionUIResponseBody {
  constructor(props:Partial<CancelSubscriptionUIResponseBody>={}){}

}

@WMLConstructorDecorator
export class CancelSubscriptionAPIResponseBody {
  constructor(props:Partial<CancelSubscriptionAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<CancelSubscriptionUIResponseBody>
}

