<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div *ngFor="let video of props.changedVideos" [class]="classPrefix('Pod0Item0')">
      <div [class]="classPrefix('Pod0Item1')">
        <h4 [class]="classPrefix('SharedText0')">{{video.title | translate}}</h4>
        <h4>{{"VideoDataEditorZero.action."+video.actionTypeEnumValue | translate}}</h4>
      </div>
      <div [class]="classPrefix('Pod0Item1')">
        <h4 [class]="classPrefix('SharedText0')">{{"VideoDataEditorZero.types."+video.sectionTypeEnumValue | translate}}</h4>
        <h4 *ngIf="PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL != video.sectionTypeEnumValue">{{video.newValue| translate}}</h4>
        <a *ngIf="PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL == video.sectionTypeEnumValue"
        [href]="video.newValue"
        ><h4>{{"UpdateVideosJobZero.thumbnailURL"| translate}}</h4></a>
      </div>

    </div>
  </section>
  <section [class]="classPrefix('Pod1')">
    <job-status-zero [props]="props.jobInfo"></job-status-zero>
  </section>
</div>
