import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { SocketioClientResponseModel } from "../socketio/models/socketio-client-resp-model"

export let respondToFileTransferRequestLoad = (uiBody:RespondToFileTransferRequestUIRequestBody): Observable<RespondToFileTransferRequestAPIRequestBody>=>{



  let apiBody = new RespondToFileTransferRequestAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let respondToFileTransferRequestSuccess = (apiBody:RespondToFileTransferRequestAPIResponseBody):RespondToFileTransferRequestUIResponseBody=>{
  let uiBody = new RespondToFileTransferRequestUIResponseBody(transformObjectKeys(
    apiBody,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class RespondToFileTransferRequestUIRequestBody {
  constructor(props:Partial<RespondToFileTransferRequestUIRequestBody>={}){}
  accessToken:string
  senderEmail:string
  // TODO should this not be the receipient peer id?
  senderPeerId:string
  senderSid:string
  jobId:string
}

@WMLConstructorDecorator
export class RespondToFileTransferRequestAPIRequestBody {
  constructor(props:Partial<RespondToFileTransferRequestAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<RespondToFileTransferRequestUIRequestBody>
}

@WMLConstructorDecorator
export class RespondToFileTransferRequestUIResponseBody extends SocketioClientResponseModel<{senderPeerId:string,jobId:string,senderEmail:string}> {
  constructor(props:Partial<RespondToFileTransferRequestUIResponseBody>={}){super()}

}

@WMLConstructorDecorator
export class RespondToFileTransferRequestAPIResponseBody {
  constructor(props:Partial<RespondToFileTransferRequestAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<RespondToFileTransferRequestUIResponseBody>
}

