import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let addCardToUserLoad = (uiBody:AddCardToUserUIRequestBody): Observable<AddCardToUserAPIRequestBody>=>{

  let apiBody = new AddCardToUserAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let addCardToUserSuccess = (apiBody:AddCardToUserAPIResponseBody):AddCardToUserUIResponseBody=>{
  let uiBody = new AddCardToUserUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class AddCardToUserUIRequestBody {
  constructor(props:Partial<AddCardToUserUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  sessionId:string
  accessToken:string
}

export class AddCardToUserAPIRequestBody {
  constructor(props:Partial<AddCardToUserAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<AddCardToUserUIRequestBody>
}

export class AddCardToUserUIResponseBody {
  constructor(props:Partial<AddCardToUserUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class AddCardToUserAPIResponseBody {
  constructor(props:Partial<AddCardToUserAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<AddCardToUserUIResponseBody>
}

