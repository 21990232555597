export function deepCopyInclude(keysArray: string[], oldObject: Record<string, any>): Record<string, any> {
  const newObject: Record<string, any> = {};

  for (const key of keysArray) {
    if (key in oldObject) {
      newObject[key] = oldObject[key];
    }
  }

  return newObject;
}

export function deepCopyExclude(keysArray: string[], oldObject: Record<string, any>): Record<string, any> {
  const filteredObject: Record<string, any> = {};

  for (const key in oldObject) {
    if (!keysArray.includes(key)) {
      filteredObject[key] = oldObject[key];
    }
  }

  return filteredObject;
}




