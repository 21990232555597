import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import {  concatMap, iif, map, Observable, of, tap } from 'rxjs';
import { WMLPanelZeroItemProps, WMLPanelZeroProps } from '@windmillcode/angular-wml-panel';
import { WMLCustomComponent } from '@windmillcode/wml-components-base';
import { JobsZeroComponent, JobsZeroProps } from '@shared/components/jobs-zero/jobs-zero.component';
import {  WMLDataSource, WMLDataSourceUpdateSourcesObj } from '@core/utility/data-source-utils';


import { ListJobsAPIResponseBody, ListJobsUIRequestBody,listJobsLoad, listJobsSuccess } from './listJobs';

import { RunJobsUIRequestBody, RunJobsUIResponseBody,runJobsLoad } from './runJobs';
import { SocketioService } from '../socketio/socketio.service';


import { UpdateJobsAPIResponseBody, UpdateJobsUIRequestBody, UpdateJobsUIResponseBody,updateJobsLoad, updateJobsSuccess } from './updateJobs';
import { AccountsService } from '../accounts/accounts.service';
import { generateUUID } from '@core/utility/string-utils';


import { RequestFileTransferUIRequestBody,requestFileTransferLoad } from './requestFileTransfer';

import { RespondToFileTransferRequestUIRequestBody, RespondToFileTransferRequestUIResponseBody,respondToFileTransferRequestLoad } from './respondToFileTransferRequest';
import { NEED_MORE_CREDITS } from '@core/utility/constants';
import { SpecificService } from '@core/specific/specific.service';

@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public socketioService:SocketioService,
    public accountsService:AccountsService,
    public specificService:SpecificService
  ) { }

  jobPanelItem = (()=>{
    let panelItem = new WMLPanelZeroItemProps({
      custom:new WMLCustomComponent({
        cpnt:JobsZeroComponent
      })
    })
    panelItem.custom.props =  new JobsZeroProps({
      close:panelItem.close,
    })

    return panelItem
  })()
  jobPanel = new WMLPanelZeroProps({
    items:[this.jobPanelItem]
  })
  jobsList = new WMLDataSource()

  getIdForNewItemInListJobsDataSource = ()=>{
    let id = generateUUID("")
    while (this.listJobsDataSource.currentSource.data.find((job)=>job.id === id.toString())) {
      id =  generateUUID("")
    }
    return id.toString()
  }

  _listJobs = (uiBody = new ListJobsUIRequestBody())=>{
    return iif(
      ()=>ENV.jobsService.listJobs.automate,
      of(new ListJobsAPIResponseBody()),
      listJobsLoad(uiBody,this.accountsService.currentUser.accessToken)
        .pipe(
          concatMap((apiBody)=>{

            return this.http
            .post(ENV.jobsService.listJobs.url(),apiBody)
          })
        ) as Observable<ListJobsAPIResponseBody>
      )
    }

  listJobsDataSource = new WMLDataSource({
    getFromSink: this._listJobs,
    transformationPredicate:listJobsSuccess,
    webStorageObj:{
      key:"JobsServiceListJobsDataSource",
      isSyncedWithExternal:false
    }
  })

  runJobs = (uiBody = new RunJobsUIRequestBody(),socketRespPredicate:Function=()=>{})=>{
    let {currentUser} = this.accountsService
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken,
      googleDriveApiAccessToken: currentUser.googleDriveApiAccessToken,
      googleDriveApiRefreshToken: currentUser.googleDriveApiRefreshToken,
      youtubeDataApiAccessToken:currentUser.youtubeDataApiAccessToken,
      youtubeDataApiRefreshToken:currentUser.youtubeDataApiRefreshToken,
    })
    return iif(
      ()=>ENV.jobsService.runJobs.automate,
      of(new RunJobsUIResponseBody()),
      runJobsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http.post(ENV.jobsService.runJobs.url(),apiBody)
          }),
          tap({
            error:(err)=> {
              if(err.error.description === NEED_MORE_CREDITS){
                this.specificService.purchaseMoreCredits()
              }
            },
          })
        )
      )
    }

  updateJobs = (uiBody = new UpdateJobsUIRequestBody(),updateJobIds=false,overwriteMainId=true)=>{
    let {currentUser} = this.accountsService
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken,
    })
    return iif(
      ()=>ENV.jobsService.updateJobs.automate,
      of(new UpdateJobsUIResponseBody()),
      updateJobsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.jobsService.updateJobs.url(),apiBody)
              .pipe(
                map(updateJobsSuccess),
                concatMap((res)=>{
                  if(!updateJobIds){
                    return of(res)
                  }
                  if(res){
                    return this.updateJobIds(res.updatedJobIds,overwriteMainId);
                  }
                  else{
                    return of(null)
                  }
                }),
              )
          })
        )
      )
    }

    updateJobIds=(updatedJobIds: UpdateJobsAPIResponseBody["data"]["updated_job_ids"],overwriteMainId=true)=> {
      let updateInfo = updatedJobIds
      .filter((info) => Boolean(info.server))
      .map((info) => {
        return new WMLDataSourceUpdateSourcesObj({
          row: {
            [overwriteMainId ?"id" :"server_id"]: info.server
          },
          id: info.client,
          configurations: [{
            key: "[[],[]]",
            action: "update"
          }]
        });
      });
      return this.listJobsDataSource.updateSources(updateInfo);
    }


  requestFileTransfer = (uiBody = new RequestFileTransferUIRequestBody())=>{
    let {currentUser} = this.accountsService
    uiBody.accessToken = currentUser.accessToken
    return iif(
      ()=>ENV.jobsService.requestFileTransfer.automate,
      of(null),
      requestFileTransferLoad(uiBody)
        .pipe(
          tap((apiBody)=>{
            this.socketioService.client.emit("file_transfer_request",apiBody)
          })
        )
      )
    }

  respondToFileTransferRequest = (uiBody = new RespondToFileTransferRequestUIRequestBody())=>{
    let {currentUser} = this.accountsService
    uiBody.accessToken = currentUser.accessToken
    return iif(
      ()=>ENV.jobsService.respondToFileTransferRequest.automate,
      of(new RespondToFileTransferRequestUIResponseBody()),
      respondToFileTransferRequestLoad(uiBody)
        .pipe(
          tap((apiBody)=>{
            this.socketioService.client.emit("respond_to_file_transfer_request",apiBody)
          })
        )
      )
    }

}
