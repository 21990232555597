import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let mapSocketIOSessionIdToUserLoad = (uiBody:MapSocketIOSessionIdToUserUIRequestBody): Observable<MapSocketIOSessionIdToUserAPIRequestBody>=>{



  let apiBody = new MapSocketIOSessionIdToUserAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let mapSocketIOSessionIdToUserSuccess = (apiBody:MapSocketIOSessionIdToUserAPIResponseBody):MapSocketIOSessionIdToUserUIResponseBody=>{
  let uiBody = new MapSocketIOSessionIdToUserUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class MapSocketIOSessionIdToUserUIRequestBody {
  constructor(props:Partial<MapSocketIOSessionIdToUserUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  socketIoSessionId:string
  accessToken:string
}

export class MapSocketIOSessionIdToUserAPIRequestBody {
  constructor(props:Partial<MapSocketIOSessionIdToUserAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<MapSocketIOSessionIdToUserUIRequestBody>
}

export class MapSocketIOSessionIdToUserUIResponseBody {
  constructor(props:Partial<MapSocketIOSessionIdToUserUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class MapSocketIOSessionIdToUserAPIResponseBody {
  constructor(props:Partial<MapSocketIOSessionIdToUserAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<MapSocketIOSessionIdToUserUIResponseBody>
}

