// angular
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import {
  BaseServiceSubmitFormProps,
  BaseService,
} from '@core/base/base.service';

// rxjs
import { Subject, defer, of } from 'rxjs';
import { catchError, concatMap, takeUntil, tap } from 'rxjs/operators';

// wml-components
import {
  generateClassPrefix,
  generateIdPrefix,
} from '@windmillcode/wml-components-base';

// misc

import { ENV } from '@env/environment';

import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';





import { FormsService } from '@shared/services/forms/forms.service';
import { SpecificService } from '@core/specific/specific.service';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { MultiInputOneFieldCreatorProps, MultiInputOneProps } from '../multi-input-one/multi-input-one.component';
import { PlatformsService } from '@shared/services/platforms/platforms.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AddVideosUIRequestBody } from '@shared/services/platforms/addVideos';
import { AccountsService } from '@shared/services/accounts/accounts.service';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';
import localforage from 'localforage';
import enTranslations from "src/assets/i18n/en.json";

@Component({
  selector: 'add-new-video-one',
  templateUrl: './add-new-video-one.component.html',
  styleUrls: ['./add-new-video-one.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewVideoOneComponent {
  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
    public specificService: SpecificService,
    public platformsService:PlatformsService,
    public accountsService:AccountsService
  ) {}

  classPrefix = generateClassPrefix('AddNewVideoOne');
  idPrefix = generateIdPrefix(ENV.idPrefix.addNewVideoOne);
  @Input('props') props: AddNewVideoOneProps = new AddNewVideoOneProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  enTranslations = enTranslations

  listenForUpdate = () => {
    return this.props.updateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new AddNewVideoOneProps({
          ...this.props,
          ...(res ?? this.props),
        });
        this.cdref.detectChanges();
      }),
    );
  };

  mainForm = new WMLFormZeroProps({});
  mainFormGroup = this.formsService.addNewVideoOne.mainForm;

  createMainFormField = (props:Partial<{createWMLFieldFn:any,errorMsgKeyArray:any,fieldCustomProps:any,formControlName: keyof  FormsService["addNewVideoOne"]["mainForm"]["value"]}>) => {
    let {
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
    } = props;

    let field = this.baseService.createWMLField({
      i18nPrefix: 'AddNewVideoOne.mainForm',
      idPrefixFn: this.idPrefix,
      fieldParentForm: this.mainFormGroup,
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
    });

    return field;
  };


  mainFormClickSubmitBtn = () => {
    let  {accessToken,youtubeDataApiAccessToken,youtubeDataApiRefreshToken,googleDriveApiAccessToken,googleDriveApiRefreshToken} = this.accountsService.currentUser
    this.baseService.submitForm(
      new BaseServiceSubmitFormProps({
        rootFormGroup: this.mainFormGroup,
        openOverlayLoading:false,
        cdref: this.cdref,
        validFormPredicateType:"custom",
        validFormPredicateTypeCustom:()=>{
          this.baseService.createWMLNote("AddNewVideoOne.WMLNotifyOne.addVideoJob")
          this.baseService.closePopup()
          if(!this.accountsService.hasVideoAndStorageProviderTokens()){
            this.specificService.reconnectToAPlatform()
            return of(null)
          }
          return defer(async()=>{
            await localforage.removeItem(ENV.localForage.UserVideoData)
          })
          .pipe(
            concatMap(()=>{
              return this.platformsService.addVideos(new AddVideosUIRequestBody({
                downloadUrls:this.mainFormGroup.value.downloadUrls,
                accessToken,youtubeDataApiAccessToken,youtubeDataApiRefreshToken,googleDriveApiAccessToken,googleDriveApiRefreshToken
              }))
            }),
            catchError((res)=>{
              this.baseService.createWMLNote("AddNewVideoOne.WMLNotifyOne.addVideoJobError",WMLNotifyOneBarType.Error)
              return of(null)
            })
          )
        }
      }),
    );
  };

  createDownloadURLsField = () => {
    let field = this.createMainFormField({
      formControlName: "downloadUrls",
      errorMsgKeyArray: [],
      createWMLFieldFn: this.specificService.createMultiInputField,
      fieldCustomProps:new MultiInputOneProps({
        id:this.idPrefix("downloadUrlsField"),
        limitText:"AddNewVideoOne.WMLNotifyOne.addVideoLimit",
        getFieldCreatorPredicate: (index0)=>{
          return new MultiInputOneFieldCreatorProps({
            modifyFieldPredicate:(field)=>{
              field.deleteRequiredLabelPart()
              field.updateLabel("AddNewVideoOne.mainForm.downloadURLsField.label")
            }
          })
        }
      }),
    });

    field.deleteLabel();

    return field;
  };

  initMainForm = () => {
    let fields = [this.createDownloadURLsField()];
    this.mainForm.updateFields(fields);
    this.listenForUserSubmitURLs(fields[0]).subscribe()
  };

  listenForUserSubmitURLs = (downloadField:WMLFieldZeroProps) => {
    let multiInputProps:MultiInputOneProps = downloadField.getFieldProps()
    return multiInputProps.submitEvent
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res:FormGroup)=>{
        let resultValue = res.controls.value.value
        let downloadURLsField = this.mainFormGroup.controls.downloadUrls as FormArray
        downloadURLsField.clear()
        resultValue.forEach((item)=>{
          downloadURLsField.push(new FormControl(item))
        })
        this.mainFormClickSubmitBtn()
      })
    )

  }

  ngOnInit(): void {

    this.initMainForm()
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class AddNewVideoOneProps {
  constructor(props: Partial<AddNewVideoOneProps> = {}) {
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  updateSubj = new Subject<AddNewVideoOneProps>();
}
