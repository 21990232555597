import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"
import { ListUsersVideoDataUIResponseBody } from "./listUsersVideoData"
import { ENV } from "@env/environment"

export let addVideosLoad = (uiBody:AddVideosUIRequestBody): Observable<AddVideosAPIRequestBody>=>{


  let apiBody = new AddVideosAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let addVideosSuccess = (apiBody:AddVideosAPIResponseBody):AddVideosUIResponseBody=>{
  let uiBody = new AddVideosUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class AddVideosUIRequestBody {
  constructor(props:Partial<AddVideosUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  downloadUrls: {value:string}[];
  accessToken:string
  youtubeDataApiAccessToken:string
  youtubeDataApiRefreshToken:string
  googleDriveApiAccessToken:string
  googleDriveApiRefreshToken:string
  appPlatformTypeEnumValue =ENV.platformType
}

export class AddVideosAPIRequestBody {
  constructor(props:Partial<AddVideosAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<AddVideosUIRequestBody>
}

export class AddVideosUIResponseBody extends ListUsersVideoDataUIResponseBody{
  constructor(props:Partial<AddVideosUIResponseBody>={}){
    super(props)
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class AddVideosAPIResponseBody {
  constructor(props:Partial<AddVideosAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<AddVideosUIResponseBody>
}

