import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let deleteUserDataLoad = (uiBody:DeleteUserDataUIRequestBody): Observable<DeleteUserDataAPIRequestBody>=>{



  let apiBody = new DeleteUserDataAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let deleteUserDataSuccess = (apiBody:DeleteUserDataAPIResponseBody):DeleteUserDataUIResponseBody=>{
  let uiBody = new DeleteUserDataUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class DeleteUserDataUIRequestBody {
  constructor(props:Partial<DeleteUserDataUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  accessToken:string
  youtubeDataApiAccessToken?:string
  googleDriveApiAccessToken?:string

}

export class DeleteUserDataAPIRequestBody {
  constructor(props:Partial<DeleteUserDataAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<DeleteUserDataUIRequestBody>
}

export class DeleteUserDataUIResponseBody {
  constructor(props:Partial<DeleteUserDataUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class DeleteUserDataAPIResponseBody {
  constructor(props:Partial<DeleteUserDataAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<DeleteUserDataUIResponseBody>
}

