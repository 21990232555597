import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLInfiniteDropdownZeroOption } from "@windmillcode/angular-wml-infinite-dropdown"
import { Observable, of, take } from "rxjs"

export let updateAdressesLoad = (uiBody:UpdateAdressesUIRequestBody): Observable<UpdateAdressesAPIRequestBody>=>{

  let apiBody = new UpdateAdressesAPIRequestBody({data:{
    access_token:uiBody.accessToken,
    addresses:uiBody.addresses.map((val)=>{
      return {
        address1:val.streetAdr,
        address2:val.secondaryAdr,
        city:val.city,
        countryCode:val.country?.value,
        provinceCode:val.state?.value,
        zip:val.zipcode
      }
    })
  }})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let updateAdressesSuccess = (apiBody:UpdateAdressesAPIResponseBody):UpdateAdressesUIResponseBody=>{
  let uiBody = new UpdateAdressesUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class UpdateAdressesUIRequestBody {
  constructor(props:Partial<UpdateAdressesUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  accessToken:string
  addresses:Array<{
    streetAdr:string
    secondaryAdr:string
    city:string
    country:WMLInfiniteDropdownZeroOption
    state:WMLInfiniteDropdownZeroOption
    zipcode:string
  }>


}

export class UpdateAdressesAPIRequestBody {
  constructor(props:Partial<UpdateAdressesAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:{
    access_token:string
    addresses:Array<{
      address1:string
      address2:string
      city:string
      countryCode:string
      provinceCode:string
      zip:string
    }>
  }
}

export class UpdateAdressesUIResponseBody {
  constructor(props:Partial<UpdateAdressesUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class UpdateAdressesAPIResponseBody {
  constructor(props:Partial<UpdateAdressesAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<UpdateAdressesUIResponseBody>
}

