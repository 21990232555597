import { traverseClassAndRemoveUpdateAutomation } from "@core/utility/env-utils"
import { DevEnv } from "./environment.dev"
import { deepCopyExclude } from "@core/utility/object-utils"


export let environment = {
  production: true
}
class PreviewEnv extends DevEnv  {

  constructor(){
    super()
    this.app.freeTrialEndsAfter = 5
    this.type = "PREVIEW"
    this.frontendDomain0 = "https://ui.preview.eneobia.com"
    this.backendServerName0 = "api-eneobia-preview.azurewebsites.net"
    // silenceAllConsoleLogs()
    traverseClassAndRemoveUpdateAutomation(this)
    this.mobile.android.googlePlayStore.storeUrl ="https://drive.google.com/uc?export=download&id=1WHnV63m9-NuV8iNwEblKduIdAEKTOwGW"
    this.mobile.ios.appStore.storeUrl ="https://testflight.apple.com/join/90o5Gjyf"
    this.firebase.storageImageURL ="https://firebasestorage.googleapis.com/v0/b/eneobia-preview.appspot.com/o/"

    this.oneSignal.appId = "dbca1a9c-9e8e-40fb-9078-3cbdef1dc2c3"
    this.oneSignal.safariWebId= "web.onesignal.auto.4cc30974-98f9-47ba-8e02-4635d2d477f2"
    this.firebase.config ={
      ...this.firebase.config,
      authDomain: "ui.preview.eneobia.com",
    }
    this.peerjs = {
      ...deepCopyExclude(["port"],this.peerjs),
      host:this.backendServerName0,
    }

  }
}


export let ENV =   new PreviewEnv()


