// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input, ViewChild, ViewContainerRef   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { WMLCustomComponent,  generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';
import { SampleCpntComponent, SampleCpntMeta } from '../sample-cpnt/sample-cpnt.component';
import { addCustomComponent } from '@windmillcode/angular-wml-components-base';


@Component({

  selector: 'card-one',
  templateUrl: './card-one.component.html',
  styleUrls: ['./card-one.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class CardOneComponent  {

  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService,
  ) {}

  classPrefix = generateClassPrefix('CardOne');
  idPrefix = generateIdPrefix(ENV.idPrefix.cardOne);
  @Input('props') props: CardOneProps = new CardOneProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  @ViewChild("customBody", {read:ViewContainerRef}) customBody!:ViewContainerRef;
  ngUnsub= new Subject<void>()



  ngAfterViewInit(): void {
    if(this.props.customBody){
      addCustomComponent(
        this.customBody,
        this.props.customBody.cpnt,
        this.props.customBody.props
      )
    }
    this.myId = this.props.id ?? this.myId;
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

}

export class CardOneProps {
  constructor(props:Partial<CardOneProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
  id:string
  items:WMLButtonOneProps[] = Array(5)
  .fill(null)
  .map((nullVal,index0)=>{
    return new WMLButtonOneProps({
      text:"Item "+index0,
    })
  })
  title:string
  titleBtn = new WMLButtonOneProps({
    isPresent:false
  })
  customBody= new WMLCustomComponent({
    cpnt:SampleCpntComponent,
    props:new SampleCpntMeta()
  })

  draggable=true
  dragStart:Function
  drop:Function
  dragOver:Function
}


