// angular
import { ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';



@Component({
  selector: 'video-viewer-zero',
  templateUrl: './video-viewer-zero.component.html',
  styleUrls: ['./video-viewer-zero.component.scss'],
  // changeDetection:ChangeDetectionStrategy.OnPush,


})
export class VideoViewerZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,

    public utilService:UtilityService,
    public baseService:BaseService,
    public sanitizer:DomSanitizer
  ) { }

  classPrefix = generateClassPrefix('VideoViewerZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.videoViewerZero)
  @Input('props') props: VideoViewerZeroProps = new VideoViewerZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  videoSrc:SafeUrl


  listenForUpdate = ()=>{
    return this.props.updateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{

        this.props = new VideoViewerZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })

        this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.props.videoSrc )
        this.cdref.detectChanges()
      })
    )
  }


  ngOnInit(): void {
    this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.props.videoSrc )
    this.listenForUpdate().subscribe()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class VideoViewerZeroProps {
  constructor(props:Partial<VideoViewerZeroProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }

  updateSubj = new Subject<VideoViewerZeroProps>()
  videoSrc:string
  type:"Youtube" |"Other" ="Youtube"

}


