import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { PlatformsVideoDataEditorZeroActionEnum, PlatformsVideoDataEditorZeroSectionEnum } from "./platforms.service"

export let loadUserVideoDataEditorSettingsLoad = (uiBody:LoadUserVideoDataEditorSettingsUIRequestBody): Observable<LoadUserVideoDataEditorSettingsAPIRequestBody>=>{



  let apiBody = new LoadUserVideoDataEditorSettingsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let loadUserVideoDataEditorSettingsSuccess = (apiBody:LoadUserVideoDataEditorSettingsAPIResponseBody):LoadUserVideoDataEditorSettingsUIResponseBody=>{
  let uiBody = new LoadUserVideoDataEditorSettingsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class LoadUserVideoDataEditorSettingsUIRequestBody {
  constructor(props:Partial<LoadUserVideoDataEditorSettingsUIRequestBody>={}){}

  accessToken:string =""
  googleDriveApiAccessToken:string = ""
  googleDriveApiRefreshToken:string = ""
  appPlatformTypeEnumValue = ENV.platformType
}

@WMLConstructorDecorator
export class LoadUserVideoDataEditorSettingsAPIRequestBody {
  constructor(props:Partial<LoadUserVideoDataEditorSettingsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<LoadUserVideoDataEditorSettingsUIRequestBody>
}

@WMLConstructorDecorator
export class LoadUserVideoDataEditorSettingsUIResponseBody<TT=string> {
  constructor(props:Partial<LoadUserVideoDataEditorSettingsUIResponseBody>={}){}
  actionTypeEnumValue? =PlatformsVideoDataEditorZeroActionEnum.PREPEND
  sectionTypeEnumValue? = PlatformsVideoDataEditorZeroSectionEnum.TITLE;

  insert:{
    insertLine:{val:number,max:number},
    insertChar:{val:number,max:number}
  }
  replace:{
    fromLine:{val:number,max:number},
    fromChar:{val:number,max:number},
    toLine:{val:number,max:number},
    toChar:{val:number,max:number}
  }
  // TODO should not be here refactor out
  videos:Array<string> = []
  content:{
    description:string,
    tags:Array<string>
    // TODO we dont have this here anymore find a different place to put it
    thumbnail:Array<TT>
    title:string
  }

}

@WMLConstructorDecorator
export class LoadUserVideoDataEditorSettingsAPIResponseBody {
  constructor(props:Partial<LoadUserVideoDataEditorSettingsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<LoadUserVideoDataEditorSettingsUIResponseBody>
}

