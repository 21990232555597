import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { Observable, of, take } from "rxjs"

export let listUsersVideoDataLoad = (uiBody:ListUsersVideoDataUIRequestBody): Observable<ListUsersVideoDataAPIRequestBody>=>{



  let apiBody = new ListUsersVideoDataAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listUsersVideoDataSuccess = (apiBody:ListUsersVideoDataAPIResponseBody):ListUsersVideoDataUIResponseBody=>{

  if(typeof apiBody.data === "string"){
    return apiBody.data
  }
  let uiBody = new ListUsersVideoDataUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class ListUsersVideoDataUIRequestBody {
  constructor(props:Partial<ListUsersVideoDataUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  accessToken:string
  youtubeDataApiAccessToken:string
  youtubeDataApiRefreshToken:string
  googleDriveApiAccessToken:string
  googleDriveApiRefreshToken:string
  appPlatformTypeEnumValue =ENV.platformType
}

export class ListUsersVideoDataAPIRequestBody {
  constructor(props:Partial<ListUsersVideoDataAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ListUsersVideoDataUIRequestBody>
}

export class ListUsersVideoDataUIResponseBody {
  constructor(props:Partial<ListUsersVideoDataUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  jobWasRun  = false
  platformsRespModel: Array<{
    title: string;
    iconUrl: string;
  }>;
  channelsRespModel: Array<{
    platformTitle: string;
    id: string;
    thumbnail: string;
    title: string;
  }>;
  playlistsRespModel: Array<{
    platformTitle: string;
    channelTitle: string;
    id: string;
    thumbnail: string;
    title: string;
  }>;
  videosRespModel: Array<{
    platformTitle: string;
    channelTitle: string;
    id: string;
    playlistTitle?: string;
    thumbnail: string;
    title: string;
    fileSize: number;
    description: string;
    tags: string[];
    isChosenForVideoDataEdit?:boolean
    order?:number
  }>;



}

export class ListUsersVideoDataAPIResponseBody {
  constructor(props:Partial<ListUsersVideoDataAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ListUsersVideoDataUIResponseBody>
}

