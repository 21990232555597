import { transformObjectKeys } from "@core/utility/common-utils"
import { AuthBase } from "@core/utility/specific-utils/classes-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let getCustomTokensLoad = (uiBody:GetCustomTokensUIRequestBody): Observable<GetCustomTokensAPIRequestBody>=>{



  let apiBody = new GetCustomTokensAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let getCustomTokensSuccess = (apiBody:GetCustomTokensAPIResponseBody):GetCustomTokensUIResponseBody=>{
  let uiBody = new GetCustomTokensUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class GetCustomTokensUIRequestBody extends AuthBase  {
  constructor(props:Partial<GetCustomTokensUIRequestBody>={}){super()}
}

@WMLConstructorDecorator
export class GetCustomTokensAPIRequestBody {
  constructor(props:Partial<GetCustomTokensAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<GetCustomTokensUIRequestBody>
}

@WMLConstructorDecorator
export class GetCustomTokensUIResponseBody {
  constructor(props:Partial<GetCustomTokensUIResponseBody>={}){}
  tokens :Array<string> = []
}

@WMLConstructorDecorator
export class GetCustomTokensAPIResponseBody {
  constructor(props:Partial<GetCustomTokensAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<GetCustomTokensUIResponseBody>
}

