import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
import { Subject } from 'rxjs';
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';
import { ENV } from '@env/environment';
import { JobsZeroCommonProps } from '../jobs-zero/jobs-zero';

@Component({
  selector: 'ai-shorts-job-zero',
  templateUrl: './ai-shorts-job-zero.component.html',
  styleUrls: ['./ai-shorts-job-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class AiShortsJobZeroComponent  {
  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }
  classPrefix = generateClassPrefix('AiShortsJobZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.aiShortsJobZero)
  @Input('props') props: AiShortsJobZeroProps = new AiShortsJobZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  ngOnInit(): void { }
  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }
}

export class AiShortsJobZeroProps extends JobsZeroCommonProps {
  constructor(props:Partial<AiShortsJobZeroProps>={}){
    super(props)
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
}
