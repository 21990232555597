import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { WMLValidatorFileExtensions, WMLValidatorFileSize } from '@core/utility/validators';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import {  VideoDataEditorZeroItemProps,  } from '@shared/components/video-data-editor-zero/video-data-editor-zero.component';
import { WMLOptionZeroItemProps } from '@windmillcode/angular-wml-options';
import {PlatformsVideoDataEditorZeroSectionEnum,PlatformsVideoDataEditorZeroActionEnum} from "@shared/services/platforms/platforms.service"
import { WMLFileUploadZeroItem } from '@windmillcode/angular-wml-file-manager';

 type lineCharReactiveFormControlType=  FormGroup<{val:FormControl<number>,max:FormControl<number>}>
@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor() {}

  billingZeroPage={
    creditsForm :new FormGroup({
    	download:new FormControl(0,[RxwebValidators.numeric()]),
    	backup:new FormControl(0,[RxwebValidators.numeric()]),
    	bulkEdit:new FormControl(0,[RxwebValidators.numeric()]),
    	fileTransfer:new FormControl(0,[RxwebValidators.numeric()]),
    	channel:new FormControl(0,[RxwebValidators.numeric()])
    })
  }

  friendsZeroPage={
    mainForm :new FormGroup({
    	friends:new FormArray([])
    })
  }

  transferRecipientsFormZero={
    mainForm :new FormGroup({
    	recipients:new FormArray([], [Validators.required,Validators.email])
    })
  }

  uploadZeroPage={
    mainForm :new FormGroup({
    	files:new FormArray([],[
        Validators.required,

      ])
    })
  }

  contentEditorControlZero={
    mainForm :new FormGroup({
    	type:new FormControl<PlatformsVideoDataEditorZeroSectionEnum>(null,[Validators.required]),
    	title:new FormControl("",[]),
    	tags:new FormArray([]),
    	description:new FormControl(
        "",[]
      ),
    	thumbnail:new FormArray<AbstractControl<WMLFileUploadZeroItem>>([],[

      ])
    },[
      (formGroup:FormGroup)=>{
        let g:typeof this.contentEditorControlZero.mainForm = formGroup as any
        if([null,undefined].includes(g.value.type)){
          return null
        }
        let target = ["title","tags","description","thumbnail"][g.value.type]
        let control = g.controls[target]

        let targetValue = control.value

        // if(Array.isArray(targetValue)){
        //   if(targetValue.length <= 0){
        //     return {required:true}
        //   }
        // }
        // else if(typeof targetValue ==="string"){
        //   if([null,undefined,""].includes(targetValue)){
        //     return {required:true}
        //   }
        // }

        g.controls.title.setErrors(null)
        g.controls.description.setErrors(null)
        g.controls.thumbnail.setErrors(null)
        g.controls.tags.setErrors(null)
        let targetControl
        let errors

        // TODO as we add more platforms ensure this gets dynamic
        if(g.value.type === PlatformsVideoDataEditorZeroSectionEnum.TITLE ){
          targetControl = g.controls.title
          errors = (RxwebValidators.maxLength({value:100}))(targetControl)
        }
        else if(g.value.type === PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION ){
          targetControl = g.controls.description
          errors = (RxwebValidators.maxLength({value:5000}))(targetControl)

        }
        else if(g.value.type === PlatformsVideoDataEditorZeroSectionEnum.TAGS ){
          targetControl = g.controls.tags

          const invalidItems = g.controls.tags.value.filter(item => item.length > 500);
          errors = invalidItems.length > 0 ? {"maxItemLength":true}:null

        }
        else if(g.value.type === PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL){
          targetControl = g.controls.thumbnail
          let fileExtensionErrors =WMLValidatorFileExtensions({
            ext:["jpg","jpeg","png"]
          })(targetControl)
          let fileSizeErrors = WMLValidatorFileSize({
            fileSizeRange:[
              {value:0,unit:"B"},
              {value:2,unit:"MB"}
            ]
          })(targetControl)
          let final = {
            ...(fileExtensionErrors??{}),
            ...(fileSizeErrors??{})
          }
          if(Object.keys(final).length >0){
            errors = final
          }
        }
        if(errors){
          targetControl.setErrors(errors)
        }


        return null
      }
    ])
  }

  videoDataEditorZero={
    mainForm :new FormGroup({
    	videos:new FormArray<AbstractControl<WMLOptionZeroItemProps<VideoDataEditorZeroItemProps,any>>>([],[
        Validators.required
      ]),
    	type:new FormArray<AbstractControl<WMLOptionZeroItemProps>>([],[Validators.required]),
    	action:new FormArray<AbstractControl<WMLOptionZeroItemProps>>([],[Validators.required]),
    	insert:new FormGroup({
        insertLine: new FormGroup({
          val: new FormControl(1),
          max: new FormControl(1)
        }),
        insertChar: new FormGroup({
            val: new FormControl(1),
            max: new FormControl(1)
        }),
      }),
      replace:new FormGroup({
        fromLine: new FormGroup({
            val: new FormControl(1),
            max: new FormControl(1)
        }),
        fromChar: new FormGroup({
            val: new FormControl(1),
            max: new FormControl(1)
        }),
        toLine: new FormGroup({
            val: new FormControl(1),
            max: new FormControl(1)
        }),
        toChar: new FormGroup({
            val: new FormControl(1),
            max: new FormControl(1)
        })
      }),
    	content:this.contentEditorControlZero.mainForm
    },
    [
      (formGroup)=>{
        let g:typeof this.videoDataEditorZero.mainForm = formGroup as any
        let type = g.value.type[0]?.value
        let actionControl = g.controls.action
        let actionType = actionControl.value[0]?.value
        let replaceControl = g.controls.replace

        replaceControl.setErrors(null)
        actionControl.setErrors(null)
        if(type === PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL && actionType !== PlatformsVideoDataEditorZeroActionEnum.OVERWRITE){
          actionControl.setErrors({compound:true})
        }

        if(actionType === PlatformsVideoDataEditorZeroActionEnum.REPLACE){
          let data = replaceControl.value
          let fromLineVal = data.fromLine.val;
          let fromLineMax = data.fromLine.max;
          let fromCharVal = data.fromChar.val;
          let fromCharMax = data.fromChar.max;
          let toLineVal = data.toLine.val;
          let toLineMax = data.toLine.max;
          let toCharVal = data.toChar.val;
          let toCharMax = data.toChar.max;
          let result

          // Ensure the 'val' does not exceed the 'max'
          if (fromLineVal > fromLineMax || toLineVal > toLineMax ||
              fromCharVal > fromCharMax || toCharVal > toCharMax) {
            result ={invalidCoordinates:true};
          }

          // Validate the line coordinates
          else if (fromLineVal > toLineVal) {
            result = {invalidCoordinates:true};
          }

          // If lines are the same, check the character positions
          else if (fromLineVal === toLineVal) {
            if (fromCharVal > toCharVal) {
              result = {invalidCoordinates:true};
            }
          }
          else{
            result= null;
          }
          replaceControl.setErrors(result)
          replaceControl.markAsDirty()
        }
        return null
      }
    ]
    )
  }

  addNewVideoOne = {
    mainForm: new FormGroup({
      downloadUrls: new FormArray([], Validators.required),
    }),
  };

  accountOverviewPage = {
    mainForm: new FormGroup({
      displayName: new FormControl(''),
      email: new FormControl({value:"",disabled:true},[Validators.email,Validators.required]),
      emailVerified:new FormControl(false),
      phoneNumber: new FormControl(''),
      startWithGuide:new FormControl(false)
    }),

    notifyForm: new FormGroup({
      emailMarketingConsent:new FormControl(false),
      smsMarketingConsent:new FormControl(false),
      incomingVideosNotifications:new FormControl(false),
      pushNotifications:new FormControl(false),
    }),

    changePassForm: new FormGroup({
      currentPass: new FormControl('', []),
      pass: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirmPass: new FormControl(
        '',
        [
          Validators.required,
          RxwebValidators.compare({
            fieldName: "pass",
          }),
        ],
      ),
    }),
  };

  signUpPage = {
    mainForm: new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      pass: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirmPass: new FormControl(
        '',
        [
          Validators.required,
          RxwebValidators.compare({
            fieldName: "pass",
          }),
        ],
      ),
    }),
  };

  signInPage = {
    mainForm: new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
      pass: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
    }),
  };

  forgotPassPage = {
    mainForm: new FormGroup({
      email: new FormControl('', [
        Validators.email,
        Validators.required,
      ]),
    }),
  };

  imageUploadZero = {
    mainForm: new FormGroup({
      avatarUrl: new FormArray([]),
    }),
  };

}
