// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseServiceSubmitFormProps, BaseService } from '@core/base/base.service';

// rxjs
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { VideoTileZeroProps } from '../video-tile-zero/video-tile-zero.component';
import { WMLOptionZeroItemProps, WMLOptionsZeroProps } from '@windmillcode/angular-wml-options';
import { WMLFormZeroProps } from '@windmillcode/angular-wml-form';
import { WMLButtonOneProps, WMLButtonPropsTypeEnum } from '@windmillcode/angular-wml-button';
import { FormsService } from '@shared/services/forms/forms.service';
import { WMLCustomComponent } from '@windmillcode/wml-components-base';
import { OptionZeroComponent, OptionZeroProps } from '../option-zero/option-zero.component';
import enTranslations from "src/assets/i18n/en.json";
import { FormControl, FormGroup } from '@angular/forms';
import { SpecificService } from '@core/specific/specific.service';
import { ContentEditorControlZeroProps } from '../content-editor-control-zero/content-editor-control-zero.component';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { InsertAtControlZeroProps } from '../insert-at-control-zero/insert-at-control-zero.component';
import { MultiInputOneFieldCreatorProps, MultiInputOneProps } from '../multi-input-one/multi-input-one.component';
import { TextEditor } from '@core/utility/string-utils';
import {PlatformsVideoDataEditorZeroSectionEnum,PlatformsVideoDataEditorZeroActionEnum} from "@shared/services/platforms/platforms.service"
import { LoadUserVideoDataEditorSettingsUIResponseBody } from '@shared/services/platforms/loadUserVideoDataEditorSettings';



@Component({
  selector: 'video-data-editor-zero',
  templateUrl: './video-data-editor-zero.component.html',
  styleUrls: ['./video-data-editor-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class VideoDataEditorZeroComponent  {

  constructor(

    public formsService:FormsService,
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService,
    public specificService:SpecificService
  ) { }

  classPrefix = generateClassPrefix('VideoDataEditorZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.videoDataEditorZero)
  @Input('props') props: VideoDataEditorZeroProps = new VideoDataEditorZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()

  listenForUpdate = ()=>{
    return this.props.updateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new VideoDataEditorZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }

  mainForm = new WMLFormZeroProps({
    fieldSections:[1,1,2]
  })
  mainFormGroup = this.formsService.videoDataEditorZero.mainForm
  createMainFormField = (props:Partial<{formControlName:keyof   FormsService["videoDataEditorZero"]["mainForm"]["value"],createWMLFieldFn,errorMsgKeyArray,fieldCustomProps}>)=>{
    let {formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomProps} = props
    let field =this.baseService.createWMLField({
      i18nPrefix:"VideoDataEditorZero.mainForm",
      idPrefixFn:this.idPrefix,
      fieldParentForm:this.mainFormGroup,
      formControlName,createWMLFieldFn,errorMsgKeyArray,fieldCustomProps
    })
    return field
  }

  mainFormSubmitBtn = new WMLButtonOneProps({
    id:this.idPrefix("mainFormSubmitBtn"),
    type:WMLButtonPropsTypeEnum.PRIMARY,
    text:"VideoDataEditorZero.submit",
    click:()=> this.mainFormClickSubmitBtn()
  })

  mainFormClickSubmitBtn = ()=>{
    let contentControl = this.mainFormGroup.controls.content
    this.baseService.validateAllFormFields(contentControl as FormGroup,true)


    this.baseService.submitForm(new BaseServiceSubmitFormProps({
      rootFormGroup:this.mainFormGroup,
      cdref:this.cdref,
      validFormPredicateType:"custom",
      openOverlayLoading:false,
      validFormPredicateTypeCustom:this.previewChanges

    }))
  }

  previewChanges =()=>{
    let value = this.mainFormGroup.getRawValue()
    this.props.previewChangeSubj.next(value)
    this.baseService.closePopup()
  }

  createVideosField =()=>{

    let field= this.createMainFormField({
      formControlName:"videos",
      errorMsgKeyArray:[],
      createWMLFieldFn:this.baseService.createOptionsFormField,
      fieldCustomProps:new WMLOptionsZeroProps({
        options:this.props.items.map((itemInfo)=>{
          let {item}=itemInfo
          return new WMLOptionZeroItemProps({
            text:item.videoTitle,
            value:itemInfo,
            isChosen:this.props.current.appId === item.appId,
            toggleClass:"OptionZeroPod0Input0Chosen",
            customCpnt:new WMLCustomComponent({
              cpnt:OptionZeroComponent,
              props:new OptionZeroProps()
            })
          })
        })
      })
    })
    field.deleteRequiredLabelPart();
    return field
  }

  createTypeField =()=>{

    let field= this.createMainFormField({
      formControlName:"type",
      errorMsgKeyArray:[],
      createWMLFieldFn:this.baseService.createOptionsFormField,
      fieldCustomProps:new WMLOptionsZeroProps({
        limit:1,
        options:enTranslations.VideoDataEditorZero.types.map((type,index0)=>{
          return new WMLOptionZeroItemProps({
            text:"VideoDataEditorZero.types."+index0,
            value:parseInt(Object.keys(PlatformsVideoDataEditorZeroSectionEnum)[index0]),
            isChosen:index0 === (this.props.settings?.sectionTypeEnumValue ?? this.props.type),
            toggleClass:"OptionZeroPod0Input0Chosen",
            customCpnt:new WMLCustomComponent({
              cpnt:OptionZeroComponent,
              props:new OptionZeroProps()
            })
          })
        })
      })
    })
    field.deleteRequiredLabelPart();
    return field
  }

  createActionField =(chosenEnums:PlatformsVideoDataEditorZeroActionEnum[]= [])=>{
    let actionValue= this.mainFormGroup.controls.action.value
    if(this.props.type === PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL){
      chosenEnums = [PlatformsVideoDataEditorZeroActionEnum.OVERWRITE]
    }

    let field= this.createMainFormField({
      formControlName:"action",
      errorMsgKeyArray:["required","compound"],
      createWMLFieldFn:this.baseService.createOptionsFormField,
      fieldCustomProps:new WMLOptionsZeroProps({
        limit:1,
        options:enTranslations.VideoDataEditorZero.action
        .map((type,index0)=>{
          let value = parseInt(Object.keys(PlatformsVideoDataEditorZeroActionEnum)[index0])
          let isChosen
          if(this.props.settings?.actionTypeEnumValue){
            isChosen =value === this.props.settings.actionTypeEnumValue
          }
          else{
            isChosen = Boolean(actionValue.find((val)=>val.value === value))
          }
          let optionItem =  new WMLOptionZeroItemProps({
            text:"VideoDataEditorZero.action."+index0,
            isChosen,
            value,
          })
          return optionItem
        })
        .filter((val,index0)=>{

          return chosenEnums.includes(index0) || chosenEnums.length === 0
        })
      })
    })
    delete  this.props.settings?.actionTypeEnumValue
    field.deleteRequiredLabelPart();
    return field
  }

  createInsertField=(info= new VideoDataEditorZeroInsertProps())=>{

    let field= this.createMainFormField({
      formControlName:"insert",
      errorMsgKeyArray:["required"],
      createWMLFieldFn:this.specificService.createMultiInputField,
      fieldCustomProps:new MultiInputOneProps({
        startingAmount:2,
        propAddFieldBtnIsPresent:false,
        propSubmitBtnIsPresent:false,

        getFieldCreatorPredicate:(index0)=>{
          return new MultiInputOneFieldCreatorProps({
            createWMLFieldFn:this.specificService.createInsertAtControlZeroField,
            fieldCustomProps:new InsertAtControlZeroProps({
              insertText:["VideoDataEditorZero.insertAtLine","VideoDataEditorZero.insertAtChar"][index0]
            }),
            removeBtn:new WMLButtonOneProps({
              isPresent:false
            }),
            reactiveFormControl:new FormGroup({
              value:new FormGroup({
                val:new FormControl(  info?.rangeInfo?.[index0]?.val || 1),
                max:new FormControl({ value: info?.rangeInfo?.[index0]?.max ||  1, disabled: true })
              }),
            }),
            propEntryNumberIsPresent:false,
            modifyFieldPredicate:(field)=>{
              field.deleteLabel()
            }
          })
        }
      })
    })
    field.deleteRequiredLabelPart();
    return field
  }

  createReplaceField=(info= new VideoDataEditorZeroInsertProps())=>{

    let field= this.createMainFormField({
      formControlName:"replace",
      errorMsgKeyArray:["required","invalidCoordinates"],
      createWMLFieldFn:this.specificService.createMultiInputField,
      fieldCustomProps:new MultiInputOneProps({
        startingAmount:4,
        propAddFieldBtnIsPresent:false,
        propSubmitBtnIsPresent:false,

        getFieldCreatorPredicate:(index0)=>{
          return new MultiInputOneFieldCreatorProps({
            createWMLFieldFn:this.specificService.createInsertAtControlZeroField,
            fieldCustomProps:new InsertAtControlZeroProps({
              insertText:"VideoDataEditorZero.replaceLabelText."+index0
            }),
            removeBtn:new WMLButtonOneProps({
              isPresent:false
            }),
            reactiveFormControl:new FormGroup({
              value:new FormGroup({
                val:new FormControl(  info?.rangeInfo?.[index0]?.val || 1),
                max:new FormControl({ value: info?.rangeInfo?.[index0]?.max ||  1, disabled: true })
              }),
            }),
            propEntryNumberIsPresent:false,
            modifyFieldPredicate:(field)=>{
              field.deleteLabel()
            }
          })
        }
      })
    })
    field.deleteRequiredLabelPart();
    return field
  }

  listenForInsertFieldChangesSub:Subscription
  listenForInsertFieldChanges=(field:WMLFieldZeroProps<any,MultiInputOneProps>)=>{
    let multiInputFormGroup = field.getFieldProps().internalMainFormGroup

    return multiInputFormGroup?.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      startWith(multiInputFormGroup.value),
      debounceTime(ENV.app.keyBoardDebounceTimes[0]),
      map(()=>{
        let result = multiInputFormGroup.getRawValue()
        let targetField = this.mainFormGroup.controls.insert
        let prevResult = targetField.getRawValue()

        let targetRange =result.value.map((val)=>val.value)
        let [line,char] = targetRange.map(line => this.ensureStartAtOne(line.val, line.max));
        let patchValue = result.value.map((item,index0)=>{
          return {
            val:[line,char][index0],
            max:item.value.max
          }
        })
        targetField.patchValue({
          insertLine:patchValue[0],
          insertChar:patchValue[1]
        })

        if(targetRange[0].val === prevResult.insertLine.val && targetRange[1].val === char){
          return
        }
        if(targetRange.every((lineNo)=>Number.isInteger(lineNo.val) )){

          let type = this.mainFormGroup.controls.type.value[0]?.value;

          let target =[{
            line,
            char,
            largestLineAtLine:line,
          }]
          this.updateMultiInputs(new VideoDataEditorZeroUpdateMultiInputsProps({
            [PlatformsVideoDataEditorZeroSectionEnum.TITLE]:{
              titleTarget:target,
            },
            [PlatformsVideoDataEditorZeroSectionEnum.TAGS]:{
              tags:target,
            },
            [PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION]:{
              descTarget:target,
            }
          }[type]));
        }

      })
    )

  }

  listenForReplaceFieldChangesSub:Subscription
  listenForReplaceFieldChanges=(field:WMLFieldZeroProps<any,MultiInputOneProps>)=>{
    let multiInputFormGroup = field.getFieldProps().internalMainFormGroup

    return multiInputFormGroup?.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      startWith(multiInputFormGroup.value),
      debounceTime(ENV.app.keyBoardDebounceTimes[0]),
      tap(()=>{
        let result = multiInputFormGroup.getRawValue()
        let targetField = this.mainFormGroup.controls.replace
        let prevResult = targetField.getRawValue()


        let targetRange =result.value.map((val)=>val.value)
        let [fromLine, fromChar, toLine, toChar] = targetRange.map(line => this.ensureStartAtOne(line.val, line.max));
        let patchValue = result.value.map((item,index0)=>{
          return {
            val:[fromLine, fromChar, toLine, toChar][index0],
            max:item.value.max
          }
        })

        targetField.patchValue({
          fromLine:patchValue[0],
          fromChar:patchValue[1],
          toLine:patchValue[2],
          toChar:patchValue[3]
        });


        if(targetRange[0].val === prevResult.fromLine.val && targetRange[2].val === prevResult.toLine.val && targetRange[1].val === fromChar && targetRange[3].val === toChar){
          return
        }
        if(targetRange.every((lineNo)=>Number.isInteger(lineNo.val) )){

          let type = this.mainFormGroup.controls.type.value[0]?.value;


          let target =[
            {
              line: fromLine,
              char: fromChar,
              largestLineAtLine:fromLine,
            },
            {
              line: toLine,
              char: toChar,
              largestLineAtLine:toLine,
            }
          ]

          this.updateMultiInputs(new VideoDataEditorZeroUpdateMultiInputsProps({
            [PlatformsVideoDataEditorZeroSectionEnum.TITLE]:{
              titleTarget:target,
            },
            [PlatformsVideoDataEditorZeroSectionEnum.TAGS]:{
              tags:target,
            },
            [PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION]:{
              descTarget:target,
            }
          }[type]));
        }


      })
    )

  }

  ensureStartAtOne= (value, max)=> {
    return value > max || value <= 0 || !Number.isInteger(value)  ? 1 : value;
  }

  createContentField =()=>{
    let contentControl = this.mainFormGroup.controls.content
    let title = this.props.settings?.content?.title ??contentControl.value.title
    let description = this.props.settings?.content?.description ??contentControl.value.description
    let tags =  this.props.settings?.content?.tags ??this.props.current.tags
    contentControl.patchValue({
      type: this.props.type,
      title:[null,undefined,""].includes(title) ? this.props.current.videoTitle : title,
      description: [null,undefined,""].includes(description) ? this.props.current.description : description,
    })
    if(contentControl.value.tags.length ===0){
      let tagsControl = contentControl.controls.tags
      tagsControl.clear()
      tags.map(tag => tagsControl.push(new FormControl(tag)))
    }


    let field= this.createMainFormField({
      formControlName:"content",
      errorMsgKeyArray:["required"],
      createWMLFieldFn:this.specificService.createContentEditorControlZeroField,

    })
    field.deleteRequiredLabelPart();
    return field
  }

  listenForVideoChange =()=>{
    let formArray = this.mainFormGroup.controls.videos
    return formArray.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      distinctUntilChanged(),
      tap(()=>{
        this.updateMultiInputs();
      })
    )

  }

  listenForActionChange = ()=>{
    let formArray = this.mainFormGroup.controls.action
    return formArray.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      startWith(this.mainFormGroup.controls.action.value),
      filter((res)=>res.length !== 0),
      distinctUntilChanged((prev,current)=>prev[0].value === current[0].value ),
      tap((res)=>{
         this.updateMultiInputs();
      })
    )

  }


  // its like this  because and because the people at the angular team decided somehow that ReactiveForms need to be stateuful
  listenForTypeChange=()=>{
    let formArray = this.mainFormGroup.controls.type
    return formArray.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      filter((res)=>res.length !== 0),
      distinctUntilChanged((prev,current)=>{
        return prev[0].value === current[0].value
      }),
      tap((res)=>{
        let type = this.props.type = res[0].value
        let fields = [...this.mainForm.fields]
        if(type === PlatformsVideoDataEditorZeroSectionEnum.THUMBNAIL){
          fields.splice(2,fields.length === 5 ?  2:1,
            this.createActionField()
          )
          this.mainForm.fieldSections = [1,1,2]
          this.mainForm.updateFields(fields)
          this.cdref.detectChanges()
        }
        else{

          fields.splice(2, 1,
            this.createActionField()
          );
          this.updateMultiInputs(new VideoDataEditorZeroUpdateMultiInputsProps({fields}));
        }

        let contentField = this.mainForm.fields[fields.length === 5 ?4 :3] as WMLFieldZeroProps<any,ContentEditorControlZeroProps>
        let props =contentField.getFieldProps()
        this.mainFormGroup.controls.content.patchValue({
          type
        })
        props.updateSubj.next({})
        this.cdref.detectChanges()


      })
    )

  }

  initMainForm = ()=>{

    let fields = [
      this.createVideosField(),
      this.createTypeField(),
      this.createActionField(),
      this.createContentField(),
    ]
    this.mainForm.updateFields(fields)

  }

  updateMultiInputs =(props = new VideoDataEditorZeroUpdateMultiInputsProps())=> {

    let {
      fields,
      titleTarget,
      descTarget,tagsTarget
    } = props
    fields ??= [...this.mainForm.fields];
    let insertField, replaceField;
    let videos = this.mainFormGroup.controls.videos.value.map((item) => item.value);
    let actionType = this.mainFormGroup.controls.action.value[0]?.value;
    let type = this.mainFormGroup.controls.type.value[0]?.value;
    if(actionType === PlatformsVideoDataEditorZeroActionEnum.REPLACE ){
      if(titleTarget.length===1){
        // @ts-ignore
        titleTarget.push({
          line:1,
          char:1,
          largestLineAtLine:1
        })
      }
      if(descTarget.length===1){
        // @ts-ignore
        descTarget.push({
          line:1,
          char:1,
          largestLineAtLine:1
        })
      }
      if(tagsTarget.length === 1){
        // @ts-ignore
        tagsTarget.push({
          line:1,
          char:1,
          largestLineAtLine:1
        })
      }
    }
    let title = {
      largestRows: videos.reduce((acc, x) => { return Math.max(acc, x.textEditorInfo.title.getTextDetails.totalLines); }, 0),
      largestLineAtRow:titleTarget.map((target)=>{
        let result = videos.reduce((acc,x,i)=>{return Math.max(
          acc,
          (x.textEditorInfo.title.getTextDetails.lines[target.largestLineAtLine-1]?.charCount??0)
        )},0)
        return result === 0 ? 1: result
      })
    };

    let desc = {
      largestRows: videos.reduce((acc, x) => { return Math.max(acc, x.textEditorInfo.description.getTextDetails.totalLines); }, 0),
      largestLineAtRow:descTarget.map((target)=>{
        let result = videos.reduce((acc,x,i)=>{return Math.max(
          acc,
          (x.textEditorInfo.description.getTextDetails.lines[target.largestLineAtLine-1]?.charCount??0)
        )},0)
        return result === 0 ? 1: result
      })
    };

    let tags = {
      largestLineAtRow :tagsTarget.map((target)=>{
        let result =  videos.reduce((acc,x)=>{
          return Math.max(acc,x.item.tags.length)
        },0)
        return result === 0 ? 1: result
      })
    }

    if(actionType === PlatformsVideoDataEditorZeroActionEnum.INSERT){
      insertField = this.updateMultiInputsGetNewField(
        new VideoDataEditorZeroUpdateMultiInputsGetNewFieldProps({type,actionType, multiInputsProps:props, title, tags,  desc,predicate:this.createInsertField
      }));
      fields.splice(3, fields.length !== 5 ? 0 : 1, insertField);
    }
    else if (actionType === PlatformsVideoDataEditorZeroActionEnum.REPLACE){
      replaceField = this.updateMultiInputsGetNewField(
        new VideoDataEditorZeroUpdateMultiInputsGetNewFieldProps({type,actionType, multiInputsProps:props, title, tags,  desc,predicate:this.createReplaceField
      }));
      fields.splice(3, fields.length !== 5 ? 0 : 1, replaceField);
    }
    else{
      fields.splice(3, fields.length === 5 ? 1 : 0);
    }
    this.mainForm.fieldSections = [1,1,fields.length === 5 ? 3:2]
    this.mainForm.updateFields(fields)
    this.cdref.detectChanges()
    this.toggleMultiInputListeners(insertField, replaceField);

  }

  updateMultiInputsGetNewField(props:VideoDataEditorZeroUpdateMultiInputsGetNewFieldProps) {
    let resultField
    let {type,actionType,multiInputsProps,title,tags,desc,predicate} = props
    let {titleTarget,descTarget,tagsTarget} = multiInputsProps
    let rangeInfo;

    titleTarget = titleTarget.map((target,index0)=>{
      target.largestLine = title.largestRows
      target.largestLineAtLine =  title.largestLineAtRow[index0]
      return target
    })
    descTarget = descTarget.map((target,index0)=>{
      target.largestLine = desc.largestRows
      target.largestLineAtLine =  desc.largestLineAtRow[index0]
      return target
    })
    tagsTarget = descTarget.map((target,index0)=>{
      target.largestLine = 1
      target.largestLineAtLine =  tags.largestLineAtRow[index0]
      return target
    })

    if (type === PlatformsVideoDataEditorZeroSectionEnum.TITLE) {

      rangeInfo = titleTarget.map((target,index0)=>{
        return [
          { val: target.line, max: title.largestRows },
          { val: target.char, max: title.largestLineAtRow[index0] }
        ]
      })
      .flat(1)

    } else if (type === PlatformsVideoDataEditorZeroSectionEnum.TAGS) {

      rangeInfo = tagsTarget.map((target,index0)=>{
        return [
          { val: target.line, max: 1 },
          { val: target.char, max: tags.largestLineAtRow[index0] }
        ]
      })
      .flat(1)
    } else if (type === PlatformsVideoDataEditorZeroSectionEnum.DESCRIPTION) {

      rangeInfo = descTarget.map((target,index0)=>{
        return [
          { val: target.line, max: desc.largestRows },
          { val: target.char, max: desc.largestLineAtRow[index0] }
        ]
      })
      .flat(1)
    }
    resultField = predicate({
      rangeInfo
    });

    return resultField;
  }

  toggleMultiInputListeners(insertField: WMLFieldZeroProps, replaceField: WMLFieldZeroProps) {
    if (insertField) {
      this.listenForInsertFieldChangesSub?.unsubscribe();
      this.listenForInsertFieldChangesSub = this.listenForInsertFieldChanges(insertField).subscribe();
    }
    if (replaceField) {
      this.listenForReplaceFieldChangesSub?.unsubscribe();
      this.listenForReplaceFieldChangesSub = this.listenForReplaceFieldChanges(replaceField).subscribe();
    }
    this.cdref.detectChanges()
  }

  ngOnInit(): void {

    this.initMainForm()
    this.listenForUpdate().subscribe()
  }

  ngAfterViewInit(){
    this.listenForVideoChange().subscribe()
    this.listenForTypeChange().subscribe()
    this.listenForActionChange().subscribe()


  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}





export class VideoDataEditorZeroProps {
  constructor(props:Partial<VideoDataEditorZeroProps &{propItems:VideoTileZeroProps[]}>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
    if(props.propItems){
      this.items = props.propItems.map((item)=>{
        return {
          item,
          textEditorInfo:{
            title:new TextEditor({
              text:item.videoTitle
            }),
            description:new TextEditor({
              text:item.description
            }),
          }
        }
      })
    }
  }
  type:PlatformsVideoDataEditorZeroSectionEnum
  previewChangeSubj= new Subject<FormsService["videoDataEditorZero"]["mainForm"]["value"]>()
  updateSubj = new Subject<VideoDataEditorZeroProps>()
  items:Array<VideoDataEditorZeroItemProps> = []
  current:VideoTileZeroProps
  settings?:LoadUserVideoDataEditorSettingsUIResponseBody
}


export class VideoDataEditorZeroItemProps {
  constructor(props: Partial<VideoDataEditorZeroItemProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  item:VideoTileZeroProps
  textEditorInfo:{
    title:TextEditor,
    description:TextEditor
  }
}

export class VideoDataEditorZeroInsertProps {
  constructor(props: Partial<VideoDataEditorZeroInsertProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  rangeInfo:Array<{
    val:number,
    max:number
  }> =[]
}

export class VideoDataEditorZeroUpdateMultiInputsProps {
  constructor(props: Partial<VideoDataEditorZeroUpdateMultiInputsProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  fields : Array<WMLFieldZeroProps>
  titleTarget= [{
    line:1,
    char:1,
    largestLine:1,
    largestLineAtLine:1
  }]
  tagsTarget=[{
    line:1,
    char:1,
    largestLine:1,
    largestLineAtLine:1
  }]

  descTarget= [{
    line:1,
    char:1,
    largestLine:1,
    largestLineAtLine:1
  }]

}


export class VideoDataEditorZeroUpdateMultiInputsGetNewFieldProps {
  constructor(props: Partial<VideoDataEditorZeroUpdateMultiInputsGetNewFieldProps> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  type: PlatformsVideoDataEditorZeroSectionEnum
  actionType:PlatformsVideoDataEditorZeroActionEnum
  multiInputsProps:VideoDataEditorZeroUpdateMultiInputsProps
  title: { largestRows: number; largestLineAtRow: number[]; }
  tags: { largestLineAtRow: number[]; }
  desc: { largestRows: number; largestLineAtRow: number[]; }
  predicate:Function
}
