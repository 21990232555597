import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import {  concatMap, iif, map, of, tap } from 'rxjs';

import { RequestYoutubeAuthorizationFromUserUIRequestBody,requestYoutubeAuthorizationFromUserLoad, requestYoutubeAuthorizationFromUserSuccess } from './requestYoutubeAuthorizationFromUser';
import { RevokeYoutubeAuthorizationForUserUIRequestBody,RevokeYoutubeAuthorizationForUserUIResponseBody,revokeYoutubeAuthorizationForUserLoad, revokeYoutubeAuthorizationForUserSuccess } from './revokeYoutubeAuthorizationForUser';

import { ListUsersVideoDataUIRequestBody, ListUsersVideoDataUIResponseBody,listUsersVideoDataLoad, listUsersVideoDataSuccess } from './listUsersVideoData';
import { AccountsService } from '../accounts/accounts.service';
import { SocketioService } from '../socketio/socketio.service';


import { AddVideosUIRequestBody, AddVideosUIResponseBody,addVideosLoad, addVideosSuccess } from './addVideos';

import { DeleteVideosUIRequestBody, DeleteVideosUIResponseBody,deleteVideosLoad, deleteVideosSuccess } from './deleteVideos';

import { ListUsersCurrentVideoDataUIRequestBody, ListUsersCurrentVideoDataUIResponseBody,listUsersCurrentVideoDataLoad, listUsersCurrentVideoDataSuccess } from './listUsersCurrentVideoData';



import { SaveUserVideoDataEditorSettingsUIRequestBody,saveUserVideoDataEditorSettingsLoad, saveUserVideoDataEditorSettingsSuccess } from './saveUserVideoDataEditorSettings';

import { LoadUserVideoDataEditorSettingsUIRequestBody, LoadUserVideoDataEditorSettingsUIResponseBody,loadUserVideoDataEditorSettingsLoad, loadUserVideoDataEditorSettingsSuccess } from './loadUserVideoDataEditorSettings';
import { NEED_MORE_CREDITS } from '@core/utility/constants';
import { SpecificService } from '@core/specific/specific.service';




@Injectable({
  providedIn: 'root',
})
export class PlatformsService {
  constructor(
    public http: HttpClient,
    public utilService: UtilityService,
    public accountsService:AccountsService,
    public socketioService:SocketioService,
    public specificService:SpecificService
  ) {}
  static NEED_NEW_TOKEN ="NEED_NEW_TOKEN"
  static ISSUES ="issues"
  videoDataEditorSaved ="global.saveBtnNotSaved"
  videoDataEditorIsModified = false
  videoDataEditorSettings
  requestYoutubeAuthorizationFromUser = (
    uiBody = new RequestYoutubeAuthorizationFromUserUIRequestBody(),
  ) => {
    return iif(
      () => ENV.platformsService.requestYoutubeAuthorizationFromUser.automate,
      of('requestYoutubeAuthorizationFromUserUrl'),
      requestYoutubeAuthorizationFromUserLoad(uiBody).pipe(
        concatMap((apiBody) => {
          return this.http
            .post(
              ENV.platformsService.requestYoutubeAuthorizationFromUser.url(),
              apiBody,
            )
            .pipe(
              concatMap(this.accountsService.invalidateCacheForUsers),
              map(requestYoutubeAuthorizationFromUserSuccess)
            );
        }),
      ),
    );
  };

  revokeYoutubeAuthorizationForUser = (
    uiBody = new RevokeYoutubeAuthorizationForUserUIRequestBody(),
  ) => {
    return iif(
      () => ENV.platformsService.revokeYoutubeAuthorizationForUser.automate,
      of(new RevokeYoutubeAuthorizationForUserUIResponseBody()),
      revokeYoutubeAuthorizationForUserLoad(uiBody).pipe(
        concatMap((apiBody) => {
          return this.http
            .post(
              ENV.platformsService.revokeYoutubeAuthorizationForUser.url(),
              apiBody,
            )
            .pipe(
              concatMap(this.accountsService.invalidateCacheForUsers),
              map(revokeYoutubeAuthorizationForUserSuccess)
            );
        }),
      ),
    );
  };



  videoManagerPageWasVisited = false
  listUsersCurrentVideoData = (uiBody = new ListUsersCurrentVideoDataUIRequestBody())=>{
    return iif(
      ()=>ENV.platformsService.listUsersCurrentVideoData.automate,
      of(new ListUsersCurrentVideoDataUIResponseBody()),
      listUsersCurrentVideoDataLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.platformsService.listUsersCurrentVideoData.url(),apiBody)
              .pipe(
                map(listUsersCurrentVideoDataSuccess)
              )
          })
        )
      )
  }

  listUsersVideoDataIsRunning = false
  listUsersVideoData = (uiBody = new ListUsersVideoDataUIRequestBody())=>{
    return iif(
      ()=>ENV.platformsService.listUsersVideoData.automate,
      of(new ListUsersVideoDataUIResponseBody()),
      listUsersVideoDataLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.platformsService.listUsersVideoData.url(), apiBody)
              .pipe(
                tap({
                  error:(err)=> {
                    if(err.error.description === NEED_MORE_CREDITS){
                      this.specificService.purchaseMoreCredits()
                    }
                  },
                }),
                concatMap((res:any) => {
                  return this.socketioService.getMessageFromSocket({res,name:"loadUserVideoDataEvent"});
                }),
                map(listUsersVideoDataSuccess)
              );
          })
        )
      )
  }

  addVideos = (uiBody = new AddVideosUIRequestBody())=>{
    return iif(
      ()=>ENV.platformsService.addVideos.automate,
      of(new AddVideosUIResponseBody()),
      addVideosLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.platformsService.addVideos.url(),apiBody)
              .pipe(

                concatMap((res:any) => {
                  return this.socketioService.getMessageFromSocket({res,name:"addVideosEvent"});
                }),
                map(addVideosSuccess)
              )
          })
        )
      )
    }

  deleteVideos = (uiBody = new DeleteVideosUIRequestBody())=>{
    return iif(
      ()=>ENV.platformsService.deleteVideos.automate,
      of(new DeleteVideosUIResponseBody()),
      deleteVideosLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .delete(ENV.platformsService.deleteVideos.url(),{body:apiBody})
              .pipe(map(deleteVideosSuccess))
          })
        )
      )
    }

  saveUserVideoDataEditorSettings = (uiBody = new SaveUserVideoDataEditorSettingsUIRequestBody())=>{
    let {currentUser} = this.accountsService
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken,
      googleDriveApiAccessToken:currentUser.googleDriveApiAccessToken,
      googleDriveApiRefreshToken:currentUser.googleDriveApiRefreshToken,
    })
    return iif(
      ()=>ENV.platformsService.saveUserVideoDataEditorSettings.automate,
      of(""),
      // @ts-ignore
      saveUserVideoDataEditorSettingsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.platformsService.saveUserVideoDataEditorSettings.url(),apiBody)
              .pipe(map(saveUserVideoDataEditorSettingsSuccess))
          })
        )
      )
    }

  loadUserVideoDataEditorSettings = (uiBody = new LoadUserVideoDataEditorSettingsUIRequestBody())=>{
    let {currentUser} = this.accountsService
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken,
      googleDriveApiAccessToken:currentUser.googleDriveApiAccessToken,
      googleDriveApiRefreshToken:currentUser.googleDriveApiRefreshToken,
    })
    return iif(
      ()=>ENV.platformsService.loadUserVideoDataEditorSettings.automate,
      of(new LoadUserVideoDataEditorSettingsUIResponseBody()),
      loadUserVideoDataEditorSettingsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.platformsService.loadUserVideoDataEditorSettings.url(),apiBody)
              .pipe(map(loadUserVideoDataEditorSettingsSuccess))
          })
        )
      )
    }


}

export enum PlatformsVideoDataEditorZeroSectionEnum {
  TITLE,TAGS,DESCRIPTION,THUMBNAIL
}

export enum PlatformsVideoDataEditorZeroActionEnum {
  PREPEND,APPEND,OVERWRITE,INSERT,REPLACE
}
