import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let registerViaPasswordLoad = (uiBody:RegisterViaPasswordUIRequestBody): Observable<RegisterViaPasswordAPIRequestBody>=>{



  let apiBody = new RegisterViaPasswordAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let registerViaPasswordSuccess = (apiBody:RegisterViaPasswordAPIResponseBody):RegisterViaPasswordUIResponseBody=>{
  let uiBody = new RegisterViaPasswordUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class RegisterViaPasswordUIRequestBody {
  constructor(props:Partial<RegisterViaPasswordUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  email:string
  pass:string
}

export class RegisterViaPasswordAPIRequestBody {
  constructor(props:Partial<RegisterViaPasswordAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RegisterViaPasswordUIRequestBody>
}

export class RegisterViaPasswordUIResponseBody {
  constructor(props:Partial<RegisterViaPasswordUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class RegisterViaPasswordAPIResponseBody {
  constructor(props:Partial<RegisterViaPasswordAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RegisterViaPasswordUIResponseBody>
}

