import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"
import { AuthBase } from "@core/utility/specific-utils/classes-utils"
import { ENV } from "@env/environment"

export let purchaseSubscriptionLoad = (uiBody:PurchaseSubscriptionUIRequestBody): Observable<PurchaseSubscriptionAPIRequestBody>=>{



  let apiBody = new PurchaseSubscriptionAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let purchaseSubscriptionSuccess = (apiBody:PurchaseSubscriptionAPIResponseBody):PurchaseSubscriptionUIResponseBody=>{
  let uiBody = new PurchaseSubscriptionUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class PurchaseSubscriptionUIRequestBody extends AuthBase {
  constructor(props:Partial<PurchaseSubscriptionUIRequestBody>={}){
    super()
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  subscriptionContractIds = []
  items:{
    quantity:number
    variantId:string,
    sellingPlanId:string
  }[] =[]
  redirectUrl:string
  appPlatformTypeEnumValue =ENV.platformType
}

export class PurchaseSubscriptionAPIRequestBody {
  constructor(props:Partial<PurchaseSubscriptionAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<PurchaseSubscriptionUIRequestBody>
}

export class PurchaseSubscriptionUIResponseBody {
  constructor(props:Partial<PurchaseSubscriptionUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  // this is shopify checkout id
  id:string
  weburl:string
}

export class PurchaseSubscriptionAPIResponseBody {
  constructor(props:Partial<PurchaseSubscriptionAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<PurchaseSubscriptionUIResponseBody>
}

