// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// wml components
import { WMLComponentsModule } from './wml-components/wml-components.module';

// i18n
import {  TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { OverlayLoadingComponent } from './components/overlay-loading/overlay-loading.component';



// misc
import { SampleCpntComponent } from './components/sample-cpnt/sample-cpnt.component';


import { ConfirmDialogZeroComponent } from './components/confirm-dialog-zero/confirm-dialog-zero.component';
import { VerifiedZeroComponent } from './components/verified-zero/verified-zero.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { NavOneComponent } from './components/nav-one/nav-one.component';
import { PricingZeroComponent } from './components/pricing-zero/pricing-zero.component';
import { PricingCardZeroComponent } from './components/pricing-card-zero/pricing-card-zero.component';
import { RatingStarsComponent } from './components/rating-stars/rating-stars.component';
import { DashboardCtrlZeroComponent } from './components/dashboard-ctrl-zero/dashboard-ctrl-zero.component';
import { LogoImgZeroComponent } from './components/logo-img-zero/logo-img-zero.component';
import { NavZeroComponent } from './components/nav-zero/nav-zero.component';
import { CardZeroComponent } from './components/card-zero/card-zero.component';
import { FilterZeroComponent } from './components/filter-zero/filter-zero.component';
import { CardOneComponent } from './components/card-one/card-one.component';
import { SearchZeroComponent } from './components/search-zero/search-zero.component';
import { VideoTileZeroComponent } from './components/video-tile-zero/video-tile-zero.component';
import { PageNumberControlZeroComponent } from './components/page-number-control-zero/page-number-control-zero.component';
import { CurrentPageControlZeroComponent } from './components/current-page-control-zero/current-page-control-zero.component';
import { DropdownOptionTwoComponent } from './components/dropdown-option-two/dropdown-option-two.component';
import { JobsZeroComponent } from './components/jobs-zero/jobs-zero.component';
import { CardTwoComponent } from './components/card-two/card-two.component';
import { JobTitleZeroComponent } from './components/job-title-zero/job-title-zero.component';
import { DownloadJobZeroComponent } from './components/download-job-zero/download-job-zero.component';
import { AiShortsJobZeroComponent } from './components/ai-shorts-job-zero/ai-shorts-job-zero.component';
import { JobStatusZeroComponent } from './components/job-status-zero/job-status-zero.component';
import { AddNewVideoOneComponent } from './components/add-new-video-one/add-new-video-one.component';
import { MultiInputOneComponent } from './components/multi-input-one/multi-input-one.component';
import { ENV } from '@env/environment';
import { VideoViewerZeroComponent } from './components/video-viewer-zero/video-viewer-zero.component';
import { VideoDataEditorZeroComponent } from './components/video-data-editor-zero/video-data-editor-zero.component';
import { OptionZeroComponent } from './components/option-zero/option-zero.component';
import { ContentEditorControlZeroComponent } from './components/content-editor-control-zero/content-editor-control-zero.component';
import { RichTextEditorZeroComponent } from './components/rich-text-editor-zero/rich-text-editor-zero.component';
import { InsertAtControlZeroComponent } from './components/insert-at-control-zero/insert-at-control-zero.component';
import { BackupPreviousStateJobZeroComponent } from './components/backup-previous-state-job-zero/backup-previous-state-job-zero.component';
import { UpdateVideosJobZeroComponent } from './components/update-videos-job-zero/update-videos-job-zero.component';
import { SortZeroComponent } from './components/sort-zero/sort-zero.component';
import { TransferRecipientsFormZeroComponent } from './components/transfer-recipients-form-zero/transfer-recipients-form-zero.component';

import { TransferLocalFilesJobZeroComponent } from './components/transfer-local-files-job-zero/transfer-local-files-job-zero.component';
import { FriendControlZeroComponent } from './components/friend-control-zero/friend-control-zero.component';
import { IntervalInputZeroComponent } from './components/interval-input-zero/interval-input-zero.component';

let components = [
  // StepperComponent,
  // ColorOptionZeroComponent,
  // CardNumberInputZeroComponent,
  // DropdownTwoComponent,
  ConfirmDialogZeroComponent,
  SampleCpntComponent,
  OverlayLoadingComponent,
  VerifiedZeroComponent,
  NavOneComponent,
  PricingZeroComponent,
  PricingCardZeroComponent,
  RatingStarsComponent,
  DashboardCtrlZeroComponent,
  LogoImgZeroComponent,
  NavZeroComponent,
  CardZeroComponent,
  FilterZeroComponent,
  CardOneComponent,
  SearchZeroComponent,
  VideoTileZeroComponent,
  PageNumberControlZeroComponent,
  CurrentPageControlZeroComponent,
  DropdownOptionTwoComponent,
  JobsZeroComponent,
  CardTwoComponent,
  JobTitleZeroComponent,
  DownloadJobZeroComponent,
  AiShortsJobZeroComponent,
  JobStatusZeroComponent,
  AddNewVideoOneComponent,
  MultiInputOneComponent,
  VideoViewerZeroComponent,
  VideoDataEditorZeroComponent,
  OptionZeroComponent,
  ContentEditorControlZeroComponent,
  RichTextEditorZeroComponent,
  InsertAtControlZeroComponent,
  BackupPreviousStateJobZeroComponent,
  UpdateVideosJobZeroComponent,
  SortZeroComponent,
  TransferRecipientsFormZeroComponent,
  TransferLocalFilesJobZeroComponent,
  FriendControlZeroComponent,
  IntervalInputZeroComponent

]


let modules = [
  TranslateModule,
  CommonModule,
  WMLComponentsModule,
  ReactiveFormsModule,
  RouterModule,
]
@NgModule({
  imports:[
    ...modules,
    NgHcaptchaModule.forRoot({
      siteKey: ENV.hcaptcha.siteKey
    }),

  ],
  exports: [
    ...components,
    ...modules
  ],
  providers:[
    {provide:TranslatePipe,useExisting:TranslatePipe}
  ],
  declarations: [
    ...components
  ]
})
export class SharedModule { }
