import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { Observable, of, take } from "rxjs"

export let revokeYoutubeAuthorizationForUserLoad = (uiBody:RevokeYoutubeAuthorizationForUserUIRequestBody): Observable<RevokeYoutubeAuthorizationForUserAPIRequestBody>=>{



  let apiBody = new RevokeYoutubeAuthorizationForUserAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let revokeYoutubeAuthorizationForUserSuccess = (apiBody:RevokeYoutubeAuthorizationForUserAPIResponseBody):RevokeYoutubeAuthorizationForUserUIResponseBody=>{
  let uiBody = new RevokeYoutubeAuthorizationForUserUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class RevokeYoutubeAuthorizationForUserUIRequestBody {
  constructor(props:Partial<RevokeYoutubeAuthorizationForUserUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  accessToken:string
  youtubeDataApiAccessToken:string
}

export class RevokeYoutubeAuthorizationForUserAPIRequestBody {
  constructor(props:Partial<RevokeYoutubeAuthorizationForUserAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RevokeYoutubeAuthorizationForUserUIRequestBody>
}

export class RevokeYoutubeAuthorizationForUserUIResponseBody {
  constructor(props:Partial<RevokeYoutubeAuthorizationForUserUIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

}

export class RevokeYoutubeAuthorizationForUserAPIResponseBody {
  constructor(props:Partial<RevokeYoutubeAuthorizationForUserAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RevokeYoutubeAuthorizationForUserUIResponseBody>
}

