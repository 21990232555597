import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { ReplaySubject, Subject, filter, map } from 'rxjs';
import { Socket, io } from 'socket.io-client';
import { HttpUtils } from '@core/utility/http-utils';
import { SocketioClientResponseModel } from './models/socketio-client-resp-model';
import { RequestFileTransferUIResponseBody } from '../jobs/requestFileTransfer';
import { RespondToFileTransferRequestUIResponseBody } from '../jobs/respondToFileTransferRequest';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService
  ) {
    this.connectAndSetupListeners();
  }

  client:Socket
  connectEvent = new ReplaySubject<string>(1)
  loadUserVideoDataEvent = new ReplaySubject<any>(Infinity)
  bulkVideoDownloadJobEvent = new ReplaySubject<any>(Infinity)
  addVideosEvent = new ReplaySubject<any>(Infinity)
  backupPreviousStateEvent = new ReplaySubject<any>(Infinity)
  updateVideosEvent = new ReplaySubject<any>(Infinity)
  fileTransferRequestEvent = new Subject<SocketioClientResponseModel>()
  fileTransferErrorEvent = new Subject<SocketioClientResponseModel>()
  // TODO mabye move to webrtc?
  fileTransferEvent = new Subject<SocketioClientResponseModel>()
  respondToFileTransferRequestEvent = new Subject<RequestFileTransferUIResponseBody>()
  fileTransferResponseEvent = new Subject<RespondToFileTransferRequestUIResponseBody>()
  orderPaidEvent = new ReplaySubject<any>(Infinity)
  subscriptionBilledEvent = new ReplaySubject<any>(Infinity)

  connectAndSetupListeners() {
    if(![null,undefined].includes(this.client)){
      return
    }
    this.client = io(ENV.backendDomain0,{secure:true})
    this.client.on("connect", () => {
      this.connectEvent.next(this.client.id);
    });
    this.client.on("load_user_video_data", (data) => {
      this.loadUserVideoDataEvent.next(data);
    });
    this.client.on("bulk_video_download", (data) => {
      this.bulkVideoDownloadJobEvent.next(data);
    });
    this.client.on("backup_previous_state", (data) => {
      this.backupPreviousStateEvent.next(data);
    });
    this.client.on("update_videos", (data) => {
      this.updateVideosEvent.next(data);
    });
    this.client.on("add_videos_to_user_video_data", (data) => {
      this.addVideosEvent.next(data);
    });
    this.client.on("file_transfer_request", (data) => {
      this.fileTransferRequestEvent.next(data);
    });
    this.client.on("file_transfer_error", (data) => {
      this.fileTransferErrorEvent.next(data);
    });
    this.client.on("respond_to_file_transfer_request", (data) => {
      this.respondToFileTransferRequestEvent.next(data);
    });
    this.client.on("file_transfer_response", (data) => {
      this.fileTransferResponseEvent.next(data);
    });
    this.client.on("order_paid", (data) => {
      this.orderPaidEvent.next(data);
    });
    this.client.on("subscription_billed", (data) => {
      this.subscriptionBilledEvent.next(data);
    });
  }

  getMessageFromSocket(props:{res: { data: Array<{task_id:string}>; msg: string; },name:keyof SocketioService}) {
    let {res,name} = props
    return (this[name] as ReplaySubject<any>)
      .pipe(
        filter((res2) => {

          return Boolean(res.data.find((task) => task.task_id === res2.data.task_id));
        }),
        map(this.validateSocketIOResponse)
      );
  }

  validateSocketIOResponse(res2) {
    if (HttpUtils.isServerError(res2.code) || HttpUtils.isClientError(res2.code)) {
      throw new Error(res2.data.msg);
    }
    return { data: res2.data.result };
  }
}
