// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject, takeUntil, tap } from 'rxjs';

// wml-components
import { generateClassPrefix } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { StoreServiceItemPrice } from '@shared/services/store/models';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';

@Component({
  selector: 'pricing-card-zero',
  templateUrl: './pricing-card-zero.component.html',
  styleUrls: ['./pricing-card-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingCardZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  classPrefix = generateClassPrefix('PricingCardZero');
  idPrefix = generateClassPrefix(ENV.idPrefix.pricingCardZero);
  @Input('props') props: PricingCardZeroProps = new PricingCardZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();

  listenForChanges = ()=>{
    return this.props.detectChangesSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap(()=>{
        this.cdref.detectChanges()
      })
    )

  }

  ngOnInit(): void {
    if(ENV.app.toPassChromeOauthVerficationTest){
      this.props.description = this.props.description.filter((item)=>{
        return !item.includes("download")
      })
    }
    this.listenForChanges().subscribe()
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}



export class PricingCardZeroProps<M=any> {
  constructor(props:Partial<PricingCardZeroProps<M> &{propSelectClick:WMLButtonOneProps["click"],propSelectBtnIsPresent:boolean}>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
    if(props.propSelectClick){
      this.selectBtn.click = props.propSelectClick
    }
    if(![null,undefined].includes(props.propSelectBtnIsPresent)){
      this.selectBtn.isPresent = props.propSelectBtnIsPresent
    }
  }

  name = "PricingCardZero.name";
  // interval = "PricingCardZero.intervals.month";
  price = new StoreServiceItemPrice()
  selectBtn = new WMLButtonOneProps({
    text:"PricingCardZero.selectBtn"
  })
  description:Array<string> = [
    "Provide Product Details",
    "Over in",
    "PricingCardZeroProps['description'] as an string array"
  ]
  metadata:M
  readonly detectChangesSubj=new Subject<void>()

}


