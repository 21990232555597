// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

// wml-components
import { WMLConstructorDecorator, generateClassPrefix, generateIdPrefix, WMLCustomComponent } from '@windmillcode/wml-components-base';

// misc
import { ENV } from '@env/environment';
import { WMLFormZeroProps, WMLFormZeroComponent } from '@windmillcode/angular-wml-form';
import { FormsService } from '@shared/services/forms/forms.service';
import { WMLAccordionZeroItemProps, WMLAccordionZeroProps } from '@windmillcode/angular-wml-accordion';
import { FormGroup, FormControl } from '@angular/forms';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';

@Component({
  selector: 'friend-control-zero',
  templateUrl: './friend-control-zero.component.html',
  styleUrls: ['./friend-control-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FriendControlZeroComponent {
  constructor(
    public formsService: FormsService,
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  classPrefix = generateClassPrefix('FriendControlZero');
  idPrefix = generateIdPrefix(ENV.idPrefix.friendControlZero);
  @Input('props') props: FriendControlZeroProps = new FriendControlZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix('View');
  @HostBinding('id') myId: string = this.idPrefix();

  ngUnsub = new Subject<void>();

  listenForUpdate = () => {
    return this.props.setStateSubj.pipe(
      takeUntil(this.ngUnsub),
      tap((res?) => {
        this.props = new FriendControlZeroProps({
          ...this.props,
          ...(res ?? this.props),
        });
        this.cdref.detectChanges();
      })
    );
  };

  mainForm = new WMLFormZeroProps({});


  createMainFormField = (props: Partial<{ createWMLFieldFn: any; errorMsgKeyArray: any; fieldCustomProps: any; formControlName: keyof FriendControlZeroProps["formGroup"]['controls']["mainForm"]["value"] }>) => {
    let { createWMLFieldFn, errorMsgKeyArray, fieldCustomProps, formControlName } = props;
    let field = this.baseService.createWMLField({
      i18nPrefix: 'FriendControlZero.mainForm',
      idPrefixFn: this.idPrefix,
      fieldParentForm: this.props.formGroup.controls.mainForm,
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
    });
    return field;
  };

  createEmailsField = () => {
    let field = this.createMainFormField({
      formControlName: 'email',
      errorMsgKeyArray: [],
      fieldCustomProps: null,
    });
    field.deleteRequiredLabelPart();
    return field;
  };

  initMainForm = () => {
    let fields = [this.createEmailsField()];
    this.mainForm.updateFields(fields);
  };

  friendForm = new WMLFormZeroProps({});

  friendAccordion = new WMLAccordionZeroProps({
    propItems:[new WMLAccordionZeroItemProps({
      isClosed:false,
      propTitle:"",
      accordionBody:new WMLCustomComponent({
        cpnt:WMLFormZeroComponent,
        props:this.friendForm
      }),
    })]
  })

  createFriendFormField = (props: Partial<{ createWMLFieldFn: any; errorMsgKeyArray: any; fieldCustomProps: any; formControlName: keyof FriendControlZeroProps["formGroup"]['controls']["friendForm"]["value"] }>) => {
    let { createWMLFieldFn, errorMsgKeyArray, fieldCustomProps, formControlName } = props;
    let field = this.baseService.createWMLField({
      i18nPrefix: 'FriendControlZero.friendForm',
      idPrefixFn: this.idPrefix,
      fieldParentForm: this.props.formGroup.controls.friendForm,
      formControlName,
      createWMLFieldFn,
      errorMsgKeyArray,
      fieldCustomProps,
    });
    return field;
  };

  createReceiveFilesField = () => {
    let field = this.createFriendFormField({
      formControlName: 'receiveFiles',
      errorMsgKeyArray: [],
      createWMLFieldFn:this.baseService.createToggleField,

    });
    field.deleteRequiredLabelPart();
    return field;
  };



  initFriendForm = () => {
    let fields = [this.createReceiveFilesField()];
    this.friendForm.updateFields(fields);
  };

  ngOnInit(): void {
    this.initFriendForm();
    this.initMainForm();
    this.listenForUpdate().subscribe();
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

@WMLConstructorDecorator
export class FriendControlZeroProps {
  constructor(props: Partial<FriendControlZeroProps> = {}) {}

  setStateSubj = new Subject<FriendControlZeroProps>();
  setState = (value) => {
    this.setStateSubj.next(value);
  }
  wmlField:WMLFieldZeroProps
  get formGroup(){
    return this.wmlField.getReactiveFormControl() as FormGroup<{
      mainForm:FormGroup<{
        email:FormControl<string>
      }>
      friendForm:FormGroup<{
        receiveFiles:FormControl<boolean>
      }>
    }>
  }
}

