import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let useCreditsLoad = (uiBody:UseCreditsUIRequestBody): Observable<UseCreditsAPIRequestBody>=>{



  let apiBody = new UseCreditsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})

  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let useCreditsSuccess = (apiBody:UseCreditsAPIResponseBody):UseCreditsUIResponseBody=>{
  let uiBody = new UseCreditsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class UseCreditsUIRequestBody {
  constructor(props:Partial<UseCreditsUIRequestBody>={}){}

  // snake case keyof keyof AccountsServiceUser["credits"]
  creditType:string
  amount:number
  accessToken:string
}

@WMLConstructorDecorator
export class UseCreditsAPIRequestBody {
  constructor(props:Partial<UseCreditsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<UseCreditsUIRequestBody>
}

@WMLConstructorDecorator
export class UseCreditsUIResponseBody {
  constructor(props:Partial<UseCreditsUIResponseBody>={}){}

}

@WMLConstructorDecorator
export class UseCreditsAPIResponseBody {
  constructor(props:Partial<UseCreditsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<UseCreditsUIResponseBody>
}

