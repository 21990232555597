// angular
import { ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';
import { WMLOptionZeroItemProps } from '@windmillcode/angular-wml-options';



@Component({
  selector: 'option-zero',
  templateUrl: './option-zero.component.html',
  styleUrls: ['./option-zero.component.scss']


})
export class OptionZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('OptionZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.optionZero)
  @Input('props') props: OptionZeroProps = new OptionZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()


  listenForUpdate = ()=>{
    return this.props.updateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res?)=>{
        this.props = new OptionZeroProps({
          ...this.props,
          ...(res ?? this.props)
        })
        this.cdref.detectChanges()
      })
    )
  }


  ngOnInit(): void {
    this.listenForUpdate().subscribe()

  }

  ngOnChanges(){
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class OptionZeroProps {
  constructor(props:Partial<OptionZeroProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }

  updateSubj = new Subject<OptionZeroProps>()
  wmlOptionItem:WMLOptionZeroItemProps
}


