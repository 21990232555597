// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';

// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';

// rxjs
import { Subject } from 'rxjs';

// wml-components
import { generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc
import { ENV } from '@env/environment';
import { WMLInputZeroAreaProps, WMLInputZeroProps } from '@windmillcode/angular-wml-input';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';



@Component({
  selector: 'search-zero',
  templateUrl: './search-zero.component.html',
  styleUrls: ['./search-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,
})
export class SearchZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('SearchZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.searchZero)
  @Input('props') props: SearchZeroProps = new SearchZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  searchBtn = new WMLButtonOneProps({
    iconType:"icon",
    iconClass:'fa-solid fa-search',
    iconAlt:"SearchZero.searchBtn.iconAlt",
    iconIsPresent:true,
    style:{
      display:"none"
    },
    click:()=>this.clickSearchBtn()
  })

  clickSearchBtn = ()=>{
    let value = this.props.input.wmlField.getReactiveFormControl().value
    if([null,undefined].includes(value)){
      value = ''
    }
    this.props.searchEvent.next(value)
  }
  ngOnInit(): void {
    this.cdref.detectChanges()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class SearchZeroProps {
  constructor(props:Partial<SearchZeroProps &
    {propInputClass,propInputPlaceholder}
  >={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
    if(props.propInputPlaceholder){
      this.input.input.placeholder = props.propInputPlaceholder
    }
    if(props.propInputClass){
      this.input.input.class = props.propInputClass
    }
  }
  searchEvent = new Subject<string>()
  input = new WMLInputZeroProps({
    input :new WMLInputZeroAreaProps({
      placeholder:"SearchZero.placeholder"
    }),
    wmlField:new WMLFieldZeroProps({
      type:"default"
    })
  })

}


