import { transformObjectKeys } from "@core/utility/common-utils"
import { WMLDataSourceSinkResponseBody } from "@core/utility/data-source-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let listSubscriptionsLoad = (uiBody:ListSubscriptionsUIRequestBody): Observable<ListSubscriptionsAPIRequestBody>=>{

  let apiBody = new ListSubscriptionsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listSubscriptionsSuccess = (apiBody:ListSubscriptionsAPIResponseBody):ListSubscriptionsUIResponseBody=>{

  let uiBody = new ListSubscriptionsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

export class ListSubscriptionsUIRequestBody extends WMLAPIPaginationRequestModel  {
  constructor(props:Partial<ListSubscriptionsUIRequestBody>={}){
    super();
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
}

export class ListSubscriptionsAPIRequestBody {
  constructor(props:Partial<ListSubscriptionsAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<ListSubscriptionsUIRequestBody>
}

export class ListSubscriptionsUIResponseBody<T = ListSubscriptionsEntity>  extends WMLAPIPaginationResponseModel<T>{


}

export class ListSubscriptionsAPIResponseBody<T = ListSubscriptionsEntity > extends WMLDataSourceSinkResponseBody {
  constructor(props:Partial<ListSubscriptionsAPIResponseBody>={}){
    super(props)
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
}

export class ListSubscriptionsEntity{
  constructor(props:Partial<ListSubscriptionsEntity>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  id: string;
  name: string;
  productId:string
  productVariant:{
    id:string
  }
  sellingPlans: {
    price: string;
    currency: string;
    id: string;
    interval: string;
    description:Array<string>
  }[] =[]
}
