import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"
import { PlatformsService } from "../platforms/platforms.service"

export let listFriendsLoad = (uiBody:ListFriendsUIRequestBody): Observable<ListFriendsAPIRequestBody>=>{
  let apiBody = new ListFriendsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listFriendsSuccess = (apiBody:ListFriendsAPIResponseBody):ListFriendsUIResponseBody=>{
  // @ts-ignore
  if(apiBody.data ===PlatformsService.NEED_NEW_TOKEN){
    return apiBody.data
  }
  let uiBody = new ListFriendsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class ListFriendsUIRequestBody  {
  constructor(props:Partial<ListFriendsUIRequestBody>={}){ }
  accessToken:string =""
}

@WMLConstructorDecorator
export class ListFriendsAPIRequestBody {
  constructor(props:Partial<ListFriendsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<ListFriendsUIRequestBody>
}

@WMLConstructorDecorator
export class ListFriendsUIResponseBody {
  constructor(props:Partial<ListFriendsUIResponseBody>={}){}
  friends:Array<{email:string,receiveFiles:boolean}>
}

@WMLConstructorDecorator
export class ListFriendsAPIResponseBody {
  constructor(props:Partial<ListFriendsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<ListFriendsUIResponseBody>
}

