<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <img
    [class]="classPrefix('Pod0Img0')"
    [src]=props.backgroundImg.src [alt]="props.backgroundImg.alt | translate" />
  </section>
  <section [class]="classPrefix('Pod1')">
    <wml-button-one [props]="btn" *ngFor="let btn of props.commandBtns"></wml-button-one>
  </section>
</div>
