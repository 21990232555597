// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding,  Input   } from '@angular/core';


// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';




// rxjs
import { Subject } from 'rxjs';
import { takeUntil,tap } from 'rxjs/operators';

// wml-components
import { WMLUIProperty, generateClassPrefix, generateIdPrefix } from '@windmillcode/wml-components-base';


// misc

import { ENV } from '@env/environment';
import { isMissingValue } from '@core/utility/common-utils';



@Component({

  selector: 'page-number-control-zero',
  templateUrl: './page-number-control-zero.component.html',
  styleUrls: ['./page-number-control-zero.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush,

})
export class PageNumberControlZeroComponent  {

  constructor(
    public cdref:ChangeDetectorRef,
    public utilService:UtilityService,
    public baseService:BaseService
  ) { }

  classPrefix = generateClassPrefix('PageNumberControlZero')
  idPrefix = generateIdPrefix(ENV.idPrefix.pageNumberControlZero)
  @Input('props') props: PageNumberControlZeroProps = new PageNumberControlZeroProps()
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId:string = this.idPrefix()
  ngUnsub= new Subject<void>()
  displayPageNumbers = PageNumberControlZeroProps["items"]=[]
  lastCurrentPage = 0

  listenForPropsChanges = ()=>{
    return this.props.updateSubj
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{

        this.props = res
        this.updateDisplayedPageNumbers();
        let selected = this.props.items[this.props.currentPage]
        if(selected){
          this.togglePageNum(selected,"add")
        }
        this.cdref.detectChanges()
      })
    )

  }

  togglePageNum =(page:WMLUIProperty,action?:"add"|"remove")=>{
    this.props.items.forEach((item)=>{
      item.updateClassString("PageNumberControlZeroPod0Item1Selected","remove")
    })
    action = ![null,undefined,""].includes(action) ? action: page.class.includes("PageNumberControlZeroPod0Item1Selected") ? "remove" : "add"
    page.updateClassString(
      "PageNumberControlZeroPod0Item1Selected",
      action
    )
    this.props.currentPage = this.props.items.findIndex((item)=>{

      return page.value === item.value
    })
    this.props.pageNumChangeEvent.next(page.value)
    this.cdref.detectChanges()
  }


  updateDisplayedPageNumbers=()=> {
    let startingIndex = this.props.currentPage / this.props.amntOfPagesToShow
    startingIndex = Math.floor(startingIndex)
    startingIndex *= this.props.amntOfPagesToShow
    this.displayPageNumbers = this.props.items.slice(
      startingIndex,
      startingIndex + this.props.amntOfPagesToShow
    );
    this.lastCurrentPage = this.props.currentPage;
  }

  ngOnInit(): void {
    this.props.items = this.props.items.map((item,index0)=>{

      item.value = isMissingValue(item.value,index0)
      return item
    })
    this.listenForPropsChanges().subscribe()
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete()
  }

}



export class PageNumberControlZeroProps {
  constructor(props:Partial<PageNumberControlZeroProps>={}){
    let origProps = Object.entries(props)
    .filter(([key,val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) })
  }
  amntOfPagesToShow = 4
  items:Array<WMLUIProperty> =[]
  currentPage:number = 0
  updateSubj = new Subject<PageNumberControlZeroProps>()
  pageNumChangeEvent = new Subject<any>()
}


