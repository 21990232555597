<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <div *ngFor="let page of displayPageNumbers"
      (click)="togglePageNum(page)"
      [class]="classPrefix('Pod0Item1') + page.class">
        <p>{{page.text}}</p>
      </div>
    </div>
  </section>
</div>
