
import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { WMLAPIPaginationRequestModel, WMLAPIPaginationResponseModel, WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let listUsersLoad = (uiBody:ListUsersUIRequestBody): Observable<ListUsersAPIRequestBody>=>{

  let apiBody = new ListUsersAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  apiBody.data.page_size =1


  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listUsersSuccess = (apiBody:ListUsersAPIResponseBody):ListUsersUIResponseBody=>{
  let uiBody = new ListUsersUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  uiBody.data =uiBody.data.map((user)=>{
    user.subscriptions = (user.subscriptions ??[]).map((sub)=>{
      if(["DEV","DOCKER_DEV"].includes(ENV.type)){
        sub.creationDate = new Date(sub.creationDate).toLocaleString();
        sub.nextBillDate = new Date(sub.nextBillDate).toLocaleString();
      }
      else{
        sub.creationDate = new Date(sub.creationDate).toLocaleDateString();
        sub.nextBillDate = new Date(sub.nextBillDate).toLocaleDateString();
      }
      return sub
    })
    return user
  })
  return uiBody
}


export enum ListUsersUIRequestBodyTypeEnum {
  CREATEACCTIFNOTEXISTS
}

@WMLConstructorDecorator
export class ListUsersUIRequestBody extends WMLAPIPaginationRequestModel {

  accessToken:string
  type:ListUsersUIRequestBodyTypeEnum
}

export class ListUsersUIResponseBody extends WMLAPIPaginationResponseModel<ListUsersEntity> {
  constructor(props:Partial<ListUsersUIResponseBody>={}){
    super(props)
    Object.assign(
      this,
      {
        ...props
      }
    )
  }


}



export class ListUsersAPIRequestBody {
  constructor(props:Partial<ListUsersAPIRequestBody>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
  }
  data:RecursiveSnakeCaseType<ListUsersUIRequestBody>

}

export class ListUsersAPIResponseBody {
  constructor(props:Partial<ListUsersAPIResponseBody>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
  }
  data:RecursiveSnakeCaseType<ListUsersUIResponseBody>
}

export class ListUsersEntity {
  constructor(props: Partial<ListUsersEntity> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });

  }

  addresses?:{
    city: string;
    country: string;
    secondaryAdr: string;
    state: string;
    streetAdr: string;
    zipcode: string;
  }
  subscriptions:Array<{
    confirmationNumber: string;
    price: string;
    priceCurrency:string;
    title: string;
    id:string
    creationDate:string
    nextBillDate:string
  }>
  // dev additions
  googleDriveApiAccessToken:string
  googleDriveApiRefreshToken:string
  youtubeDataApiRefreshToken:string
  youtubeDataApiAccessToken:string
  emailNotificationsIsPresent:boolean
  smsNotificationsIsPresent:boolean
  incomingVideosIsPresent:boolean
  startWithGuide:boolean
  credits:{
    download:number,
    backup:number,
    bulkEdit:number,
    fileTransfer:number,
    channel:number
  }
}
