import { WMLConstructorDecorator } from "@windmillcode/wml-components-base";
import { WMLFileUploadZeroProps } from "@windmillcode/angular-wml-file-manager";

@WMLConstructorDecorator
export class MyWMLFileUploadZeroProps extends WMLFileUploadZeroProps {
  constructor(props: Partial<MyWMLFileUploadZeroProps> = {}) {super() }

  override dragDropText ='global.wmlFileUpload.dragDropText'
  override browseFileText= "global.wmlFileUpload.browseFileText"
}

