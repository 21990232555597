<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')" >
      <h3 [class]="classPrefix('Pod0Text0')">{{props.title | translate}}</h3>
      <div [class]="classPrefix('Pod0Line0')"></div>
    </div>
    <wml-button-one
    *ngIf="props.titleBtn.isPresent"
    [props]="props.titleBtn"></wml-button-one>
  </section>
  <section [class]="classPrefix('Pod1')">
    <ng-content *ngIf="!props.customBody"></ng-content>
    <ng-template *ngIf="props.customBody" #customBody></ng-template>
  </section>
</div>
