import { ChangeDetectorRef, Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import { ENV } from '@env/environment';
import { Observable , BehaviorSubject, catchError, concatMap, defer, delay, filter, from, iif, map, of, ReplaySubject, Subject, take, takeUntil, tap, throwError, merge, shareReplay } from 'rxjs';
import { FirebaseService } from '../firebase/firebase.service';
import { signInWithPopup, onAuthStateChanged, User, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updatePassword, sendEmailVerification, signInWithCustomToken } from 'firebase/auth';
import { BaseService } from '@core/base/base.service';
import { SpecificService } from '@core/specific/specific.service';
import { WMLImage } from '@windmillcode/wml-components-base';
import { ListUsersAPIResponseBody , ListUsersEntity, ListUsersUIRequestBody, ListUsersUIRequestBodyTypeEnum, ListUsersUIResponseBody, listUsersLoad, listUsersSuccess } from './listUsers';
import { ListCardsUIResponseBody } from './listCards';
import { DeleteCardsUIRequestBody, DeleteCardsUIResponseBody, deleteCardsLoad, deleteCardsSuccess } from './deleteCards';
import { DeleteUserUIRequestBody, DeleteUserUIResponseBody, deleteUserLoad, deleteUserSuccess } from './deleteUser';
import { ExportUsersUIRequestBody, ExportUsersUIResponseBody, exportUsersLoad, exportUsersSuccess } from './exportUsers';
import { isNetworkConnectionGood } from '@core/utility/common-utils';
import { AuthAccountDisabled, AuthEmailAlreadyInUse, AuthProviderExists, AuthUserNotFound, AuthWrongPassword, UserCanceledAuthError } from '@core/utility/error-utils';
import { SocketioService } from '../socketio/socketio.service';

import { GetCardServerSessionIdUIRequestBody, GetCardServerSessionIdUIResponseBody, getCardServerSessionIdLoad, getCardServerSessionIdSuccess } from './getCardServerSessionId';
import { AddCardToUserUIRequestBody, AddCardToUserUIResponseBody, addCardToUserLoad, addCardToUserSuccess } from './addCardToUser';

import { UpdateAdressesUIRequestBody,UpdateAdressesUIResponseBody,updateAdressesLoad, updateAdressesSuccess } from './updateAdresses';
import { UpdateUserUIRequestBody,UpdateUserUIResponseBody,updateUserLoad, updateUserSuccess } from './updateUser';

import { RegisterViaPasswordUIRequestBody,RegisterViaPasswordUIResponseBody } from './registerViaPassword';
import { LoginViaPasswordUIRequestBody,LoginViaPasswordUIResponseBody } from './loginViaPassword';
import { WMLNotifyOneBarType } from '@windmillcode/angular-wml-notify';

import { ChangePasswordUIRequestBody,ChangePasswordUIResponseBody } from './changePassword';
import { DeleteUserDataUIRequestBody,DeleteUserDataUIResponseBody,deleteUserDataLoad, deleteUserDataSuccess } from './deleteUserData';

import { WMLDataSource } from '@core/utility/data-source-utils';
import localforage from 'localforage';

import { MapSocketIOSessionIdToUserUIRequestBody, MapSocketIOSessionIdToUserUIResponseBody,mapSocketIOSessionIdToUserLoad, mapSocketIOSessionIdToUserSuccess } from './mapSocketIOSessionIdToUser';
import { EmailPasswordRecoveryLinkUIRequestBody, EmailPasswordRecoveryLinkUIResponseBody } from './emaillPasswordRecoveryLink';
import { deepCopyInclude } from '@core/utility/object-utils';

import { ListFriendsUIRequestBody, ListFriendsUIResponseBody,listFriendsLoad, listFriendsSuccess } from './listFriends';
import { UpdateFriendsUIRequestBody, UpdateFriendsUIResponseBody,updateFriendsLoad, updateFriendsSuccess } from './updateFriends';


import { ListCreditsUIRequestBody, ListCreditsUIResponseBody,listCreditsLoad, listCreditsSuccess } from './listCredits';
import { captureMessage } from '@sentry/angular-ivy';


import { GetCustomTokensUIRequestBody, GetCustomTokensUIResponseBody,getCustomTokensLoad, getCustomTokensSuccess } from './getCustomTokens';


@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    public http:HttpClient,
    public utilService:UtilityService,
    public firebaseService:FirebaseService,
    public baseService:BaseService,
    public specificService:SpecificService,
    public injector:Injector,
    public socketioService:SocketioService,
  ) {
  }

  users:AccountsServiceUser[] = []
  currentUser:AccountsServiceUser
  paymentMethodsIsReadySubj = new BehaviorSubject<boolean>(false)
  paymentMethodsIsReadyObs$ = this.paymentMethodsIsReadySubj
  .pipe(
    filter((val) => val === true),
    take(1)
  )
  onUserLoggedInEvent = new ReplaySubject<void>(Infinity)
  onUserLoggedOutEvent = new ReplaySubject<void>(Infinity)
  onNoUserEvent = new ReplaySubject<void>(Infinity)
  onMapSocketIOSessionIdToUserEvent = new ReplaySubject<void>(Infinity)
  onUserFriendsEvent = new ReplaySubject<void>(Infinity)
  onUserEvent = merge(
    this.onNoUserEvent.pipe(map(()=>"noUser")),
    this.onUserLoggedInEvent.pipe(map(()=>"userLoggedIn")),
    this.onUserLoggedOutEvent.pipe(map(()=>"userLoggedOut")),
  )
  .pipe(
    shareReplay({ bufferSize: 1, refCount: true })
  );

  invalidateCacheForUsers = (res)=>{
    return this.listUsersDataSource.invalidateCache()
    .pipe(
      map(()=> res)
    )
  }

  invalidateCacheForUserVideoData =(res)=>defer(async ()=>{
    await localforage.removeItem(ENV.localForage.UserVideoData)
    return res
  })

  invalidateCacheForUserVideoDataEdits =(res)=>defer(async ()=>{
    await localforage.removeItem(ENV.localForage.VideoDataEditor)
    return res
  })

  hasVideoAndStorageProviderTokens() {

    let {youtubeDataApiAccessToken,youtubeDataApiRefreshToken,googleDriveApiAccessToken,googleDriveApiRefreshToken} = this.currentUser
    return youtubeDataApiAccessToken && youtubeDataApiRefreshToken && googleDriveApiAccessToken && googleDriveApiRefreshToken;
  }

  authUserViaLogoClick(
    ngUnsub: Subject<void>,
    provider:keyof FirebaseService["idpInfo"],
  ) {
    return this.authenticateViaFirebaseProvider(
      provider
    )
    .pipe(
      takeUntil(ngUnsub),
      catchError((err) => throwError(() => {
        this.baseService.closeOverlayLoading()
        if(err.message.includes("Firebase: Error (auth/popup-closed-by-user)")){
          return new UserCanceledAuthError(err)
        }
        else if(err.message.includes("Firebase: Error (auth/user-disabled).")){
          return new AuthAccountDisabled(err)
        }
        else if(err.message.includes("Firebase: Error (auth/account-exists-with-different-credential)")){
          return new AuthProviderExists(err)
        }
        else{
          captureMessage(err);
        }
        return err;

      })),
    )
  }

  authenticateViaFirebaseProvider = (provider:keyof FirebaseService["idpInfo"])=>{

    let idpInfo = this.firebaseService.idpInfo[provider]

    return from(
      signInWithPopup(this.firebaseService.auth, idpInfo.provider)
    )
  }

  registerViaPassword = (uiBody = new RegisterViaPasswordUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.registerViaPassword.automate,
      of(new RegisterViaPasswordUIResponseBody()),
      defer(()=>createUserWithEmailAndPassword(
        this.firebaseService.auth, uiBody.email, uiBody.pass))
    )
    .pipe(
      catchError((err)=>{
        return throwError(()=>{
          this.baseService.closeOverlayLoading()
          if(err.message.includes("Firebase: Error (auth/email-already-in-use).")){
            return new AuthEmailAlreadyInUse(err)
          }
          else if(err.message.includes("Firebase: Error (auth/account-exists-with-different-credential)")){
            return new AuthProviderExists(err)
          }
          return err
        })

      })
    )

  }

  loginViaPassword = (uiBody = new LoginViaPasswordUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.loginViaPassword.automate,
      of(new LoginViaPasswordUIResponseBody()),
      defer(()=>signInWithEmailAndPassword(
        this.firebaseService.auth, uiBody.email, uiBody.pass))
      )
      .pipe(
        catchError((err)=>{
          return throwError(()=>{
            this.baseService.closeOverlayLoading()
            if(err.message.includes("Firebase: Error (auth/user-not-found).")){
              return new AuthUserNotFound(err)
            }
            else if(err.message.includes("Firebase: Error (auth/wrong-password).")){
              return new AuthWrongPassword(err)
            }
            else if(err.message.includes("Firebase: Error (auth/user-disabled).")){
              return new AuthAccountDisabled(err)
            }
            else if(err.message.includes("Firebase: Error (auth/account-exists-with-different-credential)")){
              return new AuthProviderExists(err)
            }
            return err
          })

        })
      )
  }

  loginViaCustomToken = (token:string)=>{
    return iif(
      ()=>ENV.accountsService.loginViaCustomToken.automate,
      of(null),
      defer(()=>signInWithCustomToken(
        this.firebaseService.auth, token))
      )
      .pipe(
        catchError((err)=>{
          return throwError(()=>{
            return err
          })

        })
      )
  }

  emailPasswordRecoveryLink = (uiBody = new EmailPasswordRecoveryLinkUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.EmailPasswordRecoveryLink.automate,
      of(new EmailPasswordRecoveryLinkUIResponseBody()),
      defer(()=>sendPasswordResetEmail(
        this.firebaseService.auth, uiBody.email))
      )
      .pipe(
        catchError((err)=>{
          return throwError(()=>{
            this.baseService.closeOverlayLoading()
            if(err.message.includes("Firebase: Error (auth/user-not-found).")){
              return new AuthUserNotFound(err)
            }
            else if(err.message.includes("Firebase: Error (auth/user-disabled).")){
              return new AuthAccountDisabled(err)
            }
            return err
          })

        })
      )
  }

  changePassword = (uiBody = new ChangePasswordUIRequestBody())=>{
    let missingPassworProvider = !this.currentUser.user.providerData.find((data)=>data.providerId ==="password")

    let needsToLoginFirst=  uiBody.currentPass === ""  && Boolean(missingPassworProvider)
    return  iif(
      ()=>needsToLoginFirst,
      of(null),
      this.loginViaPassword({email:this.currentUser.businessEmail,pass:uiBody.currentPass})
    )
    .pipe(
      tap({
        error:(err)=>{
          if(needsToLoginFirst){
            this.baseService.createWMLNote("AccountsService.WMLNotifyOne.changePasswordWithoutCurrentPassword",WMLNotifyOneBarType.Error)
          }
          else if(err.message.includes("Firebase: Error (auth/requires-recent-login)")){
            this.baseService.createWMLNote("AccountsService.WMLNotifyOne.signInAgainToChangePassword",WMLNotifyOneBarType.Error)
          }
        }
      }),
      concatMap(()=>{
        return iif(
          ()=>ENV.accountsService.changePassword.automate,
          of(new ChangePasswordUIResponseBody()),
          defer(()=>updatePassword(
            this.currentUser.user, uiBody.pass))
          )
      })
    )


  }

  proceed= ()=>{
    this.onUserLoggedInEvent.next()
    if(this.currentUser.startWithGuide){

      // had to make a tiny sacrifice
      localforage.getItem(ENV.localForage.LoginFromFirebaseCustomToken)
      .then((loginFromFirebaseCustomToken)=>{
        if(!loginFromFirebaseCustomToken){
          this.utilService.router.navigateByUrl(ENV.nav.urls.guide)
        }
        else{
          localforage.setItem(ENV.localForage.LoginFromFirebaseCustomToken,false).then(()=>{})
        }
      })

    }
    else if([
      "signUpPage",
      "signInPage",
    ].map((val)=>{
      return ENV.nav.urls[val]
    }).includes(this.utilService.router.url)){
      this.utilService.router.navigateByUrl(ENV.nav.urls.accountOverviewPage)
    }
    this.baseService.closeOverlayLoading()
  }

  setupUserAfterLogin(user: User) {

    if(!user.emailVerified){
      return defer(async ()=>{

        await sendEmailVerification(user)
        this.utilService.router.navigateByUrl(ENV.nav.urls.verifyEmailZeroPage)
        this.baseService.closeOverlayLoading();
        this.onUserLoggedInEvent.next()
      })
    }

    return defer(async ()=>{
      this.specificService.getOneSignalDeferredGlobalVar().push(async (OneSignal)=> {
        await OneSignal.login(this.currentUser.id)
        .catch((err)=>{
          console.error(err)
        })
        ;
      });
    })
    .pipe(
      catchError((err)=>{
        return of(null)
      }),
      concatMap(()=>{
        return this.listUsersDataSource.queryDataFromSource(new ListUsersUIRequestBody({
          // @ts-ignore
          accessToken: user.stsTokenManager.accessToken,
          type: ListUsersUIRequestBodyTypeEnum.CREATEACCTIFNOTEXISTS,
        }))
      }),
      tap({
        next: (res: ListUsersUIResponseBody) => {
          let userInfo = res.data[0];

          Object.assign(this.currentUser,deepCopyInclude(
            [
              "emailNotificationsIsPresent",
              "smsNotificationsIsPresent",
              "incomingVideosNotificationsIsPresent",
              "youtubeDataApiAccessToken",
              "youtubeDataApiRefreshToken",
              "googleDriveApiAccessToken",
              "googleDriveApiRefreshToken",
              "subscriptions",
              "credits",
              "startWithGuide"
            ],userInfo ??{}
          ))
          this.socketioService.connectAndSetupListeners()
          this.proceed();
        },
        error: (err) => {
          this.baseService.closeOverlayLoading();
        }
      })
    );
  }

  onAuthStateChangedCallback =(user:User) => {

    if (user) {
      if(!this.users.find((loggedInUsers)=>user.uid === loggedInUsers.user.uid)){
        this.users.push(
          new AccountsServiceUser({
            user
          })
        )
      }

      this.currentUser = this.users.at(-1)
      if(!isNetworkConnectionGood()){
        this.proceed()
        return
      }
      this.baseService.openOverlayLoading()
      // @ts-ignore
      this.setupUserAfterLogin(user).subscribe()

    }
    else if(this.users.length > 0){
      this.users = []
      this.currentUser = null
      this.onUserLoggedOutEvent.next()
    }
    else{
      this.onNoUserEvent.next()
    }


  }

  manageUsersLoginInfo = ()=>{

    onAuthStateChanged(
      this.firebaseService.auth,
      this.onAuthStateChangedCallback
    );
  }

  listenForUser = (props:Partial<{ngUnsub:Subject<void>,cdref:ChangeDetectorRef}>={})=>{

    return this.onUserLoggedInEvent
    .pipe(
      takeUntil(props?.ngUnsub ?? new Subject()),
      map(()=>{
        props.cdref?.detectChanges()
        return this.currentUser
      })
    )
  }

  navToProfilePostsIfAlreadyLoggedIn =(props:{ngUnsub:Subject<void>,cdref?:ChangeDetectorRef,notify?:boolean})=>{
    return this.listenForUser(props)
    .pipe(
      delay(2000),
      takeUntil(props.ngUnsub),
      tap(()=>{
        if(this.currentUser){
          this.utilService.router.navigateByUrl(ENV.nav.urls.accountOverviewPage)
          if(props.notify !== false){
            this.baseService.createWMLNote("SignInPage.WMLNotifyOne.alreadyLoggedIn",WMLNotifyOneBarType.Warning)
          }
        }
      })
    )
  }

  signOutViaFirebase =()=>{
    return from(this.firebaseService.auth.signOut())
    .pipe(
      concatMap(()=>{
        return defer(async()=>{
          return localforage.removeItem(ENV.localForage.UserVideoData)
        })
      }),
      concatMap(this.invalidateCacheForUsers),
      concatMap(this.invalidateCacheForUserVideoData),
      concatMap(this.invalidateCacheForUserVideoDataEdits),
      concatMap(()=>{
        return defer(async ()=> {
          this.specificService.getOneSignalDeferredGlobalVar().push(async (OneSignal)=> {
            await OneSignal.logout(this.currentUser.id);
          });
        })
      }),
      tap(()=>{
        this.socketioService.client.disconnect()
      }),
      tap({
        next:()=>{
          this.users.pop()
          this.currentUser = null
          this.baseService.createWMLNote("AccountsService.WMLNotifyOne.loggedOutSuccess")
          // needed because the next call to map the sid in ther server is too complex to have it listen again
          this.utilService.getWindow().location.reload()
        },
        error:()=>{
          this.baseService.createWMLNote("AccountsService.WMLNotifyOne.loggedOutError")
        }
      }),
    )

  }

  deleteUser = (uiBody =new DeleteUserUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.deleteUser.automate,
      of(new DeleteUserUIResponseBody()),
      deleteUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .delete(ENV.accountsService.deleteUser.url(),{body:apiBody})
              .pipe(
                concatMap(this.invalidateCacheForUsers),
                concatMap(this.invalidateCacheForUserVideoData),
                concatMap(this.invalidateCacheForUserVideoDataEdits),
                map(deleteUserSuccess)
              )
          })
        )
      )
  }

  deleteUserData = (uiBody = new DeleteUserDataUIRequestBody())=>{
    let {currentUser} = this
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken,
      youtubeDataApiAccessToken:currentUser.youtubeDataApiAccessToken,
      googleDriveApiAccessToken:currentUser.googleDriveApiAccessToken
    })
    return iif(
      ()=>ENV.accountsService.deleteUserData.automate,
      of(new DeleteUserDataUIResponseBody()),
      deleteUserDataLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .delete(ENV.accountsService.deleteUserData.url(),{body:apiBody})
              .pipe(
                concatMap(this.invalidateCacheForUsers),
                concatMap(this.invalidateCacheForUserVideoDataEdits),
                map(deleteUserDataSuccess)
              )
          })
        )
      )
  }

  exportUsers = (uiBody =new ExportUsersUIRequestBody())=>{
    let {currentUser} = this
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken,
      googleDriveApiRefreshToken:currentUser.googleDriveApiRefreshToken,
      googleDriveApiAccessToken:currentUser.googleDriveApiAccessToken
    })
    return iif(
      ()=>ENV.accountsService.exportUsers.automate,
      of(new ExportUsersUIResponseBody()),
      exportUsersLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.exportUsers.url(),apiBody)
              .pipe(
                map(exportUsersSuccess)
              )
          })
        )
      )
  }

  getCustomTokens = (uiBody = new GetCustomTokensUIRequestBody())=>{
    Object.assign(uiBody,{
      accessToken:this.currentUser.accessToken
    })
    return iif(
      ()=>ENV.accountsService.getCustomTokens.automate,
      of(new GetCustomTokensUIResponseBody()),
      getCustomTokensLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.getCustomTokens.url(),apiBody)
              .pipe(map(getCustomTokensSuccess))
          })
        )
      )
    }

  deleteCards = (uiBody = new DeleteCardsUIRequestBody(),raw = false)=>{

    return iif(
    ()=>ENV.accountsService.deleteCards.automate,
      of(new DeleteCardsUIResponseBody()),

      deleteCardsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
            .delete(ENV.accountsService.deleteCards.url(),{body:apiBody})
            .pipe(raw ? tap() : map(deleteCardsSuccess))
        })
      )
    )
  }

  getCardServerSessionId = (uiBody = new GetCardServerSessionIdUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.getCardServerSessionId.automate,
      of(new GetCardServerSessionIdUIResponseBody()),
      getCardServerSessionIdLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.getCardServerSessionId.url(),apiBody)
              .pipe(raw ? tap() : map(getCardServerSessionIdSuccess))
          })
        )
      )
  }

  addCardToUser = (uiBody = new AddCardToUserUIRequestBody(),raw =false)=>{
    return iif(
      ()=>ENV.accountsService.addCardToUser.automate,
      of(new AddCardToUserUIResponseBody()),
      addCardToUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.addCardToUser.url(),apiBody)
              .pipe(raw ? tap() : map(addCardToUserSuccess))
          })
        )
      )
  }

  updateAdresses = (uiBody = new UpdateAdressesUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.updateAdresses.automate,
      of(new UpdateAdressesUIResponseBody()),
      updateAdressesLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.updateAdresses.url(),apiBody)
              .pipe(
                concatMap(this.invalidateCacheForUsers),
                map(updateAdressesSuccess)
              )
          })
        )
      )
  }

  updateUser = (uiBody = new UpdateUserUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.updateUser.automate,
      of(new UpdateUserUIResponseBody()),
      updateUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .put(ENV.accountsService.updateUser.url(),apiBody)
              .pipe(
                concatMap(this.invalidateCacheForUsers),
                map(updateUserSuccess),
                tap(()=>{
                  this.utilService.getWindow().location.reload()
                })
              )
          })
        )
      )
  }

  _listUsers = (uiBody = new ListUsersUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.listUsers.automate,
      of(new ListUsersAPIResponseBody()),
      listUsersLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.listUsers.url(),apiBody)
          })
        ) as Observable<ListUsersAPIResponseBody>
      )
  }

  listUsersDataSource = new WMLDataSource({
    getFromSink: this._listUsers,
    transformationPredicate:listUsersSuccess
  })

  mapSocketIOSessionIdToUser = (uiBody = new MapSocketIOSessionIdToUserUIRequestBody())=>{
    return iif(
      ()=>ENV.accountsService.mapSocketIOSessionIdToUser.automate,
      of(new MapSocketIOSessionIdToUserUIResponseBody()),
      mapSocketIOSessionIdToUserLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
          .put(ENV.accountsService.mapSocketIOSessionIdToUser.url(),apiBody)
              .pipe(map(mapSocketIOSessionIdToUserSuccess))
          })
        )
      )
  }

  listFriends = (uiBody = new ListFriendsUIRequestBody())=>{
    let {currentUser} = this
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken
    })
    uiBody =new ListFriendsUIRequestBody(uiBody)
    return iif(
      ()=>ENV.accountsService.listFriends.automate,
      of(new ListFriendsUIResponseBody()),
      listFriendsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.listFriends.url(),apiBody)
              .pipe(
                map(listFriendsSuccess),
                map((res:any)=>{
                  this.currentUser.friends = res.friends
                  this.onUserFriendsEvent.next()
                })
              )
          })
        )
      )
  }

  updateFriends = (uiBody = new UpdateFriendsUIRequestBody())=>{
    let {currentUser} = this
    Object.assign(uiBody,{
      accessToken:currentUser.accessToken
    })
    uiBody = new UpdateFriendsUIRequestBody(uiBody)
    return iif(
      ()=>ENV.accountsService.updateFriends.automate,
      of(new UpdateFriendsUIResponseBody()),
      updateFriendsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.updateFriends.url(),apiBody)
              .pipe(map(updateFriendsSuccess))
          })
        )
      )
  }


  listCredits = (uiBody = new ListCreditsUIRequestBody())=>{

    let {currentUser} = this
    Object.assign(uiBody, {
      accessToken: currentUser.accessToken,
    })
    return iif(
      ()=>ENV.accountsService.listCredits.automate,
      of(new ListCreditsUIResponseBody()),
      listCreditsLoad(uiBody)
        .pipe(
          concatMap((apiBody)=>{
            return this.http
              .post(ENV.accountsService.listCredits.url(),apiBody)
              .pipe(map(listCreditsSuccess))
          })
        )
      )
    }




}


export class AccountsServiceUser {
  constructor(props:Partial<AccountsServiceUser>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
  }
  fallbackAvatarURL = "assets/media/app/logo-no-bg.png"
  user:User
  subscriptions:ListUsersEntity["subscriptions"] = []
  credits:ListUsersEntity["credits"] = {
    download:0,
    backup:0,
    bulkEdit:0,
    fileTransfer:0,
    channel:0
  }
  startWithGuide = false
  paymentMethods:ListCardsUIResponseBody["data"][number] = []
  friends :ListFriendsUIResponseBody["friends"] =[]
  emailNotificationsIsPresent= false
  smsNotificationsIsPresent = false
  // WERE using the receiveFiles proerty on the friends list to determine instead
  incomingVideosNotificationsIsPresent = true
  youtubeDataApiAccessToken = ""
  youtubeDataApiRefreshToken = ""
  googleDriveApiAccessToken = ""
  googleDriveApiRefreshToken = ""
  get id(){
    return this.user.uid
  }
  get accessToken(){
    // @ts-ignore
    return this.user.stsTokenManager.accessToken
  }

  get avatarImg (){
    let img = new WMLImage({
      src:this.user.photoURL ?? this.fallbackAvatarURL,
      alt:"AccountsService.profileURLAlt",
      onError:()=>{
        img.src =this.fallbackAvatarURL
      }
    })
    return img
  }
  get displayUserName(){
    return this.user.displayName ?? "AccountsService.userName"
  }
  get businessUserName(){
    return this.user.displayName
  }
  get displayEmail(){
    return this.user.email ?? "global.na"
  }
  get businessEmail(){
    return this.user.email
  }
  get isEmailVerified(){
    return this.user.emailVerified ?? null
  }
  get displayPhone(){
    return this.user.phoneNumber ?? "global.na"
  }
  get businessPhone(){
    return this.user.phoneNumber
  }
  get accountCreationDate(){
    let {creationTime} =this.user.metadata

    return  this.formateDate0(creationTime);
  }
  get lastSignInDate(){
    let {lastSignInTime} = this.user.metadata
    return  this.formateDate0(lastSignInTime);
  }
  private formateDate0(creationTime: string) {
    let date = new Date(creationTime);
    let month = String(date.getMonth() + 1).padStart(2, '0');
    let day = String(date.getDate()).padStart(2, '0');
    let year = date.getFullYear();
    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }




}
