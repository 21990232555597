import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { Observable, of, take } from "rxjs"

export let requestYoutubeAuthorizationFromUserLoad = (uiBody:RequestYoutubeAuthorizationFromUserUIRequestBody): Observable<RequestYoutubeAuthorizationFromUserAPIRequestBody>=>{



  let apiBody = new RequestYoutubeAuthorizationFromUserAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let requestYoutubeAuthorizationFromUserSuccess = (apiBody:RequestYoutubeAuthorizationFromUserAPIResponseBody):RequestYoutubeAuthorizationFromUserUIResponseBody=>{

  return apiBody.data
}


export class RequestYoutubeAuthorizationFromUserUIRequestBody {
  constructor(props:Partial<RequestYoutubeAuthorizationFromUserUIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  accessToken:string
  appPlatformTypeEnumValue =ENV.platformType
}

export class RequestYoutubeAuthorizationFromUserAPIRequestBody {
  constructor(props:Partial<RequestYoutubeAuthorizationFromUserAPIRequestBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RequestYoutubeAuthorizationFromUserUIRequestBody>
}

export type RequestYoutubeAuthorizationFromUserUIResponseBody = string;


export class RequestYoutubeAuthorizationFromUserAPIResponseBody {
  constructor(props:Partial<RequestYoutubeAuthorizationFromUserAPIResponseBody>={}){
    let origProps = Object.entries(props).filter(([key, val]) => {
      return !key.startsWith('prop');
    });
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }
  data:RecursiveSnakeCaseType<RequestYoutubeAuthorizationFromUserUIResponseBody>
}

