import { LowerCasePipe, TitleCasePipe } from "@angular/common";
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base";

export type CamelToSnake<S extends string=""> = S extends `${infer First}${infer Rest}`
  ? `${First extends Uppercase<First> ? '_' : ''}${Lowercase<First>}${CamelToSnake<Rest>}`
  : '';

export  type RecursiveSnakeCaseType<T> = T extends Record<string, any>
  ? {
      [K in keyof T as K extends string
        ? CamelToSnake<K>
        : never]: T[K] extends Record<string, any>
        ? ( T[K] extends Array<any> ?  Array<RecursiveSnakeCaseType<T[K][number]>> : RecursiveSnakeCaseType<T[K]>)
        : T[K];
    }
  : T;




export type SnakeToCamel<S extends string=""> = S extends `${infer First}_${infer Rest}`
  ? `${Capitalize<First>}${SnakeToCamel<Rest>}`
  : S;

export type RecursiveCamelCaseType<T> = T extends Record<string, any>
? {
    [K in keyof T as K extends string
      ? SnakeToCamel<K>
      : never]: T[K] extends Record<string, any>
      ? ( T[K] extends Array<any> ?  Array<RecursiveCamelCaseType<T[K][number]>> : RecursiveCamelCaseType<T[K]>)
      : T[K];
  }
: T;


export let makeLowerCase = new LowerCasePipe().transform
export let makeTitleCase = new TitleCasePipe().transform


export let transformFromCamelCaseToSnakeCase = (str) =>
  str[0].toLowerCase() +
  str
    .slice(1, str.length)
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export let transformFromSnakeCaseToCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/([-_][a-z|0-9])/g, (group) => group.toUpperCase().replace('_', ''));
};

// Transform from Snake Case to PascalCase
export let transformFromSnakeCaseToPascalCase = (str) => {
  return str
    .split('_')
    .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');
};

// Transform from PascalCase to Snake Case
export let transformFromPascalCaseToSnakeCase = (str) => {
  return str[0].toLowerCase() +
    str.slice(1).replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};


export function repeatString(str, times) {
  return str.repeat(times);
}

export function generateUUID(prefix="") {
  // @ts-ignore
  return prefix+([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


@WMLConstructorDecorator
export class TextEditor {
  text: string;
  lines: string[];

  constructor(props: Partial<TextEditor> = {}) {}
  wmlInit(){
    this.lines = this.text.split('\n');
  }

  public insert(line: number, char: number, text: string): void {

    if (line < 0) throw new Error('Line number out of bounds');
    if (line >= this.lines.length) this.lines.push(...new Array(line - this.lines.length + 1).fill(''));
    const targetLine = this.lines[line];
    this.lines[line] = targetLine.slice(0, Math.min(char, targetLine.length)) + text + targetLine.slice(Math.min(char, targetLine.length));

    this.text = this.lines.join('\n')
  }

  public replace(fromLine: number, fromChar: number, toLine: number, toChar: number, newText: string): void {
    if (fromLine < 0 || toLine < 0 || fromLine >= this.lines.length || toLine >= this.lines.length) {
        throw new Error('Line number out of bounds');
    }
    // Ensure the lines array includes enough lines up to `toLine`
    if (toLine >= this.lines.length) {
        this.lines.push(...new Array(toLine - this.lines.length + 1).fill(''));
    }

    // Join all affected lines into a single string to simplify manipulation
    let affectedText = this.lines.slice(fromLine, toLine + 1).join('\n');

    // Calculate start and end indexes in the concatenated string
    let startIndex = this.lines[fromLine].substring(0, fromChar).length + (fromLine > 0 ? this.lines.slice(0, fromLine).join('\n').length + 1 : 0);
    let endIndex = this.lines[toLine].substring(0, toChar).length + (fromLine < toLine ? this.lines.slice(0, toLine).join('\n').length + 1 : 0);

    // Create new text by replacing specified range with `newText`
    affectedText = affectedText.substring(0, startIndex) + newText + affectedText.substring(endIndex);

    // Split the modified text back into lines
    const newLines = affectedText.split('\n');

    // Replace the old lines with the new lines in the original array
    this.lines.splice(fromLine, toLine - fromLine + 1, ...newLines);
    this.text = this.lines.join('\n')
  }





  public get getTextDetails() {
    return {
      totalLines:this.lines.length,
      lines:this.lines.map((line, index) => ({
        lineNumber: index + 1,
        charCount: line.length
      }))
    }
  }
}


