import { transformObjectKeys } from "@core/utility/common-utils"
import { AuthBase } from "@core/utility/specific-utils/classes-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { ENV } from "@env/environment"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let purchaseCreditsLoad = (uiBody:PurchaseCreditsUIRequestBody): Observable<PurchaseCreditsAPIRequestBody>=>{



  let apiBody = new PurchaseCreditsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let purchaseCreditsSuccess = (apiBody:PurchaseCreditsAPIResponseBody):PurchaseCreditsUIResponseBody=>{
  let uiBody = new PurchaseCreditsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class PurchaseCreditsUIRequestBody extends AuthBase {
  constructor(props:Partial<PurchaseCreditsUIRequestBody>={}){ super()}
  items:{
    quantity:number
    variantId:string
  }[] =[]
  appPlatformTypeEnumValue =ENV.platformType
}

@WMLConstructorDecorator
export class PurchaseCreditsAPIRequestBody {
  constructor(props:Partial<PurchaseCreditsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<PurchaseCreditsUIRequestBody>
}

@WMLConstructorDecorator
export class PurchaseCreditsUIResponseBody {
  constructor(props:Partial<PurchaseCreditsUIResponseBody>={}){}

  weburl:string
}

@WMLConstructorDecorator
export class PurchaseCreditsAPIResponseBody {
  constructor(props:Partial<PurchaseCreditsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<PurchaseCreditsUIResponseBody>
}

