import { transformObjectKeys } from "@core/utility/common-utils"
import { RecursiveSnakeCaseType, transformFromCamelCaseToSnakeCase, transformFromSnakeCaseToCamelCase } from "@core/utility/string-utils"
import { WMLConstructorDecorator } from "@windmillcode/wml-components-base"
import { Observable, of, take } from "rxjs"

export let listCreditsLoad = (uiBody:ListCreditsUIRequestBody): Observable<ListCreditsAPIRequestBody>=>{



  let apiBody = new ListCreditsAPIRequestBody({data:transformObjectKeys(
    uiBody,
    transformFromCamelCaseToSnakeCase
  )})
  return of(apiBody)
  .pipe(
    take(1),
  )
}

export let listCreditsSuccess = (apiBody:ListCreditsAPIResponseBody):ListCreditsUIResponseBody=>{
  let uiBody = new ListCreditsUIResponseBody(transformObjectKeys(
    apiBody.data,
    transformFromSnakeCaseToCamelCase
  ))
  return uiBody
}

@WMLConstructorDecorator
export class ListCreditsUIRequestBody {
  constructor(props:Partial<ListCreditsUIRequestBody>={}){}
}

@WMLConstructorDecorator
export class ListCreditsAPIRequestBody {
  constructor(props:Partial<ListCreditsAPIRequestBody>={}){}
  data:RecursiveSnakeCaseType<ListCreditsUIRequestBody>
}

@WMLConstructorDecorator
export class ListCreditsUIResponseBody {
  constructor(props:Partial<ListCreditsUIResponseBody>={}){}
  credits:Array<{
    title:string,
    variantId:string,
    price:{
      amount:number,
      currencyIcon:string
    }
    backupCreditsDelta:number
    bulkEditCreditsDelta:number
    channelCreditsDelta:number
    downloadCreditsDelta:number
    fileTransferCreditsDelta:number
  }> = []
}

@WMLConstructorDecorator
export class ListCreditsAPIResponseBody {
  constructor(props:Partial<ListCreditsAPIResponseBody>={}){}
  data:RecursiveSnakeCaseType<ListCreditsUIResponseBody>
}

