<div
(click)="props.click()"
[class]="classPrefix('MainPod')">
  <div
  (click)="props.click()"
  [ngStyle]="props.style"
  [class]="classPrefix('Pod0') + props.class"
  >
    <div [class]="classPrefix('Pod0Item0')">
    </div>
  <span [class]="classPrefix('Pod0Text0')">{{props.text | translate}}</span>
  </div>
</div>
