<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <div [class]="classPrefix('Pod0Item1')">
        <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.client.title" | translate}}</h4>
        <h4>{{props.clientType | translate}}</h4>
      </div>
    </div>
    <ul>
      <li *ngFor="let file of props.files" [class]="classPrefix('Pod0Item0')">
        <div [class]="classPrefix('Pod0Item1')">
          <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.fileName" | translate}}</h4>
          <h4>{{ file.name| translate}}</h4>
        </div>
        <div [class]="classPrefix('Pod0Item1')">
          <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.fileType" | translate}}</h4>
          <h4>{{ file.type| translate}}</h4>
        </div>
        <div   [class]="classPrefix('Pod0Item1')">
          <h4 [class]="classPrefix('SharedText0')">{{"global.progress" | translate}}</h4>
          <h4>{{(file.content?.[0]?.progress.toFixed(file.progressPrecision) ??0)+'%'}}</h4>
          <!--
          do not delete this is in case we want to bring back progress bar
          <div [class]="classPrefix('Pod0Item3')">
            <div
            [ngStyle]="{
              width:(file.content?.[0]?.progress ??0)+'%'
            }"
            [class]="classPrefix('Pod0Item4')"></div>
          </div> -->
        </div>
      </li>
    </ul>
    <ul *ngIf="props.clientType === 'TransferLocalFilesJobZero.client.types.0'"  [class]="classPrefix('Pod0Item2')">
      <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.recipients"|translate}}</h4>
      <li *ngFor="let recipient of props.recipients">
        <h4>{{recipient}}</h4>
      </li>
    </ul>
    <ul *ngIf="props.clientType === 'TransferLocalFilesJobZero.client.types.1'"  [class]="classPrefix('Pod0Item2')">
      <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.senders"|translate}}</h4>
      <li *ngFor="let recipient of props.senders">
        <h4>{{recipient}}</h4>
      </li>
    </ul>
    <div [class]="classPrefix('Pod0Item0')">
      <div [class]="classPrefix('Pod0Item1')">
        <h4 [class]="classPrefix('SharedText0')">{{"TransferLocalFilesJobZero.estimatedTime.title" | translate}}</h4>
        <h4>
          <ng-container *ngFor="let timeVal of timeValues;let i = index;">
            <span *ngIf="props.estimatedTime[timeVal] !== 0 || timeVal === 'seconds'" >{{props.estimatedTime[timeVal]}} {{"TransferLocalFilesJobZero.estimatedTime."+timeVal | translate}} {{i !== timeValues.length-1 ? ",": ""}} </span>
          </ng-container>
        </h4>
      </div>
    </div>
  </section>
  <section [class]="classPrefix('Pod1')">
    <job-status-zero [props]="props.jobInfo"></job-status-zero>
  </section>
</div>
