<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">

  </section>
  <section [class]="classPrefix('Pod1')">
    <div
    [class]="classPrefix('Pod1Item0')"
    *ngFor="let plan of props.plans">
      <pricing-card-zero [props]="plan"></pricing-card-zero>
    </div>
  </section>
</div>
