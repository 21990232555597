
import { WMLConstructorDecorator, WMLImage } from '@windmillcode/wml-components-base';
import { RatingStarsProps } from '@shared/components/rating-stars/rating-stars.component';
import { roundToXDecimals } from '@core/utility/number-utils';

export class StoreServiceCategory {
  constructor(props: Partial<StoreServiceCategory> = {}) {
    let origProps = Object.entries(props)
      .filter(([key,val]) => {
        return !key.startsWith('prop');
      });
    Object.assign(this, { ...Object.fromEntries(origProps) });
    this.id = this.id.toString()
  }

  id:any
  name:string
}

@WMLConstructorDecorator
export class StoreServiceItem {
  constructor(props: Partial<StoreServiceItem &{propInitPrice:boolean}> = {}) { }
  wmlInit =(props)=>{
    if(props.propInitPrice =true){
      this.price = new StoreServiceItemPrice()
    }
  }

  id:string
  imgs :WMLImage[]=[]
  get displayImg(){
    return this.imgs[0]
  }
  set displayImg(value){
    this.imgs.unshift(value)
  }
  title:string
  subtitle:string
  quantity:number =1
  categories: Array<StoreServiceCategory> = []
  price:StoreServiceItemPrice

  rating = new RatingStarsProps({
    rating: 4,
  });
  get totalPrice(){


    let result = new StoreServiceItemPrice({})
    if(this.price){
      let value = this.price.business * this.quantity;
      Object.assign(result, {
        business:value,
        currency:this.price.currency,
        // serverDisplay:this.price.currency+value
      })
    }

    return result
  }
}

export class StoreServiceItemPrice  {
  constructor(props:Partial<StoreServiceItemPrice>={}){
    Object.assign(
      this,
      {
        ...props
      }
    )
    this.business = parseFloat(this.business)
  }
  business:any= 0
  currency= "$"
  serverDisplay:null
  get display(){
    return this.serverDisplay ?? this.currency+ roundToXDecimals(this.business,2)
  }

}

