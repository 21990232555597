// angular
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
// services
import { UtilityService } from '@app/core/utility/utility.service';
import { BaseService } from '@core/base/base.service';
// rxjs
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
// wml-components
import { WMLUIProperty, generateClassPrefix } from '@windmillcode/wml-components-base';
// misc
import { ENV } from '@env/environment';
import { WMLButtonOneProps } from '@windmillcode/angular-wml-button';
import { WMLFieldZeroProps } from '@windmillcode/angular-wml-field';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'verified-zero',
  templateUrl: './verified-zero.component.html',
  styleUrls: ['./verified-zero.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifiedZeroComponent {
  constructor(
    public cdref: ChangeDetectorRef,
    public utilService: UtilityService,
    public baseService: BaseService
  ) {}

  classPrefix = generateClassPrefix('VerifiedZero');
  idPrefix = generateClassPrefix(ENV.idPrefix.verifiedZero);
  @Input('props') props: VerifiedZeroProps = new VerifiedZeroProps();
  @HostBinding('class') myClass: string = this.classPrefix(`View`);
  @HostBinding('id') myId: string = this.idPrefix();
  ngUnsub = new Subject<void>();
  formControl:AbstractControl

  configureProps() {
    this.props.verifyBtn.updateBtnClassString(this.classPrefix("MainBtn0"))
  }

  listenForValueChanges = ()=>{
    this.formControl = this.props.wmlField.getReactiveFormControl()
    return this.formControl.valueChanges
    .pipe(
      takeUntil(this.ngUnsub),
      tap((res)=>{
        this.props.verifyMsgContainer.isPresent = res
        this.cdref.detectChanges()

      })
    )

  }

  ngOnInit(): void {
    this.configureProps();
    this.listenForValueChanges().subscribe()
    this.formControl.patchValue(this.formControl.value)

  }


  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}

export class VerifiedZeroProps {
  constructor(props: Partial<VerifiedZeroProps> = {}) {
    let origProps = Object.entries(props).filter(([key, val]) => !key.startsWith('prop'));
    Object.assign(this, { ...Object.fromEntries(origProps) });
  }

  verifyBtn = new WMLButtonOneProps({
    text:"VerifiedZero.button"
  })
  verifyMsg= new WMLUIProperty({
    text:"VerifiedZero.msg"
  })
  verifyMsgContainer = new WMLUIProperty({})
  wmlField:WMLFieldZeroProps

}
