import { SITE_OFFLINE_ENUM } from '@core/utility/constants';
import { EnvPlatformType, traverseClassAndRemoveUpdateAutomation } from '@core/utility/env-utils';
import { makeTitleCase } from '@core/utility/string-utils';

import { WMLEndpoint } from '@windmillcode/wml-components-base';
import { PeerOptions } from 'peerjs';

export let environment = {
  production: false,
};


export class DevEnv {
  // this must be a union type for import purposes
  type: 'DEV' | 'DOCKER_DEV' | 'PREVIEW' | 'PROD' | 'TEST' = 'DEV';
  platformType = EnvPlatformType.WEB
  endpointMsgCodes = {
    success: 'OK',
    error: 'ERROR',
    respViaSocketio: 'RESPONSE_VIA_SOCKETIO',
  };

  app = (() => {
    let app = {

      shouldPerformInitialNavigate:()=>{
        return navigator.userAgent ==="Puppeteer" || !["PREVIEW","PROD"].includes(this.type)
      },
      backendHealthCheck: () => this.backendDomain0 + '/healthz/',
      siteOffline: SITE_OFFLINE_ENUM.FALSE,
      originalConsoleObj: [],
      keyBoardDebounceTimes:[500],
      // dev additions
      freeTrialEndsAfter: Infinity,
      toPassChromeOauthVerficationTest:true,
      includeSubscriptions:false,
      friendsLimit:5,
      isRemote:false //remember when setting to true you have to run the app in http because the emulator rejects your certificate as its not in the cert store of the remote device // TODO just know when deploying always set isRemote to false

    };
    return app;
  })();

  backendServerName0 = 'example.com:5003';

  get backendDomain0() {
    return 'https://' + this.backendServerName0;
  }
  frontendDomain0 = 'https://example.com:4203';

  classPrefix = {
    app: 'App',
    products: 'Products',
  };

  idPrefix: { [k: string]: string } = {
    nav: 'Nav_',
    sectionTwo: 'SectionTwo_',
    colorOptionZero: 'ColorOptionZero_',
    datePickerZero: 'DatePickerZero_',
    cardExpiryInputZero: 'CardExpiryInputZero_',
    cardNumberInputZero: 'CardNumberInputZero_',
    cardCvvInputZero: 'CardCvvInputZero_',
    accountProfileZero: 'AccountProfileZero_',
    verifiedZero: 'VerifiedZero_',
    imageUploadZero: 'ImageUploadZero_',
    landingLayout: 'LandingLayout_',
    authLayout: 'AuthLayout_',
    signUpPage: 'SignUpPage_',
    signInPage: 'SignInPage_',
    accountLayout: 'AccountLayout_',
    accountOverviewPage: 'AccountOverviewPage_',
    navOne: 'NavOne_',
    pricingZero: 'PricingZero_',
    pricingCardZero: 'PricingCardZero_',
    forgotPassPage: 'ForgotPassPage_',
    logoImgZero: 'LogoImgZero_',
    navZero: 'NavZero_',
    homePage: 'HomePage_',
    videoManagerPage: 'VideoManagerPage_',
    dropdownTwo: 'DropdownTwo_',
    connectionHubPage: 'ConnectionHubPage_',
    cardZero: 'CardZero_',
    filterZero:"FilterZero_",
    cardOne:"CardOne_",
    searchZero:"SearchZero_",
    videoTileZero:"VideoTileZero_",
    pageNumberControlZero:"PageNumberControlZero_",
    currentPageControlZero:"CurrentPageControlZero_",
    dropdownOptionTwo:"DropdownOptionTwo_",
    legalDocPage:"LegalDocPage_",
    jobsZero:"JobsZero_",
    jobItemZero:"JobItemZero_",
    cardTwo:"CardTwo_",
    jobTitleZero:"JobTitleZero_",
    downloadJobZero:"DownloadJobZero_",
    aiShortsJobZero:"AiShortsJobZero_",
    jobStatusZero:"JobStatusZero_",
    surveyPage:"SurveyPage_",
    addNewVideoZero:"AddNewVideoZero_",
    multiInputZero:"MultiInputZero_",
    addNewVideoOne:"AddNewVideoOne_",
    multiInputOne:"MultiInputOne_",
    limitsZero:"LimitsZero_",
    videoDataEditorPage:"VideoDataEditorPage_",
    videoViewerZero:"VideoViewerZero_",
    videoDataEditorZero:"VideoDataEditorZero_",
    optionZero:"OptionZero_",
    contentEditorControlZero:"ContentEditorControlZero_",
    richTextEditorZero:"RichTextEditorZero_",
    insertAtControlZero:"InsertAtControlZero_",
    backupPreviousStateJobZero:"BackupPreviousStateJobZero_",
    updateVideosJobZero:"UpdateVideosJobZero_",
    sortZero:"SortZero_",
    UploadZeroPage:"UploadZeroPage_",
    transferRecipientsFormZero:"TransferRecipientsFormZero_",
    transferLocalFilesJobZero:"TransferLocalFilesJobZero_",
    friendsZeroPage:"FriendsZeroPage_",
    friendControlZero:"FriendControlZero_",
    billingZeroPage:"BillingZeroPage_",
    intervalInputZero:"IntervalInputZero_",
    guideZeroPage:"GuideZeroPage_",
    verifyEmailZeroPage:"VerifyEmailZeroPage_"
  };

  nav = (() => {
    let nav = {
      urls: {

        landing: '/landing',
        home: '/',
        homeAlt: '/home',
        siteOffline: '/site-offline',
        notFound:"/404",
        initialURL: '/',
        authLayout: '/auth',
        signUpPage: '/auth/sign-up',
        signInPage: '/auth/sign-in',
        forgotPassPage: '/auth/forgot-pass',
        verifyEmailZeroPage:"/auth/verify-email",
        accountLayout: '/account',
        accountOverviewPage: '/account/overview',
        friendsZeroPage:"/account/friends",
        billingZeroPage:"/account/billing",
        connectionHub: '/hub',
        legalDocPage:"/legal",
        termsAndConditionsPage:"/legal/terms-and-conditions",
        privacyPolicyPage:"/legal/privacy-policy",
        surveyPage:"/survey",
        manageVideos: '/videos/manage',
        videoDataEditorPage:"/videos/data-editor",
        uploadPage:"/upload",
        guide:"/guide",

      },
    };

    let idPrefixes = Object.entries(nav.urls).map(([key, val]) => {
      return [key, makeTitleCase(key) + '_'];
    });
    this.idPrefix = {
      ...Object.fromEntries(idPrefixes),
      ...this.idPrefix,
    };
    return nav;
  })();

  mobile = {
    android:{
      appUrl:"",
      googlePlayStore:{
        storeUrl:'https://play.google.com/store/apps/details?id=com.windmillcode.eneobia&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',

      }
    },
    ios:{
      appUrl:"",
      appStore:{
        storeUrl:"https://apps.apple.com/us/app/eneobia/id6505082387"
      }
    }
  }


  errorCodes = {};

  peerjs:PeerOptions = {
    host:"example.com",
    port:5003,
    secure:true,
    // port:9503,
    // secure:false,
    clientType:"socketio",
    msgpackType:"standard",
    // debug:4
  }

  tawkTo = {
    scriptUrl:'https://embed.tawk.to/66187af1a0c6737bd12ae615/1hr7q0ggs'
  }

  oneSignal = {
    appId:"fef48508-5e84-4353-a456-718d406f1f50",
    safariWebId:"web.onesignal.auto.10485988-1822-4e96-b399-29edb7cde282"
  }

  hcaptcha = {
    siteKey:"7dee141b-7877-4153-bef2-b4e04ef037e1"
  }

  firebase = (() => {
    let firebase

    firebase = {
      config: {
        apiKey: 'AIzaSyAOntpUM6jJ5hWM9K6T1W_1vtKdTbfxEpw',
        authDomain: '127.0.0.1',
        // authDomain: "ui.preview.eneobia.com",
        projectId: 'eneobia-preview',
        messagingSenderId: '940530829997',
        appId: '1:940530829997:web:44c1e7991a54d6c66fd424',
        measurementId: 'G-0TJZF5K9BF',
      },
      get storageImageURL(){
        return firebase._storageImageURL
      },
      set storageImageURL(value){
        firebase._storageImageURL = value
      },
      getImageUrlFromStorage: (resourcePath?: string) => {
        return `${firebase.storageImageURL}${encodeURIComponent(
          resourcePath,
        )}?alt=media`;
      },
    };
    firebase.storageImageURL =`http://${firebase.config.authDomain}:9199/v0/b/eneobia-preview.appspot.com/o/`
    return firebase;
  })();

  localForage = {
    UserVideoData:"UserVideoData",
    SitePassword:"SitePassword",
    VideoDataEditor:"VideoDataEditor",
    LoginFromFirebaseCustomToken:"LoginFromFirebaseCustomToken"
  }

  accountsService = {
    listUsers: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/list',
    }),
    updateUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/update',
    }),
    mapSocketIOSessionIdToUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/socketio/map",
    }),
    updateAdresses: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/addresses/update',
    }),
    deleteUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/delete',
    }),
    deleteUserData: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/delete-data',
    }),
    exportUsers: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/users/export',
    }),
    addCardToUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/create',
    }),
    listCards: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/list',
    }),
    deleteCards: new WMLEndpoint({
      url: () => this.backendDomain0 + '/accounts/cards/delete',
    }),
    getCardServerSessionId: new WMLEndpoint({
      url: () => 'https://elb.deposit.shopifycs.com/sessions',
    }),

    registerViaPassword: new WMLEndpoint({
    }),
    loginViaPassword: new WMLEndpoint({
    }),
    loginViaCustomToken: new WMLEndpoint({
    }),
    EmailPasswordRecoveryLink: new WMLEndpoint({
    }),
    changePassword: new WMLEndpoint({
    }),
    listFriends:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/friends/list",
    }),
    updateFriends:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/accounts/friends/update",
    }),
    listCredits:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/accounts/credits/list",
      }),
    getCustomTokens:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/accounts/custom-tokens/list",
      }),
  };

  storeService = {
    listSubscriptions: new WMLEndpoint({
      url: () => this.backendDomain0 + '/store/subscriptions/list',
    }),
    purchaseSubscription: new WMLEndpoint({
      url: () => this.backendDomain0 + '/store/subscriptions/purchase',
    }),
    cancelSubscription:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/subscriptions/cancel",
    }),
    purchaseCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/purchase",
    }),
    listCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/list",
    }),
    useCredits:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/store/credits/use",
    }),
  };

  platformsService = {
    requestYoutubeAuthorizationFromUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/youtube/authorize/request',
    }),
    revokeYoutubeAuthorizationForUser: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/youtube/authorize/revoke',
    }),
    listVideos: new WMLEndpoint({
      url: () => this.backendDomain0 + '/platforms/videos/list',
    }),
    addVideos:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/videos/create",
    }),
    deleteVideos:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/videos/delete",
    }),
    listUsersCurrentVideoData:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/current-data/list",
    }),
    listUsersVideoData:new WMLEndpoint({
      automate:false,
      url:()=> this.backendDomain0 + "/platforms/data/list",
    }),
    updateVideos:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/videos/update",
      }),
    saveUserVideoDataEditorSettings:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/save",
      }),
    loadUserVideoDataEditorSettings:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/load",
      }),
    backupPreviousState:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/platforms/data-editor/backup",
      }),


  };

  jobsService = {
    listJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/list",
    }),
    runJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/run",
    }),
    updateJobs:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/platforms/jobs/update",
    }),
    requestFileTransfer:new WMLEndpoint({
        url:()=> this.backendDomain0 + "/jobs/file-transfer/request",
      }),
    respondToFileTransferRequest:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/jobs/file-transfer/respond",
    }),
  }

  storageService = {
    requestGoogleDriveAuthorizationFromUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/storage/google-drive/authorize/request",
    }),
    revokeGoogleDriveAuthorizationFromUser:new WMLEndpoint({
      url:()=> this.backendDomain0 + "/storage/google-drive/authorize/revoke",
    }),
  }
  constructor() {
    this.app.originalConsoleObj = Object.entries(console);
    traverseClassAndRemoveUpdateAutomation(this)
    if(this.app.isRemote){
      this.backendServerName0 ='0xdaf8738d7d6dca8774f2f2742cbd7be913e9c3eb.diode.link'
      this.firebase.config.authDomain ='192.168.1.89'
    }
  }
}

export let ENV = new DevEnv();
