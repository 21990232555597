// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// wml components
import { WMLNotifyOneNGXTranslateModule } from '@windmillcode/angular-wml-notify';
import { WMLPopupZeroNGXTranslateModule } from '@windmillcode/angular-wml-popup';
import { WMLFieldZeroNGXTranslateModule } from '@windmillcode/angular-wml-field';
import { WMLFormZeroNGXTranslateModule } from '@windmillcode/angular-wml-form';
import { WMLInputZeroNGXTranslateModule } from '@windmillcode/angular-wml-input';
import { WMLPanelZeroNGXTranslateModule } from "@windmillcode/angular-wml-panel";
import { WMLMobileNavZeroNGXTranslateModule } from "@windmillcode/angular-wml-mobile-nav";
import { WMLInfiniteDropdownZeroNGXTranslateModule } from "@windmillcode/angular-wml-infinite-dropdown";
import { WMLButtonOneNGXTranslateModule } from '@windmillcode/angular-wml-button';
import { WMLOptionsZeroNGXTranslateModule } from '@windmillcode/angular-wml-options';
import { WMLSelectZeroNGXTranslateModule} from "@windmillcode/angular-wml-select";
import { WMLAccordionZeroNGXTranslateModule} from "@windmillcode/angular-wml-accordion";
import { WMLToggleZeroNGXTranslateModule } from '@windmillcode/angular-wml-toggle';
import { WMLChipsZeroNGXTranslateModule } from '@windmillcode/angular-wml-chips';
import { PenroseModule } from "@windmillcode/angular-wml-penrose";
import { TranslateModule } from '@ngx-translate/core';
// import {WMLSliceboxModule} from '@windmillcode/angular-wml-slicebox';
// import { WMLTabsZeroNGXTranslateModule } from '@windmillcode/angular-wml-tabs';
// import {WMLTableZeroNGXTranslateModule} from '@windmillcode/angular-wml-table'


let modules = [
  PenroseModule,
  WMLFieldZeroNGXTranslateModule,
  WMLFormZeroNGXTranslateModule,
  WMLInputZeroNGXTranslateModule,
  WMLNotifyOneNGXTranslateModule,
  WMLPopupZeroNGXTranslateModule,
  WMLMobileNavZeroNGXTranslateModule,
  WMLInfiniteDropdownZeroNGXTranslateModule,
  WMLButtonOneNGXTranslateModule,
  WMLPanelZeroNGXTranslateModule,
  WMLOptionsZeroNGXTranslateModule,
  WMLSelectZeroNGXTranslateModule,
  WMLAccordionZeroNGXTranslateModule,
  WMLToggleZeroNGXTranslateModule,
  WMLChipsZeroNGXTranslateModule,
]


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    ...modules
  ],
  exports:[
    ...modules

  ]
})
export class WMLComponentsModule { }
