<div [class]="classPrefix('MainPod')">
  <div [class]="classPrefix('MainItem0')">
    <section [class]="classPrefix('Pod0')">
      <h5
        [class]="classPrefix('Pod0Text0')"
        *ngIf="props.items.length <= 0">{{"DownloadJobZero.noVideosInJob" | translate}}
      </h5>
      <div
      *ngIf="props.items.length > 0"
      [class]="classPrefix('Pod0Item3')">
        <div [class]="classPrefix('Pod0Item0')"
        *ngFor="let item of props.items">
          <div [class]="classPrefix('Pod0Item1')">
            <p [class]="classPrefix('Pod0Text1')">{{"DownloadJobZero.itemTitle" | translate}}
              <span>{{item.title | translate}}</span>
            </p>
            <p [class]="classPrefix('Pod0Text1')">{{"DownloadJobZero.itemFilesize" | translate}}
              <span>{{item.displayFileSize | translate}}</span>
            </p>
          </div>
          <div [class]="classPrefix('Pod0Item2')">
            <i
            (click)="deleteVideo(item)"
            [class]="classPrefix('Pod0Icon0') + '  fa-solid fa-trash '"></i>
          </div>
        </div>
      </div>

    </section>
    <section
    *ngIf="props.items.length > 0"
    [class]="classPrefix('Pod1')">
      <h4>{{"DownloadJobZero.totalFilesize" | translate}}</h4>
      <h4>{{displayTotalFileSize}}</h4>
    </section>
  </div>

  <section [class]="classPrefix('Pod2')">
    <job-status-zero [props]="props.jobInfo"></job-status-zero>
  </section>
</div>
