<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Item0')">
      <h2 [class]="classPrefix('Pod0Text0')" >{{"AddNewVideoZero.title"|translate}}</h2>
      <ul [class]="classPrefix('Pod0Item1')">
        <li [class]="classPrefix('Pod0Text1')" *ngFor="let instruction of enTranslations.AddNewVideoOne.instructions;let i = index">
          {{"AddNewVideoOne.instructions."+i | translate}}
        </li>
      </ul>
    </div>

    <wml-form-zero
    [class]="classPrefix('Pod0Form0')"
    [props]="mainForm"></wml-form-zero>
  </section>
</div>
