<div [class]="classPrefix('MainPod')">
  <section [class]="classPrefix('Pod0')">
    <div [class]="classPrefix('Pod0Img0')"></div>
    <div [class]="classPrefix('Pod0Item0')"></div>
    <h3 [class]="classPrefix('Pod0Title0')">{{props.name | translate}}</h3>
    <h4 [class]="classPrefix('Pod0Title1')">{{props.price.display}}</h4>
  </section>
  <section [class]="classPrefix('Pod1')">
    <ul [class]="classPrefix('Pod1Item0')">
      <li
      [class]="classPrefix('Pod1Text0')"
      *ngFor="let bullet of props.description">
        {{bullet | translate}}
      </li>
    </ul>

  </section>

  <section [class]="classPrefix('Pod3')">
    <div [class]="classPrefix('Pod3Img0')"></div>
    <div [class]="classPrefix('Pod3Item0')"></div>
    <wml-button-one
    *ngIf="props.selectBtn.isPresent"
    [class]="classPrefix('Pod3Btn0')"
    [props]="props.selectBtn"></wml-button-one>
  </section>
</div>
