<div [class]="classPrefix('MainPod')">
  <section
  [style]="props.pod0.style"
  [class]="classPrefix('Pod0')">
    <i
    (click)="decrease()"
    [class]="classPrefix('Pod0Icon0') + ' fa-solid fa-chevron-left'"></i>
    <form
    [formGroup]="formGroup"
    [class]="classPrefix('Pod0Input0')"
    >
    <input
    readonly
    [formControlName]="formControlName" />
    </form>
    <i
    (click)="increase()"
    [class]="classPrefix('Pod0Icon0') + ' fa-solid fa-chevron-right'"></i>
  </section>
</div>
