import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from '@core/utility/utility.service';
import {
  WMLInfiniteDropdownZeroOption,
  WMLInfiniteDropdownZeroProps
} from '@windmillcode/angular-wml-infinite-dropdown';
import { BaseService } from '@core/base/base.service';

import { AccountsService } from '../accounts/accounts.service';
import { CSSVARS } from '@core/utility/common-utils';
import { ENV } from '@env/environment';
import { WMLCustomComponent, WMLUIProperty, replaceValuesWithPaths } from '@windmillcode/wml-components-base';
import enTranslations from "src/assets/i18n/en.json";
import { JobsService } from '../jobs/jobs.service';
import { WMLMobileNavZeroProps } from '@windmillcode/angular-wml-mobile-nav';
import { LogoImgZeroComponent, LogoImgZeroProps } from '@shared/components/logo-img-zero/logo-img-zero.component';
import { makeLowerCase } from '@core/utility/string-utils';
import { SpecificService } from '@core/specific/specific.service';

@Injectable({
  providedIn: 'root',
})
export class NavService {
  constructor(
    public http: HttpClient,
    public utilService: UtilityService,
    public baseService: BaseService,
    public accountsService:AccountsService,
    public jobsService:JobsService,
    public specificService:SpecificService
  ) {}

  createDesktopNavZero = () => {
    let i18nDropdown: any = replaceValuesWithPaths(
      enTranslations.NavZero.mainPopupOptions,
      'NavZero.mainPopupOptions.',
      ({key,value,path,defaultAssignment})=>{
        return new WMLUIProperty({
          text:defaultAssignment
        })
      }
    );
    return i18nDropdown

  };

  setActionsForDesktopNavZeroOptions = (options)=>{
    let [manageVideos,connectionHub,progressTracker,editVideoData,upload] = options[0].text
    let [termsAndConditions,privacyPolicy,guide] = options[1].text

    ;[manageVideos,connectionHub,editVideoData,upload,termsAndConditions,privacyPolicy,guide]
    .forEach((item,index0)=>{

      let route = ["manageVideos","connectionHub","videoDataEditorPage","uploadPage","termsAndConditionsPage",
        "privacyPolicyPage","guide"][index0]
        item.routerLink = ENV.nav.urls[route]
    })

    progressTracker.click = this.jobsService.jobPanelItem.open

  }

  addIdsToDesktopNavZeroOptions = (options) => {

    let ids = [
      ["manageVideosLink","connectionHubLink","progressTrackerLink","editVideoDataLink","uploadLink"],
      ["termsAndConditionsLink","privacyPolicyLink"]
    ]
    options.forEach((group,index0)=>{
      group.text.forEach((link,index1)=>{
        link.id = ENV.idPrefix.nav+ids[index0][index1]
      })
    })

  };

  createDesktopNavOne = (
    interactionType: WMLInfiniteDropdownZeroProps['customize']['interactionType'] = 'click'
  ): WMLInfiniteDropdownZeroProps[] => {
    let i18nDropdown: any = replaceValuesWithPaths(
      enTranslations.NavOne.routes,
      'NavOne.routes.'
    );
    let dropdown = i18nDropdown.map((items) => {
      let dropdown = new WMLInfiniteDropdownZeroProps({
        items,
        customize: {
          interactionType
        },
      });

      return dropdown;
    });
    return dropdown;
  };

  setActionsForDesktopNavOneOptions = (dropdowns:WMLInfiniteDropdownZeroProps[])=>{
    let dropdownOptions = this.getOptions(dropdowns);

    dropdownOptions
    .forEach((item,index0)=>{
      if(index0 === 4){
        item.click = ()=>{
          this.accountsService.signOutViaFirebase().subscribe()
        }
      }
      else{
        let route = ["manageVideos","accountOverviewPage","friendsZeroPage","billingZeroPage"][index0]
        item.routerLink = ENV.nav.urls[route]
      }

    })
  }

  addIdsToDesktopNavOneOptions = (dropdowns: WMLInfiniteDropdownZeroProps[]) => {

    dropdowns.forEach((option, index0) => {
      let id = [
        'dashboardOption',
        'profileOption',
        'billingOption',
        'dataOption',
        'signOutOption'
      ][index0];
      option.id = ENV.idPrefix.nav + id;
    });
  };

  createMobileNavZero = (
    interactionType: WMLInfiniteDropdownZeroProps['customize']['interactionType'] = 'click'
  ): WMLInfiniteDropdownZeroProps[] => {
    let i18nDropdown: any = replaceValuesWithPaths(
      enTranslations.NavZero.mobileOptions,
      'NavZero.mobileOptions.'
    );
    let langsDropdown:any =replaceValuesWithPaths(
      enTranslations.global.langAbbreviations,
      'global.langAbbreviations.'
    );
    i18nDropdown[0].push(langsDropdown)
    let dropdown = i18nDropdown.map((items) => {
      let dropdown = new WMLInfiniteDropdownZeroProps({
        items,
        customize: {
          interactionType,
        },
      });

      return dropdown;
    });

    let logoDisplayOptions = new WMLInfiniteDropdownZeroOption({
      custom: new WMLCustomComponent({
        cpnt: LogoImgZeroComponent,
        props: new LogoImgZeroProps({
          backgroundImg:this.specificService.drawerLogoBackgroundImg,

        }),
      })
    });
    let logoDisplay = new WMLInfiniteDropdownZeroProps({
      items: [logoDisplayOptions],
      customize: {
        option: new WMLUIProperty({
          style: {
            background: CSSVARS.wmlgradient1,
            borderBottom: '',
          },
        })
      },
    });
    dropdown.unshift(logoDisplay);
    return dropdown;
  };

  setActionsForMobileNavZeroOptions = (dropdowns:WMLInfiniteDropdownZeroProps[])=>{

    let dropdownOptions = this.getOptions(dropdowns,true);
    let [logo,langs,guide,takeSurvey,manageVideos,connectionHub,progressTracker,videoDataEditor,upload,termsAndConditions,privacyPolicy,myAccount] = dropdownOptions
    ;[guide,takeSurvey,manageVideos,connectionHub,videoDataEditor,upload,termsAndConditions,privacyPolicy,myAccount]
    .forEach((item,index0)=>{

      let route:keyof typeof ENV["nav"]["urls"] = ["guide","surveyPage","manageVideos","connectionHub","videoDataEditorPage","uploadPage","termsAndConditionsPage","privacyPolicyPage","accountOverviewPage"][index0] as any
      item.option.routerLink = ENV.nav.urls[route]
      item.option.click = (event)=>{
        event?.preventDefault()
        this.mobileNavZero.close()
      }
    })
    langs.option.clickPredicate = ()=>{
      langs.dropdown.style = {
        position:"relative",
        zIndex:"6"
      }
      langs.option.dropdown.style = {
        left:"30%"
      }

    }
    progressTracker.option.click = ()=>{
      this.jobsService.jobPanelItem.open()
    }
    // @ts-ignore
    let langsDropdown = dropdowns[1].options[1].options
    langsDropdown.forEach((item,index0)=>{
      let langCode = enTranslations.global.langAbbreviations[index0]
      item.click = ()=>{
        this.utilService.changeLanguage(makeLowerCase(langCode))
        this.utilService.currentLang = item.text
      }
    })
  }

  addIdsToMobileNavZeroOptions = (dropdowns: WMLInfiniteDropdownZeroProps[]) => {

    dropdowns.forEach((option, index0) => {
      let id = ['logoOption', 'langsOption', 'takeSurveyOption', 'manageVideosOption', 'connectionHubOption', 'progressTrackerOption','videoDataEditorOption','uploadOption', 'termsAndConditionsOption', 'privacyPolicyOption', 'myAccountOption'][index0];
      option.id = ENV.idPrefix.nav + id;
    });
  };

  createMobileNavOne = (
    interactionType: WMLInfiniteDropdownZeroProps['customize']['interactionType'] = 'click'
  ): WMLInfiniteDropdownZeroProps[] => {
    let i18nDropdown: any = replaceValuesWithPaths(
      enTranslations.NavOne.routes,
      'NavOne.routes.'
    );
    let mobileNavDropdown:any = replaceValuesWithPaths(
      enTranslations.NavOne.mobileOptions,
      'NavOne.mobileOptions.'
    );
    let langsDropdown:any =replaceValuesWithPaths(
      enTranslations.global.langAbbreviations,
      'global.langAbbreviations.'
    );
    mobileNavDropdown.push(langsDropdown)
    i18nDropdown.unshift(mobileNavDropdown)
    i18nDropdown[0][0] = i18nDropdown[0][0][0]
    let dropdown = i18nDropdown.map((items) => {
      let dropdown = new WMLInfiniteDropdownZeroProps({
        items,
        customize: {
          interactionType
        },
      });
      return dropdown;
    });

    let logoDisplayOptions = new WMLInfiniteDropdownZeroOption({
      custom: new WMLCustomComponent({
        cpnt: LogoImgZeroComponent,
        props: new LogoImgZeroProps({
          backgroundImg:this.specificService.drawerLogoBackgroundImg,
        }),
      }),
    });
    let logoDisplay = new WMLInfiniteDropdownZeroProps({
      items: [logoDisplayOptions],
      customize: {
        option: new WMLUIProperty({
          style: {
            background: CSSVARS.wmlgradient1,
            borderBottom: '',
          },
        }),
      },
    });
    dropdown.unshift(logoDisplay);
    return dropdown;
  };

  setActionsForMobileNavOneOptions = (dropdowns:WMLInfiniteDropdownZeroProps[])=>{
    let dropdownOptions = this.getOptions(dropdowns)
    dropdownOptions
    .slice(2)
    .forEach((item,index0)=>{

      if(index0 !== 4){
        let route:keyof typeof ENV["nav"]["urls"] = ["home","accountOverviewPage","friendsZeroPage","billingZeroPage"][index0] as any
        item.routerLink = ENV.nav.urls[route]
      }


      item.click = (event)=>{
        event?.preventDefault()
        this.mobileNavOne.close()
        if(index0 === 4){
          this.accountsService.signOutViaFirebase().subscribe()
          return
        }
      }

    })

    // @ts-ignore
    let langsDropdown = dropdowns[1].options[1].options
    langsDropdown.forEach((item,index0)=>{
      let langCode = enTranslations.global.langAbbreviations[index0]
      item.click = ()=>{
        this.utilService.changeLanguage(makeLowerCase(langCode))
        this.utilService.currentLang = item.text
      }
    })
  }

  addIdsToMobileNavOneOptions = (dropdowns: WMLInfiniteDropdownZeroProps[]) => {

    dropdowns.forEach((option, index0) => {
      let id = [
        'dashboardOption',
        'profileOption',
        'billingOption',
        'dataOption',
        'signOutOption'
      ][index0];
      option.id = ENV.idPrefix.nav + id;
    });
  };

  mobileNavZero = (() => {
    let mainDropdowns = this.createMobileNavZero('click');
    this.setActionsForMobileNavZeroOptions(mainDropdowns)
    this.addIdsToMobileNavZeroOptions(mainDropdowns);
    return new WMLMobileNavZeroProps({
      class:"NavZeroView",
      items: mainDropdowns,
    });
  })();


  mobileNavOne = (() => {
    let mainDropdowns = this.createMobileNavOne('click');
    this.setActionsForMobileNavOneOptions(mainDropdowns)
    this.addIdsToMobileNavOneOptions(mainDropdowns);
    return new WMLMobileNavZeroProps({
      class:"NavOneView",
      items: mainDropdowns,
    });
  })();

  desktopNavZero = (() => {
    let mainDropdowns = this.createDesktopNavZero();
    this.setActionsForDesktopNavZeroOptions(mainDropdowns)
    this.addIdsToDesktopNavZeroOptions(mainDropdowns);
    return mainDropdowns;
  })();


  desktopNavOne = (() => {
    let mainDropdowns = this.createDesktopNavOne('hover');
    this.setActionsForDesktopNavOneOptions(mainDropdowns)
    this.addIdsToDesktopNavOneOptions(mainDropdowns);
    return mainDropdowns;
  })();



  getOptions(dropdowns: WMLInfiniteDropdownZeroProps[],makeResultSet= false) {
    return dropdowns
      .map((dropdown) => {
        if(makeResultSet){
          return {
            dropdown,
            option:dropdown.options[0]
          }
        }
        return dropdown.options[0]
      }) as any;
  }
}
